.sliderVerifyFrame {
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: #fff;
  padding: 2.75rem 1.75rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .verifyLogo {
    position: absolute;
    top: 0.75rem;
    left: 1.75rem;
    height: 1rem;
    width: auto;
  }

  .closeIcon {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    color: #000;
    font-size: 0.7rem;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.4rem;
    height: 1.4rem;
    background: #DEE6EC;
    border-radius: 50%;
  }

  .contentBox, .sliderBox {
    width: 100%;
    position: relative;
    //border: 1px solid #fff;
    user-select: none;
  }

  .contentBox {
    text-align: center;
    //height: 160px;
    border-radius: 0.4rem;
    overflow: hidden;

    .bigImage {
      display: block;
      width: 100%;
      height: auto;
    }

    .positionSign, .smallImg {
      position: absolute;
      z-index: 1;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 0.4rem;
    }

    .positionSign {
      z-index: 1;
      // background-color: rgba(0, 0, 0, 0.7);
      // box-shadow: 0 0 0.2rem 0 rgba(255, 255, 255, 0.5);
    }

    .smallImg {
      z-index: 2;
      left: 0.1rem;
      box-sizing: border-box;
      // box-shadow: 0 0 0.2rem 0 rgba(255, 255, 255, 0.5);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: 0 0;
    }

    .reloadIcon {
      position: absolute;
      z-index: 5;
      right: 0.75rem;
      bottom: 0.5rem;
      color: #fff;
      font-size: 1.1rem;
      cursor: pointer;
    }
  }

  .sliderBox {
    height: 2rem;
    line-height: 2rem;
    font-size: 0.7rem;
    color: #333;
    text-align: center;
    border-radius: 1rem;
    background: #C0D2DD;
    margin-top: 1.5rem;

    .slider {
      position: absolute;
      top: -0.25rem;
      left: 0.1rem;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background-color: #1A9DF9;
      box-shadow: 0 0.4rem 1.2rem 0.4rem rgba(23, 107, 183, 0.3);

      .right {
        font-size: 1.25rem;
        font-weight: bold;
        color: #fff;
      }
    }
  }

  .tipBox {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
  }
}

