.homePageFrame{
    overflow: auto;
    height: 100%;
    background: #fff;

    .homePageTopBox{
        position: relative;
        height: 26.5rem;
    }
    .homePageBg{
        height: 26.5rem;
        background: url("../../assets/img/homePageBg.jpg") center no-repeat;
        background-size: auto 100%;
    }
    .topBox{
        position: absolute;
        top: 3rem;
        left: 0;
        right: 0;
        height: 23.5rem;
    }
    .homeContent{
        padding: 2rem 1rem;
        //position: absolute;
        //left: 50%;
        //transform: translateX(-50%);
        max-width: 62rem;
        margin: 0 auto;
        display: flex;
        align-items: flex-start;
        overflow: hidden;
    }

    .leftContent {
        flex: 1 1 auto;
        padding: 0 0.5rem 0.5rem 0.5rem;
        background: #F0F5F8;
        border-radius: 0.4rem;
        overflow: hidden;

        .knowledge {
            .ant-tabs-tab{
                padding: 0.5rem 0;
            }

            .ant-spin-nested-loading {
                .ant-spin-container {
                    .ant-tabs {
                        .ant-tabs-nav {
                            &:before {
                                 border-color: #fff;
                            }
                        }
                    }

                    .knowledgeListFrame {
                        margin-top: 0.6rem;
                        overflow: hidden;
                        border-radius: 0.4rem;

                        .listBox {
                            max-height: calc(100vh - 10rem);
                            min-height: 20.9rem;
                            margin-right: -0.2rem;
                            padding-right: 0.2rem;
                            overflow: auto;

                            .listItem {
                                background: #fff;
                                margin-bottom: 0;

                                +.listItem {
                                    margin-top: 0.5rem;
                                }
                            }
                        }
                    }
                }
            }
        }
       .questionAndAnswer{
            background: #F0F5F8;
            border-radius: 4px;
            padding: 1rem;
        }
    }

    .rightContent{
        // flex: 1 1 31%;
        margin-left: 1rem;
        // max-width: 300px;
        // min-width: 12rem;
        width: 17rem;
        .hotKnowledgeList{
            background: #FFFFFF;
            box-shadow: 0px 3px 16px 1px rgba(105,131,142,0.27);
            border-radius: 8px;
            padding: 0.5rem;
            font-size: 0.8rem;
            min-height: 427px;
            width: 17rem;
        }
        .hotListItem{
            color: #333333;
            padding: 0.4rem 0;
            overflow: hidden;
            cursor: pointer;

            .anticon-vertical-align-top{
                color: #FF4721;
                margin-right: 0.3rem;
            }

            .knowLedgeName{
                display: inline-block;
                max-width: 250px;
                margin-right: 5px;
                overflow: hidden;
                text-overflow:ellipsis;
                white-space: nowrap;
                cursor: pointer;
            }

            .iconfont{
                margin-left: 5px;
                display: inline-block;
                margin-top: -11px;
            }
            .icon-pinglun{
                color: rgb(91, 143, 249);
            }
            .icon-yuedujilu{
                color: rgb(0 188 118);
            }
            .icon-shoucangxiao{
                color: rgb(240, 209, 85);
            }
            .icon-dianzan_kuai{
                color: rgb(232, 98, 98);
            }
        }

        .hotWordsListItem{
            span{
                float: left;
            }
            .ant-image{
                float: left;
            }
            // .hotImg{
            //     width: 20px;
            //     margin-left: 5px;
            //     margin-top: -4px;
            // }
            .hotWord{
                max-width: 265px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .hotLabel{
                display: inline-block;
                background-color: #f60;
                color: #fff;
                width: 18px;
                height: 18px;
                line-height: 18px;
                font-size: 0.6rem;
                text-align: center;
                border-radius: 4px;
                margin-left: 5px;
                margin-top: 3px;
            }
            .anticon-vertical-align-top{
                margin-top: 4px;
            }
        }
        
        .index,.indexs{
            display: block;
            float: left;
            width: 0.9rem;
            text-align: center;
            margin-right: 0.3rem;
        }
        .professorList{
            width: 17rem;
            background: #F0F5F8;
            border-radius: 8px;
            padding: 0.5rem;
            font-size: 0.8rem;
            margin-top: 1.5rem;
        }

        .hotKnowledgeTitleBox{
            position: relative;
            .listTitle{
                margin-bottom:0.4rem;
                height:1rem;
                line-height: 1rem;
                display: inline-block;
                margin-right: 1rem;
                cursor: pointer;
                .icon-shuba{
                    font-size: 1rem;
                    color: #FF4721;
                    float: left;
                    margin-right: 0.3rem;
                    line-height: inherit;
                }
                span{
                    float: left;
                    height: 1rem;
                    line-height: 1rem;
                }
                .icon-reci1{
                    display: inline-block;
                    color: #f60;
                    font-size: 1rem;
                    text-align: center;
                    float: left;
                    margin-right: 0.3rem;
                }
            }
            .selectedTitle{
                color: #1890ff;
            }
            .exchange{
                position: absolute;
                right: 0;
                top: 0;
                font-size: 0.7rem;
                cursor: pointer;
                .anticon{
                    margin-right: 5px;
                }
            }
        }
        
        .professorIcon{
            color: #FF4721;
            font-size: 1rem;
            margin-right: 0.3rem;
            float: left;
        }
        .headSculpture{
            width: 3.5rem;
            height: 2.6rem;
            background: #FFFFFF;
            border-radius: 8px;
            float: left;
            margin-right: 1rem;
        }
        .indexs{
            height: 2.6rem;
            line-height: 2.6rem;
        }
        .MessageOutlined{
            color: #43A232;
            margin-right: 0.4rem;
        }
        .LikeOutlined{
            color: #EF3B1A;
            margin-right: 0.4rem;
        }
        .spaceBox{
            font-size: 0.7rem;
        }
        .professorName{
            margin-bottom: 0.4rem;
        }
    }
    .ant-tabs-top > .ant-tabs-nav{
        margin: 0;
    }
}
