.excerptListFrame {
  padding: 1rem;
  width: 100%;
  height: 100%;
  .originalManage {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    background: #fff;
    .knowledge {
      .title {
        line-height: 2.375rem;
        font-weight: 600;
        font-size: 0.9rem;
        display: flex;
        align-items: center;
        cursor: pointer;
        span {
          margin-right: 0.5rem;
        }
      }
      .expandSortBtn {
        flex-shrink: 0;
        line-height: 1;
        cursor: pointer;
        margin-left: 1rem;
        transition: transform 0.1s linear;

        .iconfont {
          font-size: 0.9rem;
          color: #888686;
        }

        &.expandStatus {
          transform: rotate(90deg);
        }
      }
      .noteView {
        font-size: 0.8rem;
        color: #797979;
        overflow: hidden;
        .noteDetail {
          background: #F0F5F8;
          width: 100%;
          margin-bottom: 1rem;
          border-radius: 4px;
          padding: 0.5rem;
          .topTitle{
            display: flex;
            .noteName {
              flex: 1;
              .icon-wj-bjb{
                margin-top: 6px;
                float: left;
                margin-right: 10px
              }
            }
            .noteOpr {
              display: flex;
              align-items: center;
              justify-content: space-around;
              float: right;
              .iconfont {
                cursor: pointer;
                margin-right: 5px;
              }
              
            }
          }
          
          .labelList{
            margin-top: 0.5rem;
            span{
              background: #ffffff;
              font-size: 0.6rem;
              margin-right: 10px;
              padding: 2px 0.5rem;
              border-radius: 4px;
            }
          }
          .noteMessage {
            width: 100%;
            cursor: pointer;
          }
        }
      }
    }
  }
}
