.signManageFrame {
  background-color: rgba(0,0,0,0) !important;
  display: flex;
  flex-flow: column;
  height: 100%;
  overflow: hidden;

  .topBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    flex-shrink: 0;
    user-select: none;
    .returnBox{
      position: relative;
      height: 100%;
    }
  }
  
  .contentBox {
    flex: 1 1 100%;
    display: flex;
    overflow: hidden;

    .leftGroupBox, .labelTableBox {
      background-color: #fff;
      height: 100%;
      overflow: hidden;
      border-radius: 0.2rem;
    }

    .leftGroupBox {
      flex-shrink: 0;
      margin-right: 1rem;
      display: flex;
      flex-direction: column;
      width: 20rem;
      padding: 1rem;
      user-select: none;

      .groupOperatorBox {
        flex-shrink: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        //height: 1rem;
        cursor: default;
        padding-bottom: 1rem;
        box-shadow: 0 1px 0 0 #efefef;
        margin-bottom: 1rem;

        .iconfont {
          font-size: 1rem;
        }

        .groupTitleBox {
          display: inline-flex;
          align-items: center;
          font-size: 0.8rem;

          .iconfont {
            margin-right: 0.5rem;
          }
        }

        .createGroupBtn {
          display: flex;
          align-items: center;
          width: 1rem;
          height: 1rem;
          cursor: pointer;

          .iconfont {
            &:hover {
              color: #60B4F6;
            }
          }
        }
      }

      .groupListBox {
        flex: 1 1 100%;
        overflow: auto;
        margin-right: -0.5rem;
        padding-right: 0.5rem;

        .groupListItem {
          height: 2rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          overflow: hidden;
          cursor: pointer;
          background: #F4F8FA;
          border-radius: 0.2rem;
          color: #333;
          padding: 0 0.5rem;

          &.activeGroupItem {
            background: #60B4F6;
            color: #fff;

            .operateBtnBox {
              .iconfont {
                &:hover {
                  color: #fff;
                }
              }
            }
          }

          +.groupListItem {
            margin-top: 0.5rem;
          }
          
          .groupName {
            font-size: 0.8rem;
            flex: 1 1 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          
          .operateBtnBox {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            margin-left: 0.5rem;
            
            .editGroupBtn, .deleteGroupBtn {
              cursor: pointer;
              height: 0.8rem;
              display: flex;
              align-items: center;
            }

            .iconfont {
              font-size: 0.8rem;

              &:hover {
                color: #60B4F6;
              }
            }
            
            .deleteGroupBtn {
              margin-left: 0.5rem;
            }
          }
        }
      }
    }

    .labelTableBox {
      flex: 1 1 100%;
      display: flex;
      flex-direction: column;
      padding-bottom: 1rem;
      
      .labelTableTopBox {
        flex-shrink: 0;
        margin-bottom: 1rem;
        height: 3rem;
        display: flex;
        align-items: center;
        box-shadow: 0 1px 0 0 #efefef;
        padding: 0 1rem;
        user-select: none;
        
        > span {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          font-size: 0.7rem;
          height: 1.6rem;
          border-radius: 0.2rem;
          background-color: #60B4F6;
          color: #fff;
          padding: 0 0.5rem;
          
          .iconfont {
            font-size: 0.7rem;
            margin-right: 0.3rem;
          }
        }
      }
      
      .tableBox {
        flex: 1 1 100%;
        overflow: hidden;
        padding: 0 1rem;
        position: relative;

        &:after {
          content: '';
          width: calc(100% - 2rem);
          height: 1px;
          background-color: #f0f0f0;
          cursor: default;
          position: absolute;
          bottom: 1px;
          left: 1rem;
        }
        
        .labelValueListBox {
          display: flex;
          flex-flow: row wrap;
          position: relative;
          margin: -0.8rem;
          padding: 0.8rem;
          overflow: hidden;

          .labelValueItem {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 1.2rem;
            padding: 0 0.5rem;
            background-color: #edf1f3;
            border-radius: 0.2rem;
            font-size: 0.6rem;
            color: #333;
            margin: 0.3rem;
            cursor: move;
            user-select: none;
            white-space: nowrap;
            position: relative;

            .positionSignBox {
              position: absolute;
              left: -0.4rem;
              top: 0;
              width: 0.2rem;
              height: 100%;
              background-color: #1A9DF9;
              border-radius: 0.2rem;
              opacity: 0;
              cursor: default;

              &.activeLabelValueItem {
                opacity: 1;
              }
            }

            &.labelValueAddBtn {
              cursor: pointer;
              background-color: #60B4F6;
              color: #fff;
              line-height: 1;

              .iconfont {
                margin-right: 0.3rem;
                margin-left: 0;
                color: #fff;

                &:hover {
                  color: #fff;
                }
              }
            }

            > span {
              line-height: 0.6rem;

            }
            
            .iconfont {
              font-size: 0.6rem;
              margin-left: 0.3rem;
              cursor: pointer;
              color: #333;

              &:hover {
                color: #60B4F6;
              }
            }
          }
        }

        .labelOperatorBox {
          display: flex;
          align-items: center;
          justify-content: center;

          .labelOperatorBtn {
            cursor: pointer;
            line-height: 1;
            .iconfont {
              font-size: 1rem;

              &:hover {
                color: #60B4F6;
              }
            }
          }

          .deleteLabelBtn {
            margin-left: 1rem;
          }
        }
      }
    }

    .noneDataBox {
      width: 100%;
      height: 100%;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      cursor: default;
      color: #c9c8c8;

      .iconfont {
        font-size: 3rem;
        margin: 0;
      }

      > span {
        font-size: 0.9rem;
        margin-top: 1rem;
      }
    }
  }
}

.signModalTitleBox {
  display: flex;
  align-items: center;
  cursor: default;
  font-size: 0.8rem;
  color: #333;
  line-height: 1;
  margin-bottom: 1rem;

  .iconfont {
    font-size: 1rem;
    margin-right: 0.3rem;
    color: #3D5C73;
  }
}

.signModal {
  .ant-modal-body {
    padding: 1rem;
  }
}