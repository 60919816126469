.processConfigFrame{
    position: relative;
    height: 100%;
    overflow: hidden;

    .processConfigTabs {
        overflow: hidden;

        .ant-tabs {
            display: flex;
            flex-direction: column;
            height: 100%;
            overflow: hidden;

            .ant-tabs-nav {
                flex-shrink: 0;
                padding: 0 1rem;
                background: #fff;
                margin: 0 0 0.5rem 0 !important;

                .ant-tabs-tab-btn{
                    font-size: 0.9rem;
                }
            }

            .ant-tabs-content-holder {
                flex: 1;
                overflow: hidden;

                .ant-tabs-content {
                    height: 100%;
                    overflow: hidden;

                    .ant-tabs-tabpane {
                        height: 100%;
                        overflow: hidden;
                    }
                }
            }
        }
    }

    .processConfigContent{
        background: white;
        padding: 1rem;
        .operation{
            // text-align: right;
            margin-bottom: 1rem;
        }
    }
}

.processModalWrap{
    .formItem{
        position: relative;
        .ant-form-item{
            margin-bottom: 10px;
        }
        .addPeopleButton{
            width: 80%;
            text-align: center;
            padding-left: 10px;
            color: #c0c0c0;
        }
        .dynamic-delete-button{
            position: absolute;
            right: 47px;
            top: 6px;
            font-size: 20px;
            color: #a9a8a8;
            cursor: pointer;
        }
        .peopleList{
            padding-left: 118px;
            margin-bottom: 20px;
            .peopleListItem{
                background: #fafafa;
                border: 1px solid #e7e7e7;
                border-radius: 2px;
                padding-left: 10px;
                width: 304px;
                margin-bottom: 5px;
                .anticon-close{
                    float: right;
                    cursor: pointer;
                    margin: 4px 5px 0 0;
                    color: #7c7c7c;
                }
            }
        }
    }
    .warningTag{
        margin-left: 50%;
        transform: translateX(-50%);
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .processImg{
        margin-top: 20px;
        border: 1px dashed #ccc;
        cursor: pointer;
        img{
            width: 472px;
        }
    }
}

.selectPeopleModal{
    .ant-transfer-list-body{
        height: 400px;
        overflow: auto;
    }
    .ant-modal-body{
        padding: 0 24px 24px;
    }
    .dynamicsPeopleBox{
        // border: 1px solid #d9d9d9;
        // border-radius: 2px;
        padding: 0 5px;
       
        .ant-checkbox-group-item{
            width: 100%;
            height: 28px;
            line-height: 28px;
        }
    }
}