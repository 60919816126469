.QAListArticlesFrame{
    padding: 1rem;
    .title{
        font-size: 16px;
        margin-bottom: 0.5rem;
    }
    .ant-checkbox-group{
        width: 100%;
        .ant-checkbox-wrapper{
            width: 100%;
            margin-bottom: 0.5rem;
        }
    }
}