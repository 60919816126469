.importantKnowledgeListFrame{
    background: white;
    position: relative;
    // 这个页面的列表可以复制文字
    -webkit-touch-callout: text;
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    .title{
        padding: 0.5rem 1rem;
        font-size: 1.2rem
    }
    .operation{
        padding: 0 1rem 0 1rem;
        display: flex;
        .operationLeft{
            flex: 1;
        }
        .operationItem{
            height: 1.6rem;
            line-height: 1.6rem;
            display: inline-block;
            margin-bottom: 0.5rem;
            margin-right: 1rem;
            .operationTitle{
                font-size: 0.7rem;
            }
            .ant-picker,.ant-input-affix-wrapper{
                width: 240px;
            }
            .ant-input-affix-wrapper{
                flex: 1;
            }
        }
        .operationRight{
            float: right;
        }
    }

    .operationSec{
        padding: 0 1rem 0 1rem;
        .operationItem{
            height: 1.6rem;
            line-height: 1.6rem;
            display: inline-block;
            margin-bottom: 0.5rem;
            margin-right: 1rem;
            .operationTitle{
                font-size: 0.7rem;
            }
            .ant-picker,.ant-input{
                width: 240px;
            }
            .ant-input{
                flex: 1;
            }
        }
        .operationRight{
            float: right;
        }
    }
    .table{
        padding: 0 1rem 0 1rem;
        .childrenlistItemHeader{
            font-size: 0.6rem;
            color: #666666;
            display: flex;
            margin-bottom: 0.5rem;
            .anticon {
                margin-right: 0.5rem;
            }
            .iconfont {
                margin-right: 0.5rem;
                top: 0.05rem;
            }
            .listTitle{
                flex: 1;
                font-size: 0.8rem;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
        .childrenlistItemHeader:last-child{
            margin-bottom: 0
        }
        .ant-table-row-expand-icon-cell{
            border-right: 0 !important;
        }
        .expandableList{
            margin-left: 160px;
        }
        .ant-table-column-title{
            z-index: 0;
        }
    }
}
.settingModalWrap{
    .checkboxGroup{
        background: #f0f2f5;
        padding: 0.5rem;
        border-radius: 8px;
        .ant-checkbox-group-item{
            margin-bottom: 5px;
        }
    }
    .exportTitle{
        margin: 1rem 0;
        font-weight: bold;
    }
}
.flowDetailsModalWrap{
    .flowDetailsBox{
        margin-bottom: 1rem;
        .flowDetailsTitle{
            font-size: 0.8rem;
            margin-bottom: 1rem;
        }
    }
}