.knowledgeReviewFrame {
  background: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  .title {
    flex-shrink: 0;
    padding: 1rem;
    font-size: 1.2rem;
    position: relative;
    display: flex;
    align-items: center;
    .pickDate {
      margin-right: 2rem;
    }
    .span {
      margin-right: 2rem;
    }
  }
  .operation {
    flex-shrink: 0;
    padding: 0 1rem 1rem 1rem;
  }
  .table {
    flex: 1;
    overflow: hidden;
    padding: 0 1rem 0 1rem;
  }
  .operationRight {
    float: right;
  }
}
.opinionMessage {
  .ant-input {
    border: none;
  }
}

// .reviewModal {
.descriptionsTitle {
  display: flex;
  justify-content: space-between;
  // color: #169BD5;
  h4 {
    font-weight: 600;
  }
  span {
    font-weight: normal;
  }
}
.ant-modal-title {
  font-weight: 600;
}
.ant-form-item-no-colon {
  font-weight: 600;
}
// .ant-form-item-control-input-content {
//   margin-left: 1rem;
//   display: flex;
//   .ant-form-item-control-input-content {
//     margin: 0;
//     margin-right: 1rem;
//   }
// }
// .ant-col,
// .ant-col-22,
// .ant-form-item-control {
//   width: 70%;
//   // margin-left: 1rem;
// }
// .ant-form-item-control-input-content{
//   margin-left: 1rem;
// }
// }

.shareTags {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.opinionMessageS {
  padding: 0.3rem 0.5rem 0.5rem 0.5rem !important;
  width: 100% !important;
  height: 3rem !important;
}
  .userDepartmentInfo {
    display: initial !important;
    padding-left: 0.5rem;
    span {
      margin-right: 1rem;
    }
  }
.authorizationOk{
  .ant-descriptions-item-content,.ant-descriptions-item-label{
    padding: 0.2rem 0;
  }
}

.knowledgeNameWrap {
  width: 95%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .knowledgeName {
    max-width: 60%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .viewKnowledge {
    margin: 0 !important;
  }
}

.processStatusModal{
  .ant-modal-content,
  .ant-modal-header {
    background: #f4f8fa;
    .modalTitleContent {
      font-weight: 600;
    }
  }
  .ant-descriptions-header {
    margin-bottom: 0.5rem;
  }
  .ant-descriptions-view {
    .ant-descriptions-item-label {
      justify-content: flex-end;
      min-width: 6rem;
      color: #555555;
    }
    .ant-descriptions-item-label::after {
      content: "";
    }
    .ant-descriptions-item-content {
      background: #fff;
      text-indent: 1rem;
      border-radius: 0.3rem;
      .knowledgeName {
        color: #169bd5;
      }
      .viewKnowledge {
        margin-left: 40%;
        height: 1rem;
        width: 2rem;
        font-size: 0.5rem;
        border: none;
        color: #fff;
        background: #169bd5;
        border-radius: 0.3rem;
      }
    }
  }
}

