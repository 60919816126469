.hotSpotFrame {
    padding: 1rem;
    .titleBox{
        >span{
            font-size: 0.9rem;
        }
        .exchange{
            float: right;
            font-size: 0.9rem;
            cursor: pointer;
            .anticon-sync{
                margin-right: 5px;
            }
        }
        .exchange:hover{
            color: #5FA8F2
        }
    }

    .hotWordsListItem{
        margin-top: 15px;
        cursor: pointer;
        display: flex;
        font-size: 0.8rem;
        span{
            float: left;
        }
        .index{
            display: block;
            float: left;
            width: 0.9rem;
            text-align: center;
            margin-right: 0.3rem;
        }
        .anticon-vertical-align-top{
            color: #FF4721;
            margin-right: 0.3rem;
            width: 0.9rem;
            margin-top: 4px;
        }

        .hotWord{
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            flex: 1;
        }

        .iconfont{
            margin-left: 5px;
            display: inline-block;
            margin-top: -11px;
        }
        
        .hotLabel{
            display: inline-block;
            background-color: #f60;
            color: #fff;
            width: 18px;
            height: 18px;
            line-height: 18px;
            font-size: 0.6rem;
            text-align: center;
            border-radius: 4px;
            margin-left: 5px;
            margin-top: 6px;
        }
    }
   

}
