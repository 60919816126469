.noteSearch {
  width: 100%;
  height: 100%;
  .ant-tabs,
  .ant-tabs-top {
    width: 100%;
    height: 100%;
    .ant-tabs-nav {
      margin-bottom: 0.5rem;
      box-sizing: border-box;
      padding-left: 1rem;
      width: 100%;
      height: 3rem;
      background: #fff;
    }
    .ant-tabs-content-holder {
      box-sizing: border-box;
      padding-left: 1rem;
      width: 100%;
      background: #fff;
      .knowledge {
        width: 100%;
        .title {
          font-weight: 600;
        }
        .con {
          width: 90%;
          .noteDetail {
            width: 100%;
            .name {
              display: flex;
              width: 100%;
            }
          }
        }
      }
    }
  }
  .originalManage {
    // display: flex;
    // justify-content: space-around;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: #fff;
    .knowledge {
      width: 95%;
      .title {
        line-height: 2.375rem;
        font-weight: 600;
        font-size: 1.125rem;
      }
      .noteView {
        margin-left: 3%;
        width: 90%;
        color: #797979;
        .noteDetail {
          width: 100%;
          .noteName {
            display: flex;
            justify-content: space-between;
            width: 100%;
          }
          .noteMessage {
            position: relative;
            overflow: hidden;
            .messageContent {
              width: 100%;
              text-indent: 2rem;
              max-height: 4rem;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              overflow: hidden;
              white-space: normal;
              &:after {
                text-indent: 0;
                content: "...";
                position: absolute;
                bottom: 0;
                right: 0;
                padding-right: 89%;
                padding-left: 0.5%;
                background: linear-gradient(to right, transparent, #fff 0%);
              }
            }
            .expanded {
              width: 100%;
              text-indent: 2rem;
              max-height: none;
            }
            .expandedBtn {
              position: absolute;
              right: 84%;
              bottom: 0;
              color: #0099cc;
              cursor: "pointer";
              background: none;
            }
            .expandedStyle {
              color: #0099cc;
              cursor: "pointer";
              background: none;
            }
          }
        }
      }
    }
  }
}
.noteManage{
  position: relative;
}
.noteSearchInput{
  position: absolute;
  right: 1rem;
  top: 0.7rem;
}