.analyzeListrame{
    background: white;
    height: 100%;
    .title{
        padding: 0.5rem 1rem 0 1rem;
        font-size: 1.2rem
    }
    .operation{
        padding: 1rem 1rem;
        .operationItem{
            display: flex;
            height: 1.6rem;
            line-height: 1.6rem;
            .ant-input{
                flex: 1;
            }
        }
        .operationRight{
            float: right;
        }
    }
    .table{
        padding: 0 1rem 0 1rem;
    }
}