.policyTreeFrame{
    margin-top: 0.5rem;
    position: relative;
    z-index: 1;
    height: 100%;
    overflow: auto;
    margin-right: -0.5rem;
    padding-right: 0.5rem;

    > div {
        position: relative;
        z-index: 1;

        .activeNodeStatus {
            position: relative;

            &:before {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                border: 0.1rem solid #1A9DF9;
                border-radius: 0.2rem;

            }
        }
    }

    .menuRootNode {
        position: absolute;
        z-index: 0;
    }

    .ant-tree-node-content-wrapper {
        display: flex;

        .ant-tree-iconEle {
            flex-shrink: 0;
            width: 0.7rem;
            margin-right: 0.4rem;

            .anticon {
                font-size: unset;

                &.anticon-file-text {
                    color: #8f9da8;
                }
            }
        }
    }

    .conditionSortListBox {
        flex-shrink: 0;
        display: flex;
        // align-items: center;
        // justify-content: space-between;
        margin-bottom: 0.5rem;
        box-shadow: 0 0.05rem 0 0 #e8e8e8;

        .conditionSortItem {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            padding-bottom: 0.5rem;
            line-height: 1rem;
            position: relative;
            color: #333;
            cursor: pointer;
            margin-right: 10px;
            .iconfont {
                font-size: 0.9rem;
                margin-right: 0.2rem;
                color: #bdbdbd;

                &.icon-fuwuleixing {
                    font-size: 0.8rem;
                }
            }

            .conditionSortName {
                font-size: 0.65rem;
            }

            &.choiceStatus {
                color: #1890ff;

                .iconfont {
                    &.icon-qianbao {
                        color: #ff4500;
                    }

                    &.icon-hangye {
                        color: #8909e3;
                    }

                    &.icon-fuwuleixing {
                        color: #52c41a;
                    }
                    &.icon-quanbu{
                        color: #1c57bd;
                    }
                }

                &:after {
                    content: "";
                    position: absolute;
                    width: 1.2rem;
                    height: 0.1rem;
                    background: #1890ff;
                    border-radius: 0.05rem;
                    bottom: 0;
                    left: calc(50% + 0.5rem);
                    transform: translateX(-50%);
                }
            }

            + .conditionSortItem {
                &:before {
                    content: "";
                    position: absolute;
                    width: 0.05rem;
                    height: 0.6rem;
                    background: #e8e8e8;
                    left: -0.8rem;
                    top: 0.2rem;
                }
            }
        }
    }
}

.menuToolTipBox {
    .ant-tooltip-content {
        background-color: #fff;
        border-radius: 0.4rem;
        padding: 0.5rem 0;
        //width: 7rem;
        box-shadow: 0 0.3rem 0.8rem 0.05rem rgba(0,0,0,0.25);
        user-select: none;
        cursor: default;

        .ant-tooltip-arrow {
            .ant-tooltip-arrow-content {
                --antd-arrow-background-color: #fff;
            }
        }

        .ant-tooltip-inner {
            background: #fff;
            box-shadow: none;
            padding: 0;

            .menuBox {
                max-height: 15rem;
                overflow: auto;

                .menuItem {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    color: #333;
                    padding: 0.2rem 0.5rem;

                    .iconfont {
                        font-size: 0.8rem;
                        flex-shrink: 0;
                        margin-right: 0.3rem;

                        &.icon-add {
                            font-size: 0.85rem;
                        }
                    }

                    .menuItemName {
                        font-size: 0.6rem;
                        line-height: 1;
                    }

                    +.menuItem {
                        margin-top: 0.2rem;
                    }

                    &:hover {
                        background-color: #1A9DF9;
                        color: #fff;
                    }
                }
            }
        }
    }
}
