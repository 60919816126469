.notificationsFrame {
  height: 100%;
  position: relative;
  background: #fff;
  .ant-tabs-nav {
    border-bottom: 10px solid #f0f2f5;
    padding: 0 1rem;
    margin: 0;
  }
  .ant-tabs-tab-btn {
    font-size: 0.9rem;
  }
  .ant-tabs,
  .ant-tabs-content,
  .ant-tabs-tabpane {
    height: 100%;
  }
  .messageSettings {
    position: absolute;
    right: 1rem;
    top: 0.6rem;
    cursor: pointer;
    .anticon-setting {
      margin-right: 0.2rem;
    }
  }
}
.messageSettingsBox {
  width: 400px;
  p {
    font-size: 0.9rem;
  }
  .ant-row {
    margin-bottom: 0.7rem;
    font-size: 0.7rem;
  }
}

// .ant-modal-content,
// .ant-modal-header {
//   background: #f4f8fa;
//   .modalTitleContent {
//     font-weight: 600;
//   }
// }
// .ant-descriptions-header {
//   margin-bottom: 0.5rem;
// }
// .ant-descriptions-view {
//   .ant-descriptions-item-label {
//     justify-content: flex-end;
//     min-width: 6rem;
//     color: #555555;
//   }
//   .ant-descriptions-item-label::after {
//     content: "";
//   }
//   .ant-descriptions-item-content {
//     background: #fff;
//     text-indent: 1rem;
//     border-radius: 0.3rem;
//     .knowledgeName {
//       color: #169bd5;
//     }
//     .viewKnowledge {
//       margin-left: 40%;
//       height: 1rem;
//       width: 2rem;
//       font-size: 0.5rem;
//       border: none;
//       color: #fff;
//       background: #169bd5;
//       border-radius: 0.3rem;
//     }
//   }
// }



// .userDepartmentInfo {
//   display: flex;
//   justify-content: space-around;
//   width: 50%;
//   font-size: 0.5rem;
// }
.opinionMessage {
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  height: 3rem;
  width: 100%;
  background: #fff;
  .ant-input:focus {
    box-shadow: none;
  }
  input {
    border: none;
  }
}
.opinionMessageS {
  position: relative;
  box-sizing: border-box;
  padding: 0 0.5rem;
  display: flex;
  justify-content: space-between;
  height: 3rem;
  width: 100%;
  background: #fff;
  color: #000;
  font-size: 0.5rem;
  .optionInfoS {
    position: absolute;
    bottom: 0;
    right: 0.5rem;
    display: flex;
    flex-direction: column;
    text-align: right;
  }
}
.Avatar{
    position: relative;
    .arrowIcon{
    position: absolute;
    left: 0.9rem;
  }
}

