.AIIcon {
    position: fixed;
    right: 0;
    bottom: 3rem;
    background: #ffffff;
    box-shadow: 0px 5px 10px 1px rgba(101, 158, 193, 0.44);
    border-radius: 6px 6px 6px 6px;
    text-align: center;
    cursor: pointer;
    z-index: 1;
    i {
      vertical-align: initial;
    }
}