.rootFrame {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .rootFrameTop {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 2.8rem;
        background-color: #fff;
        padding: 0 1rem;

        .logoBox {
            display: flex;
            height: 2.2rem;
            align-items: flex-end;

            .logoImgOne {
                width: 1.65rem;
                height: auto;
            }

            .logoImgTwo {
                width: auto;
                height: 1.45rem;
                margin-left: 0.55rem;
            }

            .logoText {
                margin-left: 0.5rem;
                font-size: 1rem;
                color: #357DB0;
                cursor: default;
                display: flex;
                align-items: center;
                line-height: 1;

                i {
                    display: block;
                    width: 0.25rem;
                    height: 0.25rem;
                    border-radius: 50%;
                    background-color: #357DB0;
                    margin-right: 0.5rem;
                }
            }

            .logoHelpText {
                margin-left: 0.5rem;
                cursor: pointer;
                display: flex;
                align-items: flex-end;

                .iconfont {
                    font-size: 1rem;
                    color: #357DB0;
                    top: 0.05rem;
                }
            }
        }

        .navigator {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-end;
            align-items: center;

            .navigatorBox {
                list-style: none;
                margin: 0;
                padding: 0;
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                align-items: center;
                margin-right: 1.2rem;

                .navItem {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 4.8rem;
                    height: 100%;
                    font-size: 0.8rem;
                    color: #333;
                    cursor: pointer;
                    position: relative;
                    line-height: 2.8rem !important;

                    +.navItem {
                        margin-left: 1rem;
                    }
                    
                    &::after {
                        content: '';
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        display: none;
                        width: 100%;
                        height: 0.1rem;
                        background-color: #60B4F6;
                    }

                    &:hover {
                        color: #0099FF;
                        &::after {
                            display: block;
                        }
                    }

                    &.activeNav {
                        color: #0099FF;

                        &::after {
                            display: block;
                        }
                    }
                }
            }

            .myNotificationBox {
                display: inline-block;
                position: relative;

                .tipIconBtn {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: center;
                    align-items: center;
                    width: 1rem;
                    height: 1rem;
                    color: #3DADEE;
                    overflow: hidden;
                    cursor: pointer;
                    margin-right: 0.9rem;
                    position: relative;

                    .iconfont {
                        font-size: 1.2rem;
                    }

                    .redTip {
                        position: absolute;
                        width: 0.4rem;
                        height: 0.4rem;
                        background-color: #FF3116;
                        border-radius: 50%;
                        top: 0;
                        right: 0;
                    }

                }
                .notificationListBox {
                    display: none;
                    position: absolute;
                    width: 20rem;
                    z-index: 10;
                    right: 0.9rem;
                    top: 2rem;
                    padding: 0.5rem 0.8rem;
                    background-color: #fff;
                    border-radius: 0.4rem;
                    box-shadow: 0 0.2rem 0.65rem 0 rgba(148,169,191,0.32);
                    font-size: 0.6rem;
                    color: #333;
                    border: 0.05rem solid #eee;
                    line-height: 1;

                    .noneDataBox {
                        height: 3rem;

                        .iconfont {
                            font-size: 1.3rem;
                        }

                        > span {
                            font-size: 0.8rem;
                        }
                    }

                    .notificationListItem {
                        display: flex;
                        cursor: default;
                        padding: 0.6rem;
                        line-height: 0.8rem;
                        border-bottom: 0.05rem solid #D9E6EC;

                        .iconfont {
                            flex-shrink: 0;
                            margin-right: 0.5rem;
                            font-size: 0.6rem;
                            line-height: 0.8rem;
                            padding-top: 0.15rem;

                            &.icon-dui3 {
                                color: #2BA71B;
                            }
                            &.icon-quxiao {
                                color: #FF3B17;
                            }
                        }

                        .itemInfoBox {
                            flex: 1 1 100%;
                            white-space: pre-wrap;
                            word-break: break-all;
                            font-size: 0.6rem;
                            text-align: justify;

                            > span {
                                color: #1599FB;
                                white-space: pre-wrap;
                            }
                        }
                    }

                    .viewAll {
                        display: flex;
                        justify-content: flex-end;
                        padding-top: 0.5rem;

                        > span {
                            background-color: #efefef;
                            border-radius: 0.1rem;
                            font-size: 0.7rem;
                            color: #333;
                            cursor: pointer;
                            padding: 0.25rem 0.7rem;
                        }
                    }
                }

            }

            .myHeadBox {
                display: inline-block;
                position: relative;

                .myHeadIcon {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: center;
                    align-items: center;
                    width: 1rem;
                    height: 1rem;
                    background: #3DADEE;
                    border-radius: 50%;
                    overflow: hidden;
                    cursor: pointer;

                    img {
                        display: block;
                        width: 100%;
                        height: 100%;
                    }

                    .nameText {
                        font-size: 0.6rem;
                        color: #fff;
                        line-height: 0.6rem;
                    }
                }

                &:hover {
                    .exitBox {
                        display: block;
                    }
                }

                .exitBox {
                    display: none;
                    position: absolute;
                    z-index: 10;
                    right: 0;
                    top: 1rem;
                    padding: 0.5rem 0.8rem;
                    background-color: #fff;
                    border-radius: 0.2rem;
                    box-shadow: 0 0.2rem 0.65rem 0 rgba(148,169,191,0.32);
                    font-size: 0.6rem;
                    color: #333;
                    border: 0.05rem solid #eee;
                    line-height: 1;

                    > span {
                        display: block;
                        cursor: pointer;
                        white-space: nowrap;
                        padding: 0.1rem 0;
                        border-bottom: 1px solid rgba(0,0,0,0);

                        + span {
                            margin-top: 0.3rem;
                        }

                        &:hover {
                            color: #3DADEE;
                            border-bottom-color: #3DADEE;
                        }
                    }
                }
            }


            .noLoginText {
                font-size: 0.6rem;
                color: #8c8c8c;
                cursor: default;
            }
        }
    }

    .contentView {
        display: block;
        width: 100%;
        height: 100%;
        overflow: hidden;
        // background: rgb(214,229,251);
        background-color: #F4F8FA;
        padding: 1rem;

        > div {
            width: 100%;
            height: 100%;
            overflow: auto;
            background-color: #fff;
        }
    }

    .rootFrameBottom {
        padding: 0;
        height: 2.4rem;

        .bottomContent {
            display: flex;
            width: 100%;
            height: 100%;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            color: #001529;
            font-size: 0.7rem;
            border-top: 0.05rem solid gainsboro;
        }
    }
}

.itemBox {
    display: flex;
    align-items: center;

    +.itemBox {
        margin-top: 1rem;
    }

    .labelText {
        display: block;
        width: 3.6rem;
        text-align: right;
        font-size: 0.7rem;
        color: #333;
        cursor: default;
        user-select: none;
        flex-shrink: 0;
    }

    .ant-input-affix-wrapper {
        border-radius: 0.3rem;
    }
}

.notificationModal {
    .ant-modal-body {
        padding: 1rem;
    }

    //.ant-modal-close-x {
    //    width: 2rem;
    //    height: 2rem;
    //    line-height: 2rem;
    //}
}