.commentFrame{
    height: 100%;
    padding: 1rem;
    .operation{
        height: 2rem;
        line-height: 2rem;
        font-size: 0.9rem;
    }
    .content{
        position: absolute;
        left: 1rem;
        right: 1rem;
        bottom: 4rem;
        top: 4rem;
        overflow: auto;
    }
    .listItem{
        >div{
            margin-bottom: 0.5rem;
        }
        .date{
            font-size: 1rem;
            margin-bottom: 0.7rem;
        }
        .title{
            font-size: 0.7rem;
            margin: 0;
            color: rgba(0, 0, 0, 0.45);
            span{
                display: inline-block;
                color: #1890ff;
                margin: 0 5px;
            }
        }
        .questionTitle{
            color: #1890ff;
            // font-weight: bold;
            font-size: 0.8rem;
            cursor: pointer;
        }
        .fileList{
            padding-left: 1rem;
        }
        .actionBar{
            color: rgba(0, 0, 0, 0.45);
            font-size: 0.6rem;
            cursor: pointer;
        }
        .ant-timeline{
            margin-left: 0.5rem;
        }
        .actionBar{
            padding-left: 1rem;
        }
    }
    .footer{
        position: absolute;
        bottom: 1rem;
        right: 1rem;
    }
}
