.roleListFrame{
    background: white;
    .title{
        padding: 0.5rem 1rem;
        font-size: 1.2rem
    }
    .operation{
        padding: 0 1rem 1rem 1rem;
    }
    .table{
        padding: 0 1rem 0 1rem;
        .ant-radio-group{
            text-align: left
        }
    }
}
