.knowledgeTypeListFrame{
    background: #fff;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 1rem;

    .titleBox {
        flex-shrink: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        .title {
            font-size: 1.2rem;
        }

        .backButton {
            position: unset!important;
        }

    }
    .operation{
        flex-shrink: 0;
        margin: 1rem 0;
    }
    .table{
        flex: 1;
        //padding: 0 1rem 0 1rem;
    }
    .ant-upload-list-picture-card .ant-upload-list-item-info::before{
        left: 0;
    }
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td{
        white-space: pre-line;
    }
}
.knowledgeAdd{
    .ant-checkbox-group-item{
        width: 48%
    }
    .ant-modal-body{
        .ant-btn-default{
            width: 100%;
            text-align: left
        }
    }
    .topTitle{
        margin-bottom: 15px;
        font-weight: bold;
        margin-top: 20px;
    }
    .title{
        margin: 10px 0;
        >span{
            display: inline-block
        }
        >span:before{
            display: inline-block;
            margin-right: 4px;
            color: #ff4d4f;
            font-size: 14px;
            font-family: SimSun,sans-serif;
            line-height: 1;
            content: "*";
        }
        .ant-btn,.approvalType{
            float: right
        }
        .ant-switch{
            vertical-align: bottom;
            margin-right: 5px;
        }
    }
    .ant-descriptions-view .ant-descriptions-item-label{
        width: 140px;
        justify-content: flex-start;
        min-width: auto;
        padding-left: 12px;
    }
    .approvalConfiguration{
        .ant-descriptions-view .ant-descriptions-item-content{
            text-indent: inherit;
            padding: 0.2rem 0.5rem;
        }
    }
    .departmentBox{
        margin-left: 143px;
    }
    .ant-input-number{
        width: 100%;
    }
}
.approvalProcess{
    .topTitle{
        margin-bottom: 15px;
        font-weight: bold;
        margin-top: 20px;
    }
    .title{
        margin: 10px 0;
        >span{
            display: inline-block
        }
        .approvalType{
            float: right
        }
        .ant-switch{
            vertical-align: bottom;
            margin-right: 5px;
        }
    }
    .dynamic-delete-button {
        position: absolute;
        right: 0;
        top: 4px;
        margin: 0 8px;
        color: #999;
        font-size: 24px;
        cursor: pointer;
        transition: all 0.3s;
    }
    .formItem{
        position: relative;
    }
}
