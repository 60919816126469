.likeFrame{
    height: 100%;
    padding: 1rem;
    .operation{
        height: 2rem;
        line-height: 2rem;
        font-size: 0.9rem;
    }
    .content{
        position: absolute;
        left: 1rem;
        right: 1rem;
        bottom: 4rem;
        top: 4rem;
        overflow: auto;
    }
    .listItem{
        margin-bottom: 1rem;
        .date{
            font-size: 1rem;
            margin-bottom: 0.3rem;
        }
        .time{
            font-size: 0.8rem;
            margin-bottom: 0.3rem;
            color: rgba(0, 0, 0, 0.45);
            padding-left: 1rem;
        }
        .fileList{
            padding-left: 1rem;
        }
    }
    .footer{
        position: absolute;
        bottom: 1rem;
        right: 1rem;
    }
    .anticon-like{
        color: #C86E05;
    }
    .colorBlue{
        color: #1890ff;
        cursor: pointer;
    }
}
