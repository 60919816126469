/* 滚动条样式 */
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(58, 56, 56, 0.3);
    background-color: #fff;
}

::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #cecece;
}

// 一行超出显示省略号
.overflowEllipsis {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

// 解决浏览器自动填充导致input背景色填充为蓝色
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-transition-delay: 10000s;
    -webkit-transition: color 10000s ease-out,
    background-color 10000s ease-out;
}

// table 翻页
.ant-table-wrapper {
    .ant-table {
        border-left: 0.05rem solid #DCE6EA;
        border-right: 0.05rem solid #DCE6EA;

        &.ant-table-bordered {
            border: none;
        }

        .ant-table-thead {
            user-select: none;
            > tr {
                > th {
                    background: #E4EDF2;
                    border-color: #fff !important;

                    &:nth-last-of-type(1) {
                        border-color: #DCE6EA!important;
                    }

                    &:before {
                        background-color: #DCE6EA!important;
                    }

                    .ant-table-filter-trigger {
                        margin-left: 0;
                        padding-left: 0;
                        margin-right: -0.25rem;
                    }
                }
            }
        }

        .ant-table-tbody {
            > tr {
                > td {
                    border-color: #DCE6EA!important;
                }

                &:nth-of-type(odd) {
                    td {
                        background: #F7FAFB;
                    }
                }
            }
        }
    }
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td,
    .ant-table tfoot > tr > th,
    .ant-table tfoot > tr > td {
        padding: 0.4rem 0.5rem;
        word-break: break-all;
        overflow-wrap: anywhere;
        font-size: 0.7rem;
    }

    .ant-table-pagination {
        padding-right: 0.5rem;
        &.ant-pagination {
            border-top: 1px solid #f0f0f0;
            margin: -1px 0 0 0;
            padding: 0.8rem 0;
            position: relative;
            z-index: 1;

            > li {
                +li {
                    margin-left: 0.5rem;
                }

                &.ant-pagination-options {
                    margin-left: 0.9rem;

                    .ant-select-selector {
                        border: none;
                        box-shadow: none;
                        background: #F7FAFB;
                        border-radius: 0.2rem;
                    }
                }
            }
        }
    }
}
.ant-pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > li {
        background: #F4F8FA!important;
        border-radius: 0.2rem;
        border: none;

        > a, .anticon {
            font-size: 0.7rem;
            color: #333;
        }

        &.ant-pagination-disabled {
            .anticon {
                color: #b7b6b6;
            }
        }

        &.ant-pagination-item-active {
            background: #60B4F6 !important;
            box-shadow: 0 0 0.25rem 0.05rem rgba(96, 180, 246, 0.65);
            font-weight: normal;

            > a {
                color: #fff;
            }
        }

        &.ant-pagination-total-text {
            background: none!important;
        }
    }
}

// loadingBox
.loadingModal {
    .ant-modal-content {
        background: none;
        box-shadow: none;

        .ant-modal-confirm-btns {
            display: none;
        }

        .loadingBox {
            display: flex;
            justify-content: center;

            .ant-spin-text {
                font-size: 0.8rem;
                //color: #fff;
                margin-top: 0.5rem;
            }

            .ant-spin-dot {
                font-size: 2rem;

                .ant-spin-dot-item {
                    width: 0.8rem;
                    height: 0.8rem;
                    //background-color: #fff;
                }
            }


        }
    }
}

// 提示信息弹框
.tipBox {
    .ant-message-notice-content {
        min-width: 15rem;
        padding: 1rem;
        font-size: 0.8rem;
        color: #333;
        box-shadow: 0 0.15rem 0.8rem 0.05rem rgba(61,75,147,0.3);
        border-radius: 0.6rem;
    }
}

// 模态框蒙层
.ant-modal-root {
    z-index: 1;

    // .ant-modal-mask {
    //     background: rgba(255, 255, 255, 0.45);
    //     backdrop-filter: blur(0.2rem);
    // }

    // .ant-modal-wrap {
    //     .ant-modal {
    //         box-shadow: 0 0.15rem 0.8rem #a8d6fa;

    //         &.loadingModal {
    //             box-shadow: none;
    //         }
    //     }
    // }
}

// 删除pup
.deletePupConfirmBox {
    .ant-popover-content {

        .ant-popover-inner {
            border-radius: 0.6rem;

            .ant-popover-message {
                text-align: center;
                display: block;

                .ant-popover-message-icon {
                    display: none;
                    text-align: center;
                }

                .ant-popover-message-title {
                    text-align: left;
                    white-space: pre-wrap;
                }
            }

            .ant-popover-buttons {
                display: flex;
                flex-flow: row-reverse;

                .ant-btn {
                    width: 3rem;
                    height: 1rem;
                    border-radius: 0.2rem;
                    background: #E4EDF2;
                    border: none;
                    font-size: 0.6rem;
                    color: #333;

                    &:after {
                        display: none;
                    }

                    +.ant-btn {
                        margin-right: 1rem;
                        margin-left: 0;
                        background: #60B4F6;
                        box-shadow: 0 0.1rem 0.45rem 0.05rem rgba(96,180,246,0.65);
                        color: #fff;
                    }
                }
            }
        }
    }
    .confirmTitleBox {
        display: flex;
        align-items: center;
        font-size: 0.6rem;
        color: #333;
        cursor: default;

        .iconfont {
            font-size: 1rem;
            color: #ff981e;
            top: 0.05rem;
            margin-right: 0.3rem;
        }
    }
}

// modal-confirm
.ant-modal-confirm-body {
    .ant-modal-confirm-content {
        margin: 0;
    }

    .ant-modal-confirm-title {
        .ant-modal-confirm-content {
            margin-top: 0.4rem;
        }
    }
}

// ai - 只是列表样式
.aiTableClass{
    .ant-table-wrapper .ant-table{
        border: 0;
    }
    .ant-table-wrapper .ant-table .ant-table-thead > tr > th{
        background: #F9FAFD;
        font-size: 12px;
        font-weight: 500;
        color: #687182;
        padding: 5px 0 5px 5px;
        .ant-btn{
            color: #687182;
        }
    }
    .ant-table-tbody > tr.ant-table-row-selected > td{
        background: #fff;
    }
    .ant-table-wrapper .ant-table .ant-table-tbody > tr:nth-of-type(odd) td{
        background: #F4F4F4;
    }
    .anticon-right-circle{
        color: #868FA0 !important;
    }
}

// 上传文件编辑界面的确认弹窗
.resultTipsBox{
    .ant-modal-content{
        border-radius: 10px;
        background: #FFFFFF;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
    }
    .ant-result-icon{
        display: flex;
        justify-content: center;
    }
    .iconBox{
        width: 45px;
        height: 45px;
        line-height: 45px;
        text-align: center;
        border-radius: 50%;
    }
    .iconWarning{
        background: #DAE5FA;
        color: #F20707;
        font-size: 20px;
    }
    .ant-result-title{
        font-size: 14px;
        color: #1D2129;
        font-weight: 600;
    }
    .ant-result-subtitle{
        font-size: 14px;
        color: #4E5969;
    }
    .ant-btn-default{
        background: #F2F3F5;
        border: 0;
    }
    .ant-btn-primary{
        background: #165DFF;
        border: 0;
    }
}


// 树节点
.sortTreeBox {
    overflow-x: hidden;
    overflow-y: auto;
    height: 12rem;

    .treeRoot {
        + .treeRoot {
            margin-top: 0.25rem;
        }
        .treeRoot {
            +.treeRoot {
                margin-top: 0;
            }
            padding-left: 1.53rem;
            .treeSortBox {
                background: #fff;
                position: relative;

                &:hover {
                    &:before {
                        position: absolute;
                        content: '';
                        width: 200%;
                        height: 100%;
                        left: -50%;
                        top: 0;
                        background: #eee;
                        z-index: 0;
                    }
                }

                &:after {
                    position: absolute;
                    content: '';
                    width: 200%;
                    height: 0.05rem;
                    left: -50%;
                    bottom: 0;
                    background: #eee;
                    z-index: 0;
                }
            }
        }
        .treeSortBox {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 1.5rem;
            background-color: rgb(169,206,240);
            padding: 0 1rem 0 0.5rem;

            .treeSortLeft {
                display: inline-flex;
                align-items: center;
                position: relative;
                z-index: 1;
                overflow: hidden;
                //text-overflow: ellipsis;
                //white-space: nowrap;
                flex: 1 1 auto;
                max-width: 100%;

                .expandIcon {
                    margin-right: .75rem;
                    transition: all 0.3s;
                    line-height: 1;
                    cursor: pointer;
                }


                .sortName {
                    font-size: 0.8rem;
                    color: #333;
                    line-height: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    cursor: default;
                    user-select: none;
                }

                .ant-checkbox-wrapper {
                    line-height: 1;
                    margin-left: 0.5rem;
                }

                &.expand {
                    .expandIcon {
                        transform: rotate(90deg);
                        transition: all 0.3s;
                    }
                }
            }

            .treeSortRight {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                position: relative;
                z-index: 1;
                flex-shrink: 0;
                margin-left: 2rem;

                .operateBtn {
                    display: block;
                    cursor: pointer;
                    color: #333;

                    .iconfont {
                        font-size: 1rem;
                    }

                    + .operateBtn {
                        margin-left: 1.5rem;
                    }

                    &.disabled {
                        cursor: not-allowed;
                        color: #ddd;
                        &:hover {
                            color: #ddd;
                        }
                    }
                }

                .treeOperate {
                    display: flex;
                    align-items: center;
                }

                .treeCreate {
                    margin-left: 2.15rem;
                    padding-left: 2.15rem;
                    position: relative;

                    &:before {
                        content: '';
                        width: 0.05rem;
                        height: 1.7rem;
                        background-color: #ddd;
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }
}


/* 选择框 */
.choiceBtn {
    display: block;
    width: 0.5rem;
    height: 0.5rem;
    background: #dedede;
    border-radius: 0.1rem;
    margin-right: 0.3rem;
    cursor: pointer;
    position: relative;

    &.someChoice {
        &::before {
            content: '';
            background: #FF3116;
            width: 50%;
            height: 50%;
            position: absolute;
            left: 50%;
            top: 50%;
            border-radius: 0.05rem;
            transform: translate(-50%, -50%);
        }
    }

    &.allChoice {
        background: #FF3116;
        box-shadow: 0 0.1rem 0.25rem 0.05rem rgba(255,49,22,0.28);
    }
}