.policyIndicatorFrame {
  box-sizing: border-box;
  padding: 0 1rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  overflow-y: hidden;

  .policyIndicatorTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 0.5rem;
    flex-shrink: 0;
    // height: 4.5rem;
    // background: pink;
    .operate {
      display: flex;
      justify-content: space-around;
      // width: 15%;
    }
    .policyIndicatorName {
      width: 25%;
      .name {
        //color: "";
      }
      input {
        width: 100%;
        border: none;
        background: none;
      }
    }
  }
  .tableContent {
    // box-sizing: border-box;
    // padding: 0 1rem;
    width: 100%;
    flex: 1;
    overflow: hidden;
    //display: flex;
    //justify-content: space-around;
    //align-items: center;
    //flex: 1;
  }
}
