.documentModeFrame{
    background: white;
    height: 100%;
    .title{
        padding: 0.5rem 1rem;
        font-size: 1.2rem;
        .switchModeList{
            cursor: pointer;
            margin-left: 0.5rem;
        }
    }
    
    .table{
        padding: 0 1rem 0 1rem;
    }
}
.markedContent{
    height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
}