.knowledgeContentFrame {
    height: 100%;
    background: #f0f2f5;
    display: flex;
    flex-direction: column;
    .topTitle{
        height: 3rem;
        line-height: 4rem;
        font-size: 1.6rem;
        background: #fff;
        margin-bottom: 0.5rem;

        // tabs样式
        .tabsBox{
            margin-top: 0.3rem;
            position: absolute;
            left: 18.5rem;
            right: 1rem;
        }

        // 下拉框样式
        .ant-select{
            float: left;
            margin-top: 0.5rem;
            font-size: 1rem;
            color: #1890ff;
            width: 16.5rem;
            padding-left: 0.3rem;
            margin-right: 1.5rem;
        }
        .ant-select-selector{
            margin-right: 1rem;
        }
        .ant-select-arrow{
            font-size: 0.8rem;
        }
    }

    .content{
        flex:1;
        display: flex;
        flex-direction: row;
        overflow: hidden;
    }
    .leftBox{
        flex: 1 1 25%;
        max-width: 17rem;
        min-width: 12rem;
        height: 100%;
        background: #fff;
        position: relative;
        padding: 1rem;
        margin-right: 0.5rem;
        overflow: hidden;

        .leftBoxScorll{
            //position: absolute;
            //left: 0.5rem;
            //right: 0.5rem;
            //top: 1rem;
            //bottom: 1rem;
            //overflow: auto;
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            
            .ant-spin-nested-loading {
                height: 100%;

                .ant-spin-container {
                    height: 100%;

                    .policyTreeFrame {
                        margin-top: 0;
                    }
                }
            }
            .searchInputBox {
                box-shadow: none;
                border: none;
                height: 30px;
                line-height: 30px;
                font-size: 0.7rem;
                background: #F0F5F8;
                margin-bottom: 0.5rem;
    
                input{
                    background: #F0F5F8;
                }
            }
        }
        .ant-tree-title{
            font-size: 0.7rem;
        }
    }
    .righBox{
        flex: 1 1 75%;
        overflow: hidden;
        background: #fff;
        padding: 0.3rem 1rem 0;
        .ant-spin-nested-loading {
            height: 100%;

            .ant-spin-container {
                height: 100%;

                > div {
                    height: 100%;
                }
            }
        }

        .ant-tabs-nav{
            margin: 0
        }
        .policyBox{
            display: flex;
            flex-direction: column;
            height: 100%;
            overflow: hidden;

            .policyHeadBox {
                flex-shrink: 0;
                background: #fff;
                padding: 0.5rem 0;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;

                .policyNameText {
                    font-size: 0.9rem;
                    font-weight: bold;
                    color: #333;
                    line-height: 1rem;
                }

                .tabListBox {
                    flex-shrink: 0;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    margin-left: 1rem;

                    .tabItem {
                        display: inline-block;
                        font-size: 0.8rem;
                        color: #333;
                        cursor: pointer;
                        line-height: 1rem;
                        position: relative;

                        &:hover {
                            color: #1A9DF9;
                        }

                        &.activeStatus {
                            color: #1A9DF9;
                        }

                        +.tabItem {
                            margin-left: 1rem;

                            &:before {
                                position: absolute;
                                content: "";
                                width: 0.05rem;
                                height: 100%;
                                top: 0;
                                left: -0.5rem;
                                background: #c9c8c8;
                            }
                        }
                    }
                }
            }


            .choiceConditionBox {
                display: flex;
                align-items: center;
                align-content: flex-start;
                justify-content: flex-start;
                flex-flow: row wrap;
                margin: 0 -0.25rem;

                .conditionItem {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    overflow: hidden;
                    line-height: 1rem;
                    border-radius: 0.2rem;
                    background: #f0f2f5;
                    margin: 0 0.25rem 0.5rem;
                    padding: 0.2rem 0.3rem;

                    .iconfont {
                        font-size: 0.7rem;

                        &.icon-qianbao {
                            color: #ff4500;
                        }

                        &.icon-hangye {
                            color: #8909e3;
                        }

                        &.icon-fuwuleixing {
                            color: #52c41a;
                        }
                        &.icon-quanbu{
                            color: #1c57bd;
                        }
                    }

                    .conditionName {
                        font-size: 0.6rem;
                        max-width: 6rem;
                        margin: 0 0.3rem;
                    }

                    .deleteBtn {
                        display: flex;
                        width: 0.9rem;
                        height: 0.9rem;
                        border-radius: 50%;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;

                        .iconfont {
                            font-size: 0.6rem;
                            color: #b7b6b6;
                        }
                    }
                }
            }

            .policyBottomBox {
                flex: 1;
                overflow: hidden;
                display: flex;
                flex-direction: column;
                background: #fff;
                padding: 1rem 0 1rem 0;

                .wpsFileBox {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    overflow: hidden;

                    .titleBox {
                        flex-shrink: 0;
                        margin-bottom: 1rem;
                    }

                    .wps-excle {
                        flex: 1;
                        overflow: auto;
                    }
                }
            }

            //.contentTabs{
            //    position: absolute;
            //    right: 1rem;
            //    top: 0;
            //    .ant-tabs-top > .ant-tabs-nav::before{
            //        border: 0;
            //    }
            //    .ant-tabs-ink-bar {
            //        background: #fff;
            //    }
            //    .ant-tabs-tab{
            //        padding: 12px 0 0 0;
            //    }
            //}
        }
    }

    .researchReportOperationBox{
        position: absolute;
        right: 0;
        .operationItem{
            display: inline-block;
            margin-left: 10px;
            >span:first-child{
                display: inline-block;
                max-width: 70px;
                font-size: 0.7rem;
            }
            .ant-picker,.ant-input,.ant-input-affix-wrapper{
                width: 240px;
            }
            .ant-input,.ant-input-affix-wrapper{
                flex: 1;
            }
        }
    }
}
