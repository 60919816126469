.policyOperationFrame{
    height: 100%;
    // background: #f0f2f5;
    display: flex;
    flex-direction: column;

    .topTitle{
        position: relative;
        height: 3rem;
        line-height: 3rem;
        font-size: 1rem;
        background: #fff;
        margin-bottom: 0.5rem;
        padding-left: 1rem;
    }
    .content{
        flex: 1 1;
        display: flex;
        flex-direction: row;
        overflow: hidden;

        .leftBox{
            display: flex;
            flex-direction: column;
            flex: 1 1 25%;
            max-width: 17rem;
            min-width: 12rem;
            height: 100%;
            background: #fff;
            position: relative;
            padding: 1rem;
            overflow: hidden;

            .leftHeadBox {
                flex-shrink: 0;
                margin-bottom: 0.5rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 0.5rem;
                border-bottom: 0.05rem solid #e9e9e9;

                .headTitleText {
                    font-size: 0.8rem;
                    color: #333;
                    line-height: 1rem;
                }

                .classificationList{
                    .operateBtn {
                        line-height: 0;
                        cursor: pointer;
                        display: block;

                        .iconfont {
                            color: #b8b8b8;
                            font-size: 1rem;
                        }

                        &:hover {
                            .iconfont {
                                color: #1A9DF9;
                            }
                        }
                    }
                }
            }

            .policyTreeBox {
                overflow: hidden;
                flex: 1;
                margin-right: -1rem;
                padding-right: 1rem;

                .ant-spin-nested-loading {
                    height: 100%;
                    .ant-spin-container {
                        height: 100%;
                        .policyTreeFrame {
                            margin-top: 0;
                        }
                    }
                }
            }
        }

        .rightBox{
            flex: 1 1 75%;
            margin-left: 0.5rem;
            display: flex;
            flex-direction: column;
            height: 100%;
            overflow: hidden;

            .rightContentBox {
                height: 100%;
                overflow: hidden;
                display: flex;
                flex-direction: column;

                .rightTopBox {
                    flex-shrink: 0;
                    display: flex;
                    justify-content: space-between;
                    align-content: flex-start;
                    width: 100%;
                    background: #fff;
                    margin-bottom: 0.05rem;
                    padding: 1rem 1rem 0.75rem;
                    position: relative;
                    overflow: hidden;

                    .topLeftBox {
                        flex: 1;
                        overflow: hidden;

                        .titleText {
                            display: block;
                            font-size: 0.8rem;
                            line-height: 1rem;
                            word-break: break-all;
                        }

                        .keyWards{
                            font-size: 0.7rem;
                            display: flex;
                            align-items: flex-start;
                            margin-top: 0.5rem;
                            color: #898989;
                            line-height: 1rem;

                            .keyWardsTitle{
                                flex-shrink: 0;
                                width: 4.2rem;
                                display: block;
                            }

                            .keyWardsList{
                                flex: 1;
                                line-height: 0;
                                overflow: hidden;
                                .site-tag-plus{
                                    cursor: pointer;
                                }
                                .ant-tag {
                                    line-height: 1rem;
                                    margin-bottom: 0.25rem;
                                    color: #7b7b7b;
                                    border: none;
                                    background: #efefef;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    max-width: 100%;
                                }
                            }
                        }
                    }

                    .topRightBox {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                        margin-left: 1rem;
                        flex-shrink: 0;

                        .tabListBox {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;

                            .tabItem {
                                display: inline-block;
                                font-size: 0.8rem;
                                color: #333;
                                cursor: pointer;
                                line-height: 1rem;
                                position: relative;

                                &:hover {
                                    color: #1A9DF9;
                                }

                                &.activeStatus {
                                    color: #1A9DF9;
                                }

                                +.tabItem {
                                    margin-left: 2rem;

                                    &:before {
                                        position: absolute;
                                        content: "";
                                        width: 0.05rem;
                                        height: 100%;
                                        top: 0;
                                        left: -1rem;
                                        background: #c9c8c8;
                                    }
                                }
                            }
                        }

                        .addButton{
                            display: flex;
                            align-items: center;
                            text-align: right;
                            margin-top: 0.5rem;
                            position: relative;
                            top: 0.25rem;

                            > button {
                                margin-left: 0.5rem;
                                font-size: 0.6rem;
                                padding: 0 0.3rem;
                                height: 1.2rem;
                            }

                            .uploadBtnFrame {
                                > span {
                                    display: block;
                                    line-height: 0;

                                    .ant-upload {
                                        .ant-btn {
                                            font-size: 0.6rem;
                                            padding: 0 0.3rem;
                                            height: 1.2rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .rightBottomBox{
                    flex: 1;
                    background: #fff;
                    position: relative;
                    padding: 0 1rem 1rem;
                    display: flex;
                    flex-direction: column;
                    overflow: hidden;
                    height: 100%;

                    > div {
                        flex: 1;
                        overflow: hidden;
                        padding: 0;

                        &.policyListBox {

                            .ant-spin-nested-loading {
                                height: 100%;

                                .ant-spin-container {
                                    height: 100%;
                                }
                            }
                        }
                    }
                }
            }

            .noneDataBox {
                height: 100%;
                background: #fff;
            }
        }
    }
}
.selfDesignModal{
    .ant-tag{
        white-space: normal;
    }
}