.ant-table-wrapper {
  &.resizeTableFrame {
    .ant-table {
      .ant-table-thead {
        > tr {
          > th {
            background: #DCE6EA;
            border-color: #fff !important;

            &:nth-last-of-type(1) {
              border-color: #DCE6EA!important;
            }

            &:before {
              background-color: #DCE6EA!important;
            }

            .ant-table-filter-trigger {
              margin-left: 0;
              padding-left: 0;
              margin-right: -0.25rem;
            }
          }
        }

        .react-resizable {
          position: relative;
          background-clip: padding-box;
          //border-bottom: none !important;
          user-select: none;

          //+ th {
          //  border-bottom: none;
          //}
        }

        .react-resizable-handle {
          position: absolute;
          width: 10px;
          height: 100%;
          bottom: 0;
          right: -5px;
          cursor: col-resize;
          z-index: 1;
        }
      }

      .ant-table-tbody {
        > tr {
          &:nth-of-type(odd) {
            td {
              background: #fff;
            }
          }

          &:nth-of-type(even) {
            td {
              background: #fff;
            }
          }
        }
      }
    }
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 0.4rem 0.5rem;
    word-break: break-all;
    overflow-wrap: anywhere;
    font-size: 0.7rem;

    .ant-table-row-expand-icon {
      &:focus {
        border-color: #f0f0f0;
      }
    }
  }

  .ant-table-pagination {
    padding-right: 0.5rem;
    &.ant-pagination {
      > li {
        +li {
          margin-left: 0.5rem;
        }

        &.ant-pagination-options {
          margin-left: 0.9rem;

          .ant-select-selector {
            border: none;
            box-shadow: none;
            background: #F7FAFB;
            border-radius: 0.2rem;
          }
        }
      }
    }
  }
}
