.pointsRuleFrame {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;

  .pointsRuleTabs {
    //background: #fff;
    height: 100%;

    .ant-tabs {
      height: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      > div {
        background: #fff;
        border-radius: 0.4rem;
        overflow: hidden;
        flex-shrink: 0;
      }

      .ant-tabs-nav {
        //border-bottom: 10px solid #f0f2f5;
        padding: 0 1rem;
        margin-bottom: 1rem;
      }

      .ant-tabs-content-holder {
        padding: 1rem;
        flex: 1;

        .ant-tabs-content {
          height: 100% !important;
        }
      }
    }


    .ant-tabs-tab-btn {
      font-size: 0.9rem;
    }
  }
  .scoringTableFrame,
  .scoreSettingsFrame,
  .scoreForAnsweringTableFrame {
    .tableTitleSmall {
      width: 100%;
      height: 1rem;
      line-height: 1rem;
      font-size: 0.8rem;
      font-weight: 600;
      margin-bottom: 0.5rem;

      span {
        margin-right: 0.5rem;
      }
    }
    .tableTitleAdd {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        span {
          margin-right: 0.5rem;
        }
      }
    }
  }
  // .pageTitle {
  //   width: 100%;
  //   height: 3rem;
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-around;
  //   font-weight: 600;
  //   // background: pink;
  //   // p {
  //   //   display: flex;
  //   //   margin-top: 1rem;
  //   //   .anticon,
  //   //   .anticon-setting {
  //   //     margin-right: 0.5rem;
  //   //     color: #999;
  //   //     font-size: 1.5rem;
  //   //   }
  //   // }
  // }
  // .modalView {
  //   padding: 0 1rem 1rem 2rem;
  //   width: 100%;
  //   background: #fff;
  //   margin-bottom: 0.5rem;
  //   .tableTitle {
  //     height: 3rem;
  //     line-height: 3rem;
  //     border-bottom: solid 0.01rem #ccc;
  //     font-weight: 600;
  //     font-size: 1rem;
  //   }
  //   .tableTitleSmall {
  //     width: 100%;
  //     height: 2rem;
  //     line-height: 2rem;
  //     font-size: 0.8rem;
  //     font-weight: 600;
  //     span {
  //       margin-right: 0.5rem;
  //     }
  //   }
  // .tableTitleAdd {
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  //   .title {
  //     span {
  //       margin-right: 0.5rem;
  //     }
  //   }
  // }
  //   .table {
  //     margin-bottom: 0.5rem;
  //   }
  // }
}
// .ant-popover-inner {
//   padding: 1rem;
// }
