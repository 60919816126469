.dictFrame{
    background: white;
    position: relative;
    .title{
        padding: 0.5rem 1rem;
        font-size: 1.2rem
    }
    .operation{
        padding: 0 1rem 0.5rem 1rem;
        #uploadList{
            opacity: 0;
            position: absolute;
            left: 0;
            width: 116px;
            cursor: pointer;
        }
        .operationItem{
            display: flex;
            height: 1.6rem;
            line-height: 1.6rem;
            .ant-input{
                flex: 1;
            }
        }
        .operationLeft{
            margin-bottom: 0.5rem;
            .operationItem{
                height: 1.6rem;
                line-height: 1.6rem;
                max-width: 320px;
                display: inline-block;
                margin-bottom: 0.5rem;
                margin-right: 1rem;
                .inputTitle{
                    display: inline-block;
                    max-width: 80px;
                    font-size: 0.8rem;
                }
                .ant-input-affix-wrapper{
                    width: 240px;
                }
                .ant-input{
                    flex: 1;
                }
            }
        }
    }
    .table{
        padding: 0 1rem 0 1rem;
    }
}