.recommendKnowledgeFrame {
    padding: 1rem;
    min-height: 700px;
    >.title{
        font-size: 0.9rem;
    }
    .exchange{
        float: right;
        font-size: 0.9rem;
        cursor: pointer;
        .anticon-sync{
            margin-right: 5px;
        }
    }
    .exchange:hover{
        color: #5FA8F2
    }
    .recommendKnowledge{
        margin-top: 1rem;
        .recommendKnowledgeItem{
            width: 100%;
            background: #F2F9FC;
            border-radius: 8px;
            padding: 1rem;
            display: flex;
            cursor: pointer;
            .knowledgeImg{
                width: 240px;
                min-width: 240px;
                height: 160px;
                background: #ffffff;
                border-radius: 8px;
                overflow: hidden;
                img{
                    width: 100%;
                }
            }
    
            .knowledgeDetails{
                flex: 1;
                margin-left: 1rem;
                overflow: hidden;
                .importantIcon{
                    color: rgb(240, 78, 78);
                    font-size: 1rem;
                    position: absolute !important;
                    right: 10px;
                    top: 0;
                }
                .title{
                    line-height: 30px;
                    margin-bottom: 10px;
                    height: 60px;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .detailsItem{
                    font-size: 0.8rem;
                    color: #999999;
                    display: flex;
                    line-height: 30px;
                    .detaileItemTitle{
                        display: inline-block;
                        width: 64px;
                        min-width: 64px;
                        line-height: 30px;
                        text-align: justify;
                        text-align-last: justify;
                        margin-right: 1rem;
                    }
                    .detaileItemText{
                        flex: 1;
                        height: 30px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}
