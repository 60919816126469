.AIModelFrame{
    height: 100%;
    position: relative;
    .AIModelTabs{
        height: 100%;
        .ant-tabs{
            height: 100%;
            .ant-tabs-nav{
                margin: 0;
            }
            .ant-tabs-content-top,.ant-tabs-tabpane{
                height: 100%;
            }
        }
    }
    .ant-tabs-nav{
        border-bottom: 10px solid #f0f2f5;
        padding: 0 1rem;
        background: #fff;
        margin-bottom: 0;
    }
    .ant-tabs-tab-btn{
        font-size: 0.9rem;
    }
}