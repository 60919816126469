.userSpaceListFrame{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 1rem;
    background: white;
    border-radius: 0.4rem;
    overflow: hidden;

    .searchBox {
        flex-shrink: 0;
        margin-bottom: 0.5rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .searchItemBox {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .ant-select {
                margin-right: 0.5rem;
            }

            +.searchItemBox {
                margin-left: 1rem;
            }
        }

        .searchBtn {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0.2rem 0.5rem;
            background: #409EFF;
            color: #fff;
            font-size: 0.7rem;
            cursor: pointer;
            border-radius: 0.2rem;
            margin-left: 1rem;

            .iconfont {
                margin-right: 0.2rem;
            }
        }
    }

    .tableContentBox {
        flex: 1;
        overflow: hidden;

        .userSpaceInfoBox {
            width: 100%;
            padding: 0 1rem;

            .infoItem {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                line-height: 1rem;

                .labelText {
                    font-size: 0.6rem;
                    color: #989696;
                    width: 3rem;
                    flex-shrink: 0;
                }

                .infoText {
                    font-size: 0.6rem;
                    color: #7e7e7e;
                }

                &.danger {
                    .labelText, .infoText {
                        color: #CB272D;
                    }
                }

                &.safe {
                    .labelText, .infoText {
                        color: #00B42A;
                    }
                }

                +.infoItem {
                    margin-top: 0.5rem;
                }
            }
        }

        .operateBox {
            .operateBtn {
                cursor: pointer;
                color: #409EFF;
            }
        }
    }
}
.editUserSpaceModalBox{
    .ant-modal-body{
        .ant-btn-default{
            width: 100%;
            text-align: left
        }
    }
}
