.aiAssistantFrame{
    display: flex;
    height: 100%;
    .left{
        padding: 1rem;
        background: #fff;
        border-radius: 8px;
        margin-right: 10px;
        flex: 1;
        .topTitle{
            span{
                font-size: 0.8rem;
                font-weight: 600;
            }
        }
        .content{
            margin-top: 1rem;
            display: flex;
            .item{
                padding: 0.8rem;
                display: flex;
                border: 1px solid #E5E8EF;
                border-radius: 4px;
                width: 320px;
                margin: 10px;
                cursor: pointer;
                .itemIcon{
                    border-radius: 50%;
                    width: 2.5rem;
                    height: 2.5rem;
                    line-height: 2.5rem;
                    text-align: center;
                    font-size: 26px;
                }
                .iconColor0{
                    background: #E8F3FF;
                    color: #165DFF;
                }
                .iconColor1{
                    background: #D4F6D0;
                    color: #1AAF05;
                }
                .itemText{
                    flex: 1;
                    margin-left: 0.5rem;
                    .title{
                        color: #1D2129;
                        line-height: 50px;
                        font-size: 0.9rem;
                    }
                    .describe{
                        color: #86909C;
                        font-size: 0.7rem;
                    }
                }
                
                border: 1px solid #E5E8EF;
                border-radius: 4px;
            }
        }
    }
    

    .right{
        width: 330px;
        background: #fff;
        border-radius: 8px;
        padding: 1rem;
        
    }
}