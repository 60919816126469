.approvalModalModal{
    .ant-modal-content,
    .ant-modal-header {
      background: #f4f8fa;
      .modalTitleContent {
        font-weight: 600;
      }
    }
    .ant-descriptions-header {
      margin-bottom: 0.5rem;
    }

    .ant-modal-content {
        .ant-modal-body {
            max-height: 60vh;
            overflow: auto;
        }
    }

    .ant-descriptions-view {
      .ant-descriptions-item-label {
        justify-content: flex-end;
        min-width: 6rem;
        color: #555555;
      }
      .ant-descriptions-item-label::after {
        content: "";
      }
      .ant-descriptions-item-content {
        background: #fff;
        text-indent: 1rem;
        border-radius: 0.3rem;
        .knowledgeName {
          color: #169bd5;
        }
        .viewKnowledge {
          margin-left: 40%;
          height: 1rem;
          width: 2rem;
          font-size: 0.5rem;
          border: none;
          color: #fff;
          background: #169bd5;
          border-radius: 0.3rem;
        }
      }
    }

    .ant-steps-item-title{
        width: 100%;
        display: flex;
    }

    .ant-steps-item-subtitle{
        font-size: 0.6rem;
        flex: 1;
        span{
            float: right;
        }
    }
    .ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail{
        left: 25px;
    }
    .ant-steps-item-icon{
        width: auto;
    }
    .statusBox{
        width: 50px;
        height: 50px;
        margin: 0 8px 0 0;
        font-size: 0.6rem;
        line-height: 50px;
        text-align: center;
        border-radius: 32px;
        overflow: hidden;
    }
    .passStatusText{
        color: #52c41a;
    }
    .currentStatusText{
        color: #1890ff;
    }
    .refuseStatusText{
        color: #ff4d4f;
    }

    .passStatus{
        background: #52c41a;
        color: #fff;
    }
    .currentStatus{
        background: #1890ff;
        color: #fff;
    }
    .refuseStatus{
        background: #ff4d4f;
        color: #fff;
    }
    .notArrivedStatus{
        background: #e0e3e8;
        color: #fff;
    }

    .descriptionsContent{
        .ant-descriptions-item-content{
            display: flex;
            >span{
                flex: 1;
            }
        }
    }
    .detailsTitle{
        color: rgba(0, 0, 0, 0.85);
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 0.5rem;
    }
    .detailsTitle:not(first-child){
        margin-top: 1rem;
    }
    .detailsItem{
        display: flex;
        padding-bottom: 16px;
        .title{
            width: 6rem;
            text-align: right;
            padding-right: 10px
        }
        .content{
            flex: 1;
            background: #fff;
            text-indent: 1rem;
            border-radius: 0.3rem;
            .ant-btn-primary{
                float: right;
            }
            .shareDepartmentNames{
                display: inline-block;
                margin-left: 10px;
                font-size: 12px;
                line-height: 20px;
                background: #fafafa;
                border: 1px solid #d9d9d9;
                border-radius: 2px;
                padding: 2px 5px;
                margin-bottom: 5px;
                margin-top: 5px;
                text-indent: initial;
                float: left;
            }
        }
    }
    .anticon-backward{
        position: absolute;
        bottom: -19px;
        transform: rotate(-90deg);
        left: -10px;
        color: #1890ff;
    }
    .ant-list-split .ant-list-item{
        border-bottom: 0
    }
    .userDepartmentInfo{
        line-height: 1.3rem;
        span{
            line-height: 1.3rem;
        }
        .departmentName{
            font-size: 0.8rem;
        }
        .title{
            font-size: 0.6rem;
            padding-left: 1rem;
        }
        .status{
            float: right;
            font-size: 0.6rem;
        }
    }

    .names{
        // background: rgb(73, 169, 238);
        width: 3rem;
        height: 3rem;
        line-height: 3rem;
        color: #fff;
        border-radius: 50%;
        text-align: center;
        overflow: hidden;
    }
    .description-empty{
        padding: 0.5rem;
        background: #fff;
        position: relative;
        height: 1.5rem;
    }
    .description {
        padding:0.5rem 0.5rem 2rem 0.5rem;
        background: #fff;
        position: relative;
        min-height: 4rem;
        .checkOption{
            line-height: 1rem;
        }
        .optionInfo{
            position: absolute;
            right: 0.5rem;
            bottom: 0;
            span{
                text-align: right
            }
        }
        span{
            color: rgba(0, 0, 0, 0.45);
            font-size: 0.6rem;
            display: block;
        }
    }
    .ant-list-item-meta{
        position: relative;
    }
    .avatar{
        .arrowIcon{
            position: absolute;
            left: 1.5rem;
            top: 3rem;
            bottom: -15px;
            border-left: 2px dashed;
            .anticon-caret-down{
                color: rgb(75, 121, 0);
                position: absolute;
                bottom: -11px;
                left: -8px;
            }
        }
    }
}
