.knowledgeSystemManageFrame {
  display: flex;
  flex-flow: column;
  height: 100%;
  background-color: #fff;
  user-select: none;
  cursor: default;
  padding: 1rem;

  .headerBox {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    //overflow: hidden;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 0.05rem solid #f0f0f0;

    .title {
      flex: 1;
      display: block;
      font-size: 0.9rem;
      color: #333;
      line-height: 1rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .addSortBtn {
      flex-shrink: 0;
      margin-left: 1rem;

      .iconfont {
        font-size: 1.3rem;
        color: #888686;
        cursor: pointer;
      }
    }
  }

  .contentBox {
    flex: 1;
    overflow: auto;
    margin-right: -0.5rem;
    padding-right: 0.5rem;

    .sortItem {
      background-color: #f8f8f8;
      border-radius: 0.4rem;

      +.sortItem {
        margin-top: 1rem;
      }

      .infoAndOperateBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 2.5rem;
        padding: 0 1rem;

        .sortInfoBox {
          display: flex;
          align-items: center;
          font-size: 0.8rem;
          color: #333;
          flex: 1;
          overflow: hidden;

          .sortName {
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .expandSortBtn {
            flex-shrink: 0;
            line-height: 1;
            cursor: pointer;
            margin-left: 1rem;
            transition: transform 0.1s linear;

            .iconfont {
              font-size: 0.9rem;
              color: #888686;
            }

            &.expandStatus {
              transform: rotate(90deg);
            }
          }
        }

        .sortOperateBox {
          flex-shrink: 0;
          margin-left: 1rem;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .operateBtn {
            display: flex;
            align-items: center;
            line-height: 1;
            height: 0.9rem;
            overflow: hidden;
            cursor: pointer;

            .iconfont {
              font-size: 0.9rem;
              color: #888686;
            }

            +.operateBtn {
              margin-left: 0.5rem;
            }

            &.addSystemBtn {

              .iconfont {
                //top: -0.05rem;
                font-size: 1rem;
              }
            }
          }
        }
      }

      .systemListBox {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        padding: 0 0.5rem;
        overflow: hidden;
        transition: max-height 0.1s linear;

        .systemItem {
          display: flex;
          flex-direction: column;
          width: 11rem;
          height: 17.5rem;
          padding: 0.5rem;
          margin: 1rem;
          position: relative;
          background-color: #fff;
          box-shadow: 0 0.1rem 0.5rem 0.05rem rgba(109,141,159,0.47);
          border-radius: 0.4rem;
          overflow: hidden;

          &:hover {
            .systemOperateBox {
              visibility: visible;
            }
          }
          &.addSystemBox {
            justify-content: center;
            align-items: center;
            //cursor: pointer;

            .operateBtn {
              cursor: pointer;

              .iconfont {
                font-size: 3rem;
                color: #a2a1a1;
              }
            }
          }

          .systemImg {
            flex: 1;
            overflow: hidden;
            border-radius: 0.2rem;
            background-color: #f0f0f0;

            > img {
              display: block;
              width: 100%;
              margin-top: 50%;
              &:not([src]) {
                opacity: 0;
              }
            }
          }

          .systemName {
            flex-shrink: 0;
            height: 1.6rem;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            margin-top: 0.4rem;

            > span {
              text-align: justify;
              font-size: 0.6rem;
              color: #333;
              line-height: 0.8rem;
              display: -webkit-box; /* 使用flex布局 */
              -webkit-box-orient: vertical; /* 纵向排列 */
              -webkit-line-clamp: 2; /* 最多展示两行文字 */
              overflow: hidden; /* 隐藏超出部分 */
            }
          }

          .systemOperateBox {
            visibility: hidden;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0.35);
            display: flex;
            align-items: center;
            justify-content: center;

            .operateBtn {
              display: block;
              text-align: center;
              cursor: pointer;

              +.operateBtn {
                margin-left: 1rem;
              }

              .iconfont {
                font-size: 1rem;
                color: #FFF;
              }

              > span {
                display: block;
                font-size: 0.7rem;
                color: #FFF;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}