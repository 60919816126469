.modelNavFrame {
  position: fixed;
  right: 1rem;
  bottom: 3rem;
  width: 1rem;
  overflow: hidden;
  padding: 0.5rem;
  background-color: #fff;
  box-shadow: 0 0.2rem 0.45rem 0 #bebaba;
  border-radius: 0.2rem;
  transition: width 0.3s;
  z-index: 3;

  &:before {
    content: '<';
    position: absolute;
    left: 0.15rem;
    top: 50%;
    transform: translateY(-50%);
    color: #ccc;
  }

  &:hover {
    width: 5rem;
    padding: 0.5rem 0.3rem;
    transition: width 0.3s;

    > div {
      overflow-y: auto;
    }

    &:before {
      display: none;
    }
  }
  > div {
    max-height: 10rem;
    overflow: hidden;

    > span {
      display: block;
      font-size: 0.6rem;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      .iconfont {
        font-size: 0.6rem;
        margin-right: 0.2rem;
        color: #3DADEE;
      }

      &:hover {
        color: #3DADEE;
      }

      + span {
        margin-top: 0.5rem;
      }
    }
  }
}