.anticon-file-ppt{
    font-size: 1rem;
    color: #C43E1C!important;
}
.anticon-file-pdf{
    font-size: 1rem;
    color: #F43A59!important;
}
.anticon-file-excel{
    font-size: 1rem;
    color: #107C41!important;
}
.anticon-file-word,.anticon-file-text{
    font-size: 1rem;
    color: #5FA8F2!important;
}
.icon-icon-png{
    font-size: 1rem;
    color: #F43A59!important;
    margin-right: 0.5rem;
    margin-top: 1px;
}
.anticon-file-zip{
    font-size: 1rem;
    color: #99451b!important;
    margin-right: 0.5rem;
    margin-top: 1px;
}
.anticon-file-jpg{
    font-size: 1rem;
    color: #99451b!important;
    margin-right: 0.5rem;
    margin-top: 1px;
}
