.noteOriginalFrame {
  padding: 1rem;
  width: 100%;
  height: 100%;
  .originalManage {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    background: #fff;
    .knowledge {
      .title {
        line-height: 2.375rem;
        font-weight: 600;
        font-size: 0.9rem;
        display: flex;
        align-items: center;
        cursor: pointer;
        span {
          margin-right: 0.5rem;
        }
      }
      .expandSortBtn {
        flex-shrink: 0;
        line-height: 1;
        cursor: pointer;
        margin-left: 1rem;
        transition: transform 0.1s linear;

        .iconfont {
          font-size: 0.9rem;
          color: #888686;
        }

        &.expandStatus {
          transform: rotate(90deg);
        }
      }
      .noteView {
        font-size: 0.8rem;
        color: #797979;
        overflow: hidden;
        .noteDetail {
          background: #F0F5F8;
          width: 100%;
          margin-bottom: 1rem;
          border-radius: 4px;
          padding: 0.5rem;
          .topTitle{
            display: flex;
            .noteName {
              height: 25px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              flex: 1;
              .icon-dangdang{
                // margin-top: 4px;
                float: left;
                margin-right: 10px;
                font-size: 22px;
              }
            }
            .noteOpr {
              display: flex;
              align-items: center;
              justify-content: space-around;
              width: 7rem;
              float: right;
              .iconfont {
                cursor: pointer;
              }
              .switch {
                display: flex;
                align-items: center;
                .switchText{
                  font-size: 0.6rem;
                }
                .ant-switch {
                  background: #ffffff;
                  margin-right: 0.3rem;
                }
                .ant-switch-handle::before {
                  background: #b8cdd9;
                }
                .ant-switch-checked {
                  background: #ffffff;
                  .ant-switch-handle::before {
                    background: #ff3320;
                  }
                }
                .ant-switch-checked .ant-switch-loading-icon {
                  color: #ffffff;
                }
              }
            }
          }
          
          .labelList{
            margin-top: 0.5rem;
            span{
              background: #ffffff;
              font-size: 0.6rem;
              margin-right: 10px;
              padding: 2px 0.5rem;
              border-radius: 4px;
            }
          }
          .noteMessage {
            width: 100%;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.formItemLabel::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}