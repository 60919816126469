.documentationFrame {


}

.addProjectModel {
  .addProjectModelTitle {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    color: #333;
    cursor: default;

    .iconfont {
      font-size: 1rem;
      color: #333;
      margin-right: 0.3rem;
    }
  }

  .addProjectModelContent {
    // width: 95%;
    // max-width: 30rem;
    margin: 0 auto;
    user-select: none;
    max-height: calc(100vh - 20rem);
    overflow: auto;
    padding: 0 24px;

    .addProjectItem {
      display: flex;

      justify-content: space-between;
      align-items: center;

      &.selectLabelBox {
        flex-flow: row wrap;
        margin-top: 0!important;
      }

      &.noCenter {
        align-items: flex-start;
      }

      +.addProjectItem {
        margin-top: 1rem;
      }

      .itemLabelText {
        font-size: 0.8rem;
        color: #333;
        user-select: none;
        margin-right: 1.3rem;
        flex-shrink: 0;
      }
      
      .itemContent {
        flex: 1 1 100%;
        background-color: #F4F8FA;
        border-radius: 0.2rem;
        overflow: hidden;

        &.noBackground {
          background: none;
        }

        .ant-select-selector {
          box-shadow: none !important;
          background: none;
          border: none;
          padding: 0 0.75rem;

          .ant-select-selection-search {
            left: 0.65rem;
            input {
              height: 100%;
            }
          }
        }

        .ant-picker {
          border: none;
          background: none;
        }

        .projectIdText {
          display: flex;
          align-items: center;
          width: 100%;
          height: 2rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding: 0 0.75rem;
          font-size: 0.7rem;
          color: #333;
          cursor: default;
        }

        .ant-switch-checked {
          background-color: #FF3116;
        }

        .tipText {
          display: flex;
          align-items: center;
          width: 100%;
          font-size: 0.6rem;
          color: #999;
          padding-top: 0.5rem;
          line-height: 0.8rem;
          background-color: #fff;
        }

        .ant-input {
          padding: 0.2rem 0.75rem;
          resize: none;
          height: 5rem;
        }

        .ant-input-textarea-show-count {
          &:after {
            margin-right: 0.75rem;
          }
        }
      }

      .itemChildItem {
        display: flex;
        align-items: center;
        overflow: hidden;
        width: 48%;
        margin: 1rem 0 0;

        .itemLabelText {
          display: block;
          width: 3.2rem;
          text-align: right;
          //margin-right: 0.5rem;
        }

        .ant-select {
          flex: 1 1 100%;
          overflow: hidden;
        }
      }

      .addLabelBtn {
        display: flex;
        width: 100%;
        margin-left: 4.5rem;
        align-items: center;
        justify-content: center;
        height: 2rem;
        background: #F4F8FA;
        border-radius: 0.2rem;
        font-size: 0.7rem;
        color: #333;
        cursor: pointer;
      }
    }
  }

  .ant-modal {
    .ant-modal-content {
      .ant-modal-body {
        padding-left: 0;
        padding-right: 0;
      }
      // .ant-modal-footer {
      //   text-align: center;
      //   padding: 2.5rem 0;
      //   .ant-btn {
      //     display: inline-flex;
      //     justify-content: center;
      //     align-items: center;
      //     width: 10rem;
      //     height: 2.5rem;
      //     border-radius: 0.2rem;
      //     font-size: 0.8rem;
      //     color: #333;
      //     background-color: #D9E6ED;
      //     border: none;

      //     + .ant-btn {
      //       color: #fff;
      //       margin-left: 2.5rem;
      //       background: #60B4F6;
      //       box-shadow: 0 0.2rem 0.6rem 0.1rem rgba(96,180,246,0.65);
      //     }
      //   }
      // }
    }
  }
}

.privateTip {
  .anticon {
    color: red !important;
  }
}