.departmentListFrame{
    background: #fff;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 1rem;

    .titleBox {
        flex-shrink: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        .title {
            font-size: 1.2rem;
        }

        .backButton {
            position: unset!important;
        }

    }
    .operation{
        flex-shrink: 0;
        margin: 1rem 0;
    }
    .table{
        flex: 1;
        //padding: 0 1rem 0 1rem;
    }
}
.departmentModal{
    .ant-input-number{
        width: 100%;
    }
}
