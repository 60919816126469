.policyListFrame{
    // padding: 1rem;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background: #fff;

    .operationGroup {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        overflow: hidden;
        position: relative;

        .groupItem{
            position: relative;
            display: block;
            line-height: 1.6rem;
            padding: 0 0.5rem;
            color: rgba(0, 0, 0, 0.85);
            font-size: 0.65rem;
            background: #fff;
            border-top: 0.1rem solid rgba(0,0,0,0);
            border-bottom: 0.1rem solid rgba(0,0,0,0);
            cursor: pointer;
            transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s;

            &:hover {
                border-bottom-color: #1890ff;
                color: #1890ff;
            }

            +.groupItem {
                margin-left: 0.5rem;
            }
        }

        .groupItemChecked{
            border-bottom-color: #1890ff;
            color: #1890ff;
        }
    }

    .policyListContentBox {
        background: #F0F5F8;
        padding: 0.5rem;
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .conditionBox {
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;
            width: 100%;
            flex-shrink: 0;
            overflow: hidden;
            user-select: none;
            margin-bottom: 0.5rem;

            .conditionItem {
                display: flex;
                align-items: center;
                flex-shrink: 0;
                position: relative;
                background: #fff;
                border-radius: 0.2rem;
                cursor: pointer;

                .iconBtn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    line-height: 1.3rem;
                    width: 1.3rem;
                    height: 1.3rem;
                    flex-shrink: 0;
                    z-index: 1;

                    .iconfont {
                        font-size: 0.7rem;
                        color: #797979;

                        &.icon-search {
                            font-size: 0.8rem;
                        }
                    }
                }

                .ant-picker {
                    padding: 0.2rem 0.4rem;
                    border-radius: 0.3rem;
                }

                .ant-input-affix-wrapper {
                    border-radius: 0.3rem;
                    padding: 0.2rem 0.4rem;
                }

                .choiceDateBox {
                    width: 10rem;
                    box-shadow: none;
                    border: none;
                    height: 0.7rem;
                    padding: 0;
                    font-size: 0.7rem;
                    margin-right: 0.8rem;

                    .ant-picker-active-bar {
                        margin-left: 0;
                        bottom: -0.15rem;
                    }

                    .ant-picker-clear {
                        right: -0.7rem;
                    }

                    input {
                        text-align: center;
                    }
                }

                .searchInputBox {
                    box-shadow: none;
                    border: none;
                    height: 0.7rem;
                    padding: 0 0 0 0.5rem;
                    font-size: 0.7rem;
                    margin-right: 0.2rem;
                }

                &.selectedAreaOrDepartmentBox {
                    align-items: flex-start;
                    overflow: hidden;
                    flex-flow: row-reverse;
                    flex: 0 1 auto;

                    .areaOrDepartmentContentBox {
                        position: relative;
                        height: 100%;
                        padding: 0.5rem 4rem 0.5rem 0.5rem;

                        .listItem {
                            display: inline-flex;
                            align-items: center;
                            background: #ebebeb;
                            cursor: default;
                            max-width: 100%;
                            position: relative;
                            margin: 0.25rem 0.25rem;
                            line-height: 0;
                            padding: 0.1rem 0.3rem;
                            border-radius: 0.2rem;
                            overflow: hidden;

                            .itemText {
                                display: inline-block;
                                font-size: 0.6rem;
                                color: #333;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                line-height: 1rem;
                            }

                            .clickBtn {
                                display: block;
                                line-height: 0;
                                cursor: pointer;
                                margin-left: 0.3rem;

                                .iconfont {
                                    font-size: 0.8rem;
                                    color: #333;
                                    zoom: 0.7;
                                }
                            }
                        }

                        .selectedOperateBox {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            flex-shrink: 0;
                            position: absolute;
                            right: 0.2rem;
                            top: 50%;
                            transform: translateY(-50%);
                            width: 3rem;

                            .listItem {
                                background: #1A9DF9;
                                border-radius: 0.2rem;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                cursor: pointer;
                                margin: 0;


                                .itemText  {
                                    background: none;
                                    color: #fff;
                                }

                                .clickBtn {
                                    position: relative;
                                    top: unset;
                                    right: unset;
                                    line-height: 0;
                                    display: block;
                                    margin-left: 0;

                                    .iconfont {
                                        color: #fff;
                                        zoom: 1;

                                        &.icon-shanchu1 {
                                            font-size: 0.65rem;
                                            margin-right: 0.2rem;
                                        }
                                    }
                                }

                                &.deleteBtn {
                                    flex: 1 1 30%;
                                    background: #ff4d4f;
                                }
                            }
                        }

                        .areaOrDepartmentListBox {
                            flex: 1;
                            max-height: 7rem;
                            overflow: auto;
                            display: flex;
                            flex-direction: column;
                            flex-flow: row wrap;
                            align-items: center;
                            align-content: flex-start;
                            margin: 0 -0.25rem 0;
                        }
                    }
                }

                +.conditionItem {
                    margin-left: 1rem;
                }
            }

            .operationRight{
                flex: 1;
                text-align: right;
            }
        }

        .rightContentBox {
            flex: 1;
            height: 100%;
            overflow: hidden;
            display: flex;
            flex-direction: column;

            .listContent {
                flex: 1;
                overflow: auto;

                .contentText{
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    white-space: pre-wrap;
                    word-wrap: break-word;
                    font-size: 0.65rem;
                    color: #8a8a8a;
                }

                .iconAndTitle{
                    height: 2.5rem;

                    .icon{
                        float: left;
                        //height: 2.25rem;
                        margin: 0.25rem 0.25rem 0 0;

                        .anticon-file-word,.anticon-file-text{
                            font-size: 1.8rem;
                            color: #5FA8F2;
                        }

                        .anticon-file-ppt{
                            font-size: 1.8rem;
                            color: #C43E1C;
                        }

                        .anticon-file-pdf{
                            font-size: 1.8rem;
                            color: #F43A59;
                        }
                    }

                    .contentItem {
                        position: relative;

                        .title {
                            color: #1890ff;
                            font-size: 0.8rem;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            .contentItemTitle{
                                color: #1890ff;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 1;
                                -webkit-box-orient: vertical;
                                white-space: pre-wrap;
                                word-wrap: break-word;
                            }

                            > p {
                                flex: 1;
                                width: 4.5rem;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                color: #000;
                            }

                            .oprationBox{
                                width: 3.5rem;
                                flex-shrink: 0;
                                margin-left: 0.5rem;

                                .iconfont{
                                    font-size: 1rem;
                                    cursor: pointer;
                                    color: #969696;
                                }
                            }

                            .signPolicyOperateBox {
                                flex-shrink: 0;
                                margin-left: 0.5rem;
                                display: flex;
                                align-items: center;

                                .operateBtn {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    width: 1rem;
                                    height: 1rem;
                                    cursor: pointer;

                                    .iconfont {
                                        font-size: 0.8rem;
                                    }

                                    &.recoverBtn {
                                        .iconfont {
                                            color: #52c41a;
                                        }
                                    }
                                    &.deleteBtn {
                                        .iconfont {
                                            color: #ff3a36;
                                        }
                                    }
                                    &.editBtn {
                                        .iconfont {
                                            font-size: 0.9rem;
                                            color: #1890ff;
                                        }
                                    }

                                    +.operateBtn {
                                        margin-left: 0.2rem;
                                    }
                                }
                            }
                        }

                        .signLabelBox {
                            .signLabelContentBox {
                                display: flex;
                                width: 100%;
                                overflow: hidden;
                                align-items: center;

                                > * {
                                    flex-shrink: 0;

                                    + * {
                                        margin-left: 0.5rem;
                                    }
                                }

                                .policySourceType{
                                    .anticon{
                                        color: #666666;
                                        font-size: 0.8rem;
                                    }
                                }

                                .policyLabelListBox {
                                    flex: 1;
                                    height: 1rem;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }
                            }
                        }
                    }
                }

                .listItemFooter{
                    font-size: 0.6rem;
                    color: #666666;
                    display: flex;
                    margin-top: 0.5rem;
                    .listItemFooterItem{
                        flex: 1;
                    }
                    .listItemFooterOperation{
                        .iconfont {
                            float: left;
                            margin-right: 0.25rem;
                        }
                        span{
                            cursor: pointer;
                        }
                    }
                }

                .ant-timeline {
                    .ant-timeline-item {
                        .ant-timeline-item-label {
                            width: 75px;
                            top: 0;
                        }

                        .ant-timeline-item-tail {
                             left: 95px;
                             border-color: #e9e9e9!important;
                             height: 100%;
                        }

                        .ant-timeline-item-head {
                            left: 95px;
                            top: 6px;
                        }

                        .ant-timeline-item-content {
                            cursor: pointer;
                            background: #d7d7d74a;
                            padding: 0.5rem;
                            border-radius: 8px;
                            left: 140px;
                            width: calc(100% - 150px);
                            text-align: left;
                            margin: 0;
                            top: 0;
                        }

                        &.ant-timeline-item-last {
                            padding-bottom: 0;
                        }
                    }
                }
            }

            .pagination {
                flex-shrink: 0;
                margin-top: 0.5rem;
            }
        }
    }

    p {
        margin: 0
    }
}

.regionsPopoverBox{
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .sortTypeListBox {
        flex-shrink: 0;
        display: flex;
        align-items: center;

        .sortItem {
            font-size: 0.7rem;
            color: #333;
            cursor: pointer;
            padding: 0.5rem 0.5rem;
            line-height: 1rem;
            position: relative;

            &.activeStatus {
                &:before {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 0.1rem;
                    background-color: #1A9DF9;
                    bottom: 0;
                    left: 0;
                }
            }

            +.sortItem {
                margin-left: 1rem;
            }
        }
    }

    .areaListBox {
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        background: #F0F5F8;
        padding: 0.5rem;
        border-radius: 0.4rem;

        .regionsAllArea {
            flex-shrink: 0;
            font-size: 0.7rem;
            color: #333;
            font-weight: 600;
            line-height: 1rem;
            cursor: pointer;
            margin-bottom: 0.25rem;
        }

        .regionsAllListBox {
            flex: 1;
            overflow: auto;

            .regionsList {
                display: flex;
                line-height: 1rem;

                .regionsArea {
                    flex-shrink: 0;
                    font-size: 0.7rem;
                    line-height: 1.2rem;
                    color: #333;
                    font-weight: 600;
                    cursor: pointer;
                    margin-right: 0.5rem;
                }

                .regionsBox {
                    flex: 1;
                    overflow: auto;
                    display: flex;
                    flex-flow: row wrap;
                    align-content: flex-start;
                    align-items: center;

                    .regionsItem {
                        display: block;
                        margin: 0.1rem 0.25rem;
                        cursor: pointer;
                        max-width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        font-size: 0.6rem;
                        line-height: 1rem;
                        color: #494848;
                    }
                }
            }
        }


    }

    .footerOperateBox {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 2rem;
        margin-top: 0.3rem;

        .operateBtn {
            flex: 1 1 50%;
            font-size: 0.7rem;
            color: #575757;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #e0e0e0;
            border-radius: 0.2rem;
            height: 1.6rem;

            &.confirmBtn {
                color: #fff;
                background: #1A9DF9;
            }

            +.operateBtn {
                margin-left: 1rem;
            }
        }
    }

    .selectRegion {
        color: #1890ff!important;
    }

    &.departmentPopoverBox {
        padding-top: 0.3rem;

        .areaListBox {
            .regionsAllListBox {
                .regionsList {
                    flex-direction: column;

                    .regionsBox {
                        padding-left: 0.5rem;
                    }

                    +.regionsList {
                        margin-top: 0.5rem;
                    }
                }
            }
        }
    }
}

.areaOrDepartmentTooltipBox {
    max-width: unset !important;
    .ant-tooltip-content {
        margin-right: -0.5rem;

        .ant-tooltip-arrow {
            .ant-tooltip-arrow-content {
                --antd-arrow-background-color: #fff;
            }
        }

        .ant-tooltip-inner {
            background: #fff;
            overflow: hidden;
            border-radius: 0.4rem;
            box-shadow: 0 0 0.7rem 0 rgb(176 176 176);
            padding: 0;

            .areaOrDepartmentContentBox {
                width: 20rem;
                height: 15.5rem;
                padding: 0.2rem 0.5rem;
                overflow: auto;
            }
        }
    }
}
