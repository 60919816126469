.sortManageFrame {
  background-color: rgba(0,0,0,0) !important;
  display: flex;
  flex-flow: column;
  height: 100%;
  overflow: hidden;

  .topBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    .returnBox{
      position: relative;
      height: 100%;
    }

    .addSortBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3.5rem;
      height: 1.8rem;
      background: #60B4F6;
      box-shadow: 0 0.1rem 0.45rem 0 rgba(96,180,246,0.65);
      border-radius: 0.2rem;
      color: #fff;
      cursor: pointer;

      .iconfont {
        font-size: 0.75rem;
      }

      .addSortText {
        font-size: 0.7rem;
        margin-left: 0.2rem;
      }

    }
  }
  
  .contentBox {
    flex: 1;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #fff;

    &.sortTreeBox {
      height: unset;
      .treeRoot {
        .treeSortBox {
          height: 2.8rem;

          .expandIcon {
            font-size: 0.9rem;

            .iconfont {
              font-size: 0.9rem;
              color: #8c8c8c;
            }

            .icon-xiangmu {
              color: #f4650c !important;
            }
          }
        }
      }
    }

    .noneDataBox {
      width: 100%;
      height: 100%;
      flex-flow: column;

      .iconfont {
        font-size: 3rem;
        margin: 0;
      }

      > span {
        font-size: 0.9rem;
        margin-top: 1rem;
      }
    }
  }

  .addSortBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #F4F8FA;
    border-radius: 0.2rem;

    .addSortLeft {
      display: flex;
      align-items: center;

      .sortNameInput {
        width: 6rem;
        height: 1.6rem;
        font-size: 0.7rem;
        color: #333;
      }

      .switchBox {
        display: flex;
        align-items: center;
        margin-left: 5rem;

        .switchText {
          font-size: 0.8rem;
          color: #333;
          cursor: default;
          margin-right: 1.35rem;
        }

        .ant-switch {
          width: 3.8rem;
          height: 1.6rem;
          line-height: 1.6rem;

          &.ant-switch-checked {
            background: #FF3116;
            .ant-switch-handle {
              inset-inline-start: calc(100% - 1.5rem);
            }
          }

          .ant-switch-handle {
            width: 1.4rem;
            height: 1.4rem;
            border-radius: 50%;
            overflow: hidden;
          }
        }
      }
    }

    .addSortRight {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      > span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 6.3rem;
        height: 1.8rem;
        border-radius: 0.2rem;
        font-size: 0.8rem;
        cursor: pointer;
      }
      .cancelBtn {
        background: #fff;
        color: #333;
        margin-right: 1rem;
      }

      .confirmBtn {
        background: #60B4F6;
        color: #fff;
        box-shadow: 0 0.1rem 0.45rem 0 rgba(96,180,246,0.65);
      }
    }
  }
}

.sortModal {
  .ant-modal-body {
    position: relative;
    padding-top: 1.8rem;

    .tipTextBox {
      display: flex;
      align-items: center;
      overflow: hidden;
      width: 100%;
      height: 1.8rem;
      position: absolute;
      top: 0;
      left: 0;
      padding: 0 1.2rem;
      font-size: 0.7rem;
      color: #ff1616;

      &:after {
        content: '';
        position: absolute;
        left: 1.2rem;
        bottom: 0;
        width: calc(100% - 2.4rem);
        height: 0.05rem;
        background-color: #eee;
      }

      .tipLabel {
        flex-shrink: 0;
      }

      .tipContent {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}