.edictKnowledgeLoadingBox{
    width: 100%;
    height: 100%;
    .ant-spin-container{
        height: 100%;
    }
}
.edictKnowledgeFrame{
    display: flex;
    height: 100%;
    .leftBox{
        flex: 1;
        background-color: #fff;
        border-radius: 5px;
        position: relative;
        .disabledBox{
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: rgba(0,0,0,0.2);
            border-radius: 5px;
            z-index: 1;
        }
        .fileListFrame{
            .topTitle{
                display: flex;
                padding: 10px;
                .ant-typography{
                    float: left;
                    height: 32px;
                    line-height: 32px;
                    margin-bottom: 0;
                    margin-right: 10px;
                }
                .searchBox{
                    flex: 1;
                }
            }
            .selfTable{
                margin: 10px;
            }
        }
        .previewFileFrame{
            height: 100%;

            .detail {
                height: 100%;
                #editor{
                    height: 100%;
                    .edui-editor {
                        height: 100%;
                        .edui-editor-iframeholder{
                            height: calc(100% - 90px) !important;
                        }
                    }
                }
            }

            .custom-mount{
                height: 100%;
            }
            .zipChildFileList {
                flex: 1;
                height: 100%;
                background: #fff;
                overflow: hidden;
                padding: 1rem;
          
                .scrollBox {
                  height: 100%;
                  overflow: auto;
                  margin-right: -0.3rem;
                  padding-right: 0.3rem;
          
                  .fileItem {
                    padding: 0.5rem;
                    background: #eee;
                    overflow: hidden;
                    border-radius: 0.4rem;
                    line-height: 1rem;
                    cursor: pointer;
          
                    .infoBox {
                      display: flex;
                      align-items: center;
          
                      .anticon {
                        flex-shrink: 0;
                        margin-right: 0.5rem;
                      }
          
                      .fileName {
                        flex: 1;
                        font-size: 0.8rem;
                        color: #333;
                      }
                    }
          
                    .timeText {
                      display: block;
                      padding-left: 1.5rem;
                      font-size: 0.7rem;
                      margin-top: 0.5rem;
                      color: #868686;
                    }
          
                    +.fileItem {
                      margin-top: 1rem;
                    }
                  }
          
                  .noneDataBox {
                    flex-direction: column;
                    height: 100%;
          
                    .iconfont {
                      margin: 0;
                    }
          
                    > span {
                      line-height: 1rem;
                      font-size: 0.7rem;
                      margin-top: 0.2rem;
                    }
                  }
                }
            }
        }
    }
    .rightBox{
        margin-left: 10px;
        width: 390px;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 5px;
        .toptitle{
            padding: 10px 15px 0 15px;
        }
        .uploadBtn{
            width: 200px;
            margin: 0 auto 10px auto;
            #addFfileInput{
                opacity: 0;
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }
        .fromBox{
            flex: 1 1;
            overflow-y: auto;
            padding: 10px 15px;
            overflow-x: hidden;
            .tipsBox{
                text-align: center;
                margin: 100px 80px;
                font-size: 14px;
                .fileIcon{
                    // width: 100px;
                    margin: 20px auto;
                }
            }
        }
        .bottonBox{
            height:62px;
            text-align: right;
            padding: 10px 15px;
        }
    }
}