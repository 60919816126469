.menuListFrame{
    background: white;
    .title{
        padding: 0.5rem 1rem;
        font-size: 1.2rem
    }
    .operation{
        padding: 0 1rem 1rem 1rem;
    }
    .table{
        padding: 0 1rem 0 1rem;
    }
}

.menuListModalBox {
    .addFromIconBox {
        .iconBox {
            display: flex;
            align-items: center;
            overflow: hidden;

            .ant-input {
                flex: 1;
            }

            .iconColorBar {
                display: block;
                width: 3rem;
                height: 1rem;
                flex-shrink: 0;
                margin-left: 1rem;
            }
        }

        .iconInstanceBox {
            margin-top: 0.5rem;
            position: relative;

            .iconfont {
                font-size: 3rem;
                background: #fff;
                z-index: 1;
            }

            .tipText {
                position: absolute;
                z-index: 0;
                top: 0;
                left: 0;
                display: block;
                font-size: 0.6rem;
                color: #c9c8c8;
            }
        }
    }
}
