.mySpaceFrame{
    height: 100%;
    background: #fff;
    padding: 15px 15px 0;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    line-height: 1rem;

    .topTitle {
        flex-shrink: 0;
        margin-bottom: 1rem;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        white-space: nowrap;

        .searchBox, .rightOperateBox {
            display: flex;
            align-items: center;
        }

        .searchBox {
            .title {
                flex-shrink: 0;
                font-size: 0.8rem;
                font-weight: bold;
                color: #1D2129;
            }

            .ant-input-search {
                margin-left: 2rem;
                .ant-input {
                    border-color: #F2F3F5;
                    background: #F2F3F5;
                    border-radius: 0.1rem;
                    box-shadow: none;
                }

                .ant-input-group-addon {
                    .ant-btn {
                        border: none;
                        background: #F2F3F5;
                    }
                }
            }

            .AiSearchBtn {
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                opacity: 0.7;
                margin-left: 1rem;

                .iconfont {
                    margin-right: 0.3rem;
                    font-size: 0.9rem;
                    color: #1890ff;
                }

                span {
                    font-size: 0.7rem;
                    color: #4E5969;
                }
            }
        }

        .rightOperateBox {
            justify-content: flex-end;
            user-select: none;

            .selectedOperateBox {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0.2rem 0.6rem;
                border-radius: 0.4rem;
                border: 0.05rem solid #BFBFBF;
                margin-right: 3rem;
                opacity: 0;

                .operateBtn {
                    font-size: 0.8rem;
                    color: #409EFF;
                    cursor: not-allowed;

                    +.operateBtn {
                        margin-left: 0.8rem;
                    }
                }
            }

            .selectedInfo {
                display: flex;
                align-items: center;
                margin-right: 2rem;

                .infoDesc {
                    font-size: 0.6rem;
                    color: #555;
                }

                .cancelSelectedBtn {
                    font-size: 0.6rem;
                    color: #409EFF;
                    cursor: not-allowed;
                    opacity: 0.7;
                    margin-left: 0.5rem;
                }
            }

            .uploadFileBtn {
                display: flex;
                align-items: center;
                font-size: 0.7rem;
                color: #4E5969;
                cursor: pointer;

                .iconfont {
                    margin-right: 0.3rem;
                    font-size: 1rem;
                    color: #4E5969;
                }
            }

            &.haveSelectedStatus {
                .selectedOperateBox {
                    opacity: 1;

                    .operateBtn {
                        cursor: pointer;
                    }
                }

                .selectedInfo {
                    .cancelSelectedBtn {
                        opacity: 1;
                        cursor: pointer;
                    }
                }

                .uploadFileBtn {
                    cursor: not-allowed;
                    opacity: 0.7;
                }
            }
        }
    }

    .tableBox {
        flex: 1;
        overflow: hidden;

        .shareStatusBox {
            .iconfont {
                top: -0.1rem;
                margin-right: 0.1rem;

                &.icon-dui {
                    font-size: 0.7rem;
                }
            }
        }

        .operateBtnBox {
            display: flex;
            align-items: center;
            justify-content: right;
            > .operateBtn {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 2.7rem;
                height: 1.3rem;
                background: #409EFF;
                color: #fff;
                cursor: pointer;
                border-radius: 0.2rem;

                &.shareBtn {
                    background: #23C343;
                }
                &.disbleBtn {
                    background: #ccc;
                    cursor: no-drop;
                }

                +.operateBtn {
                    margin-left: 0.5rem;
                }
            }

            .moreBtn {
                background: #F7BA1E;

                .moreOperateBox {
                    width: 5rem;

                    .operateBtn {
                        display: block;
                        font-size: 0.7rem;
                        color: #555;
                        line-height: 1.4rem;
                        cursor: pointer;
                        position: relative;

                        &:hover {
                            color: #409EFF;
                        }

                        &:active {
                            color: #409EFF;
                        }

                        &.deleteBtn {
                            .ant-popover {
                                transform: unset;
                            }

                            .ant-popover-placement-left .ant-popover-arrow {
                                top: 50%;
                            }
                        }

                        &.downloadBtn {
                            .ant-popover {
                                transform: unset;
                            }

                            .ant-popover-placement-left .ant-popover-arrow {
                                top: 50%;
                            }
                        }
                    }
                }

                .ant-popover {
                    z-index: 500;

                    &.ant-popover-placement-leftBottom {
                        .ant-popover-content {
                            .ant-popover-arrow {
                                bottom: 0.1rem;
                            }
                        }
                    }

                    &.ant-popover-placement-leftTop {
                        .ant-popover-content {
                            .ant-popover-arrow {
                                top: 0.1rem;
                            }
                        }
                    }

                    .ant-popover-inner {
                        box-shadow:  0 4px 16px 2px rgba(0, 0, 0, 0.3);
                        border-radius: 0.2rem;
                    }
                }
            }
        }

        .knowledgeNameBox {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;

            .knowledgeName {
                flex: 1;
                cursor: pointer;
                color: #1A9DF9;
                text-decoration: underline #1A9DF9 solid;
            }

            .AiBtnBox {
                flex-shrink: 0;
                margin-left: 0.5rem;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                width: 4.2rem;
                height: 1rem;
                border-radius: 0.5rem;
                // border: 0.05rem solid #1890ff;
                cursor: pointer;
                opacity: 0.7;
                overflow: hidden;

                .AiBtn {
                    flex-shrink: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 1.5rem;
                    height: 100%;
                    // background: #409EFF;
                    color: #fff;
                    position: relative;

                    .icon-waixingren {
                        font-size: 0.7rem;
                    }

                    .icon-jiahao {
                        font-size: 1rem;
                        zoom: 0.3;
                        position: absolute!important;
                        bottom: 0.4rem;
                        left: 3.2rem;
                    }
                }

                .shoppingCartBtnAvailable{
                    background: #1890ff;
                    border: 0.05rem solid #1890ff;
                }
                .shoppingCartBtnNotAvailable{
                    background: #a0a0a0;
                    border: 0.05rem solid #a0a0a0;
                    cursor: no-drop;
                }

                .AiReadBtn {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 0.5rem;
                    border-radius: 0 0.5rem 0.5rem 0;
                    line-height: 18px;
                    color: #3D3D3D;
                    border: 0.05rem solid #1890ff;
                }
            }
        }
    }
}

.mySpaceTableMoreOperateBox {
    width: 5rem;

    .operateBtn {
        display: block;
        font-size: 0.7rem;
        color: #555;
        line-height: 1.4rem;
        cursor: pointer;
        position: relative;

        &:hover {
            color: #409EFF;
        }

        &:active {
            color: #409EFF;
        }

        &.deleteBtn {
            .ant-popover {
                transform: unset;
            }

            .ant-popover-placement-left .ant-popover-arrow {
                top: 50%;
            }
        }

        &.downloadBtn {
            .ant-popover {
                transform: unset;
            }

            .ant-popover-placement-left .ant-popover-arrow {
                top: 50%;
            }
        }
    }
}
