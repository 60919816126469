.policyListModal{
    .sortBox{
        height: 1.7rem;
        border-bottom: 1px solid #f0f0f0;
        line-height: 1.5rem;
        .sort>div{
            font-size: 0.7rem;
            float: left;
            margin-right: 1rem;
            cursor: pointer;
        }
        .timeSort{
            position: relative;
            .anticon-caret-up{
                position: absolute;
                top: 5px;
                right: -20px;
                margin-right: 0;
                font-size: 0.6rem;
            }
            .anticon-caret-down{
                position: absolute;
                top: 13px;
                right: -20px;
                margin-right: 0;
                font-size: 0.6rem;
            }
        }
        .activeKeyClass{
            color: #1890ff;
            text-shadow: 0 0 0.25px currentcolor;
        }
    }
    .searchBox{
        float: right;
    }
    .listBox{
        overflow: auto;
        .listItem{
            cursor: pointer;
            background: #F0F5F8;
            border-radius: 8px;
            padding: 1rem;
            margin-bottom: 1rem;
        }
        .listItemHeader{
            font-size: 0.6rem;
            color: #666666;
            display: flex;
            .anticon {
                margin-right: 0.5rem;
            }

            .iconfont {
                margin-right: 0.5rem;
                top: 0.05rem;
            }

            .listTitle{
                flex: 1;
                font-size: 0.8rem;
            }
            .releaseTime{
            }
        }
        .listItemContent{
            font-size: 0.7rem;
            color: #666666;
            margin-bottom: 0.7rem;
            margin-top: 0.5rem;
            padding-left: 1.5rem;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            white-space: pre-wrap;
            word-wrap: break-word;
            word-break: break-all;
            h1,h2,h3{
                font-size: 0.7rem;
            }
        }
        .listItemFooter{
            font-size: 0.6rem;
            color: #666666;
            padding-left: 1.5rem;
            display: flex;
            margin-top: 0.5rem;
            .listItemFooterItem{
                flex: 1;
            }
            .listItemFooterOperation{
                .iconfont {
                    float: left;
                    margin-right: 5px;
                }
            }
        }
    }
    .pagination{
        text-align: right;
        margin-top: 10px;
        margin-right: 10px;
    }
}
