.excerptFrame{
    .title{
        display: flex;
        color: #333333;
        font-size: 0.7rem;
    }
    .textContent{
        width: 100%;
        font-size: 0.7rem;
        line-height: 1rem;
        word-break: break-all;
        color: #9f9f9f;
        position: relative;
    }
    .pagination{
        margin-top: 0.5rem;
        text-align: right;
    }
    .content{
        background: #ffffff;
        border-radius: 4px;
        padding: 0.5rem;
        margin-top: 0.5rem;
        cursor: pointer;
        .moreBtn{
            font-size: 0.7rem;
            color: #004693;
            text-align: right;
            cursor: pointer;
        }
        .moreBtnOpen{
            padding-left: 0.5rem;
            z-index: 11;
            outline: 0;
            position: absolute;
            right: 0;
            bottom: 0;
            background: #fff;
        }
    }
    .defalutRotate{
        // position: absolute !important;
        // right: 10px;
        // top: 50%;
        margin-left: 50%;
        transform: translateX(-50%);
        cursor: pointer;
    }
    .textEllipsis{
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        white-space: pre-wrap;
        word-wrap: break-word;
        word-break: break-all;
    }
    .operation{
        float: right;
        font-size: 0.6rem;
        .iconfont{
            cursor: pointer;
        }
    }
    .noteText{
        width: 100%;
        padding-top: 0.5rem;
        font-size: 0.7rem;
        line-height: 1rem;
        word-break: break-all;
        cursor: pointer;
        color: #9f9f9f;
    }
    .textEllipsis{
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        white-space: pre-wrap;
        word-wrap: break-word;
        word-break: break-all;
    }
    .defalutRotate{
        margin-left: 50%;
        transform: translateX(-50%);
        cursor: pointer;
    }

    .notePagination{
        padding-top: 0.5rem;
        text-align: right;
    }

}
