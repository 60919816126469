.userFrame{
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .userTabs {
        height: 100%;
        overflow: hidden;
        background: #fff;
    }

    .ant-tabs-nav{
        border-bottom: 10px solid #f0f2f5;
        padding: 0 1rem;
    }
    .ant-tabs-tab-btn{
        font-size: 0.9rem;
    }
}
