.personalknowledgeModal{
    .ant-modal-content{
        border-radius: 8px;
        
    }

    .headerTitle{
        font-size: 18px;
        .anticon-info-circle{
            margin-right: 10px;
        }
    }

    .content{
        display: flex;
        margin-top: 1rem;
        .left{
            width: 170px;
            border: 1px solid #979797;
            border-radius: 8px;
            padding: 1rem 0.5rem;
            .item{
                color: #797979;
                font-size: 0.8rem;
                margin-bottom: 0.5rem;
                padding: 5px 0.5rem;
                border-radius: 8px;
                cursor: pointer;
                .anticon-folder-open{
                    margin-right: 5px;
                }
            }
            .selectedItem{
                background: #F5F7FA;
                color: #409EFF;
            }
        }
        .right{
            flex: 1;
            margin-left: 20px;
            .searchBox{
                margin-top: 1rem;
                display: flex;
                margin-bottom: 1rem;
                line-height: 32px;
                .searchInput{
                    flex: 1;
                    height: 32px;
                    line-height: 32px;
                    .ant-input-affix-wrapper{
                        background: #F2F3F5;
                        border: 0;
                    }
                    .ant-input{
                        background: #F2F3F5;
                        border: 0;
                    }
                    .ant-input-search-button{
                        border: 0;
                        background: #F2F3F5;
                        height: 30px;
                    }
                    .inputTitle{
                        color: #1D2129;
                    }
                    .ant-input-search{
                        width: 300px;
                    }
                }
                .startConversation{
                    margin-left: 10px;
                    .anticon-file-add{
                        color: #60B4F6;
                        font-size: 18px;
                    }
                }
                .ant-btn-primary{
                    background: #60B4F6;
                    border: 0;
                }
            }
        }
    }
    
}