.addForm {
  // width: 100%;
  // height: 100%;
  // overflow-y: scroll;
  form {
    font-size: 0.7rem;
    .formItemOpcity {
      opacity: 0;
      height: 0;
    }
    .formItemBlock {
      opacity: 1;
    }
  }
  .ant-checkbox-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    // align-items: center;
    label {
      margin: 0 0 0.3rem 0;
      width: 46%;
    }
  }
  .addTagBtn {
    margin-left: 0.5rem;
    display: inline-block;
    width: 1.3rem;
    height: 1.3rem;
    line-height: 1.2rem;
    text-align: center;
    border: dashed 1px #ccc;
  }
  .addTagInp {
    padding-left: 0.5rem;
    border: solid 0.01rem #ccc;
    width: 8rem;
    height: 1.3rem;
  }
  .lableItem{
    display: flex;
    margin-bottom: 8px;
    >span{
      width: 100px;
    }
  }
  .tags {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    // background: pink;
    div {
      margin-bottom: 0.2rem;
      display: block;
      width: 23%;
      height: 1rem;
      line-height: 1rem;
      background: #eee;
    }
  }
  .isCheckedTag {
    label {
      padding-left: 0.5rem;
      color: #999;
    }
  }
  .departmentSubclassificationId,.knowledgeSource,.projectSelect,.startRangeTime {
    label {
      padding-left: 0.5rem;
    }
  }
  
  .userSelect {
    .ant-select-selection-overflow-item {
      display: none;
    }
    .ant-select-selection-overflow-item-suffix {
      display: inline-block;
    }
  }
  .userTagRender {
    width: 100%;
    .ant-tag {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.2rem;
      width: 100%;
      height: 1.3rem;
      font-size: 0.7rem;
      .tagInfo {
        width: 80%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      // .anticon,.anticon-close,.ant-tag-close-icon{
      //   float: right;
      // }
    }
  }

  .vertical {
    .ant-col,
    .ant-col-24,
    .ant-form-item-control {
      // width: 90%;
    }
    .ant-checkbox-wrapper,
    .ant-checkbox-wrapper-in-form-item {
      font-size: 0.65rem;
    }
  }
  .selectedUserList{
    label{
      padding-left: 0.5rem;
    }
  }
  .shareDepartmentIds {
    .addForm,
    .ant-checkbox-group {
      justify-content: initial;
    }
    .ant-checkbox + span{
      display: flex;
      // align-items: center;
      margin-top: 0.1rem;
    }
    label {
      .checkInfo {
        display: inline-block;
        width: 5.5rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    label:nth-child(2n+1){
      margin-right: 1.1rem;
    }
  }
  .ant-form-item-control-input-content {
    width: 100%;
    .ant-form-item {
      width: 100%;
    }
  }
  .selectInfo {
    display: flex;
    flex-direction: column;
    width: 100%;
    .ant-tag {
      position: relative;
      margin-bottom: 0.2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.7rem;
      min-width: 100%;
      height: 1.5rem;
      border: none;
      color: #555;
      background: #f4f8fa;
      .anticon,
      .anticon-close-circle {
        position: absolute;
        right: 0.5rem;
      }
    }
  }
  .edit-tag {
    margin-bottom: 0.2rem;
    position: relative;
    padding-right: 1.5rem;
    height: 1.2rem;
    line-height: 1.2rem;
    border: none;
    color: #555;
    background: #f4f8fa;
    .anticon,
    .anticon-close-circle {
      position: absolute;
      right: 0.2rem;
      bottom: 0.25rem;
    }
  }
  .site-tag-plus {
    height: 1.2rem;
    line-height: 1.2rem;
    border: dashed 0.01rem #ccc;
    color: #555;
    background: #f4f8fa;
  }
}
.userDropdown{
  width: 300px;
  height: 340px;
  overflow: auto;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 3px 6px -4px, rgba(0, 0, 0, 0.08) 0px 6px 16px, rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
  padding: 10px;
  background: rgb(255, 255, 255);
  left: 310px !important;
  top: 283px !important;
  .ant-checkbox-wrapper{
    height: 1rem;
    overflow: hidden;
    margin: 0 0 5px 8px;
  }
  .ant-input{
    margin-bottom: 5px;
  }
  .dropdown-content{
    .contentUserList{
      max-height: 200px;
      overflow: auto;
      background: #fff;
    }
  }
  .ant-tree-checkbox-disabled{
    display: none
  }
  .ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper {
    color: inherit;
  }
}

.selectAuthorModal{
  .ant-modal-header{
    cursor: all-scroll;
  }
  .ant-modal-body{
    position: relative;
    .ant-transfer{
      height: 400px;
      overflow: auto;
      .ant-transfer-list:nth-of-type(1){
        height: 400px !important;
        overflow: auto !important;
      }
      .ant-transfer-list-body{
        overflow: auto;
      }
    }
    
    .custonAuthor{
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      position: absolute;
      right: 24px;
      bottom: 24px;
      width: 306px;
      height: 140px;
      display: flex;
      flex-direction: column;
      .ant-input-group{
        position: absolute;
        right: 0;
        left: 0;
        bottom: 0;
        width: 304px;
      }
      .custonlist{
        flex: 1;
        overflow-y: auto;
        overflow-x: hidden;
        margin-bottom: 32px;
        .custonlistItem{
          margin: 6px 12px;
          width: 280px;
          background: #fff;
          border: 1px solid #d9d9d9;
          color: rgba(0, 0, 0, 0.85);
          font-size: 14px;
          padding: 0 5px;
          display: flex;
          .custonlistItemText{
            flex: 1
          }
          .anticon-close-circle{
            margin-top: 5px;
          }
        }
      }
      
    }
  }
}
.overseasCaseTree{
  .anticon-file-text{
    font-size: 0.7rem;
  }
}