.loginFrame {
    display: flex;
    width: 100vw;
    height: 100vh;
    // min-width: 1200px;
    min-height: 500px;
    position: relative;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    background-image: url("../../assets/img/login-bg.png");
    background-size: 100% 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;

    .logoBox {
        position: fixed;
        left: 1.6rem;
        top: 1.25rem;
        display: flex;
        height: 2.2rem;
        align-items: flex-end;

        .LogoLog {
            width: 13.5rem;
            height: auto;
            img{
                width: 100%;
            }
        }
    }

    .logoImg {

        height: 1.05rem;
        width: auto;
    }

    .loginBox {
        width: 17.5rem;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border-radius: 0.4rem;
        padding: 3rem 1.7rem;
        .tips{
            font-size: 0.7rem;
            color: #60B4F6;
            .anticon-exclamation-circle{
                margin-right: 8px;
            }
        }
        .loginTitle {
             width: 8.4rem;
             margin-bottom: 3rem;
             img{
                width: 100%;
             }
         }

        .userPwd, .userName {
            input {
                color: #666;

                &::placeholder {
                    color: #CCC;
                }
            }
            &:before {
                font-size: 0.7rem;
                color: #666;
                width: 2.1rem;
                line-height: 1;
                position: absolute;
                left: 0.75rem;
                top: 0.55rem;
                z-index: 3;
                cursor: default;
                text-align-last:justify;
                text-align:justify;
                text-justify: distribute-all-lines;
            }
        }

        .userName {
            position: relative;
            .textPrompt{
                position: absolute;
                top: 7px;
                left: 43px;
                transform: translateX(-50%);
                z-index: 2;
                letter-spacing: 14px;
            }
            // &:before {
            //     content: '工号';
            // }

            .ant-input {
                border: none;
                background: #F4F8FA;
                border-radius: 0.4rem;
                height: 1.8rem;
                padding-right: 1.6rem;
                padding-left: 4rem;

                &:focus {
                    box-shadow: none;
                }

                &:hover {
                    background-color: #F4F8FA;
                }
            }

            .iconfont {
                position: absolute !important;
                color: rgba(0, 0, 0, 0.45);
                font-size: 0.8rem;
                right: 0.65rem;
                top: 0.55rem;
            }
        }

        .userPwd {
            margin-top: 1.5rem;
            position: relative;

            .textPrompt{
                position: absolute;
                top: 7px;
                left: 43px;
                transform: translateX(-50%);
                z-index: 2;
                letter-spacing: 14px;
            }
            // &:before {
            //     content: '密码';
            // }


            .ant-input-password {
                border: none !important;
                background: #F4F8FA;
                border-radius: 0.4rem;
                height: 1.8rem;
                box-shadow: none;
                padding-right: 0.65rem;
                padding-left: 0;

                &:hover {
                    background-color: #F4F8FA;
                }

                input {
                    height: 100%;
                    background-color: #F4F8FA;
                    padding-left: 4rem;
                }
            }
        }
        .mobileInput{
            background: #fff !important;
            input{
                background: #fff !important;
            }
        }
        .loginBtn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 2.1rem;
            font-size: 0.7rem;
            background-color: #60B4F6;
            color: #fff;
            cursor: pointer;
            margin-top: 1.8rem;
            box-shadow: 0 0.35rem 1rem 0 rgba(8, 142, 247, 0.41);
            border-radius: 0.4rem;
        }
    }

    .sliderVerifyBox {
        position: absolute;
        width: 50%;
        height: auto;
        left: 25%;
        top: 50%;
        transform: translateY(-50%);
        z-index: 5;
    }
    .sliderVerifyMobileBox {
        width: 100%;
        position: absolute;
        height: auto;
        top: 50%;
        transform: translateY(-50%);
        z-index: 5;
    }
    .ant-spin-blur{
        opacity: 1;
    }

    .bottomContent {
        position: absolute;
        bottom: 0;
        z-index: 1;
        width: 100%;
        text-align: center;
        font-size: 0.6rem;
        color: #adadad;
        line-height: 1.6rem;
    }
}

.loginSpinBox{
    display: flex;
    width: 100vw;
    height: 100vh;
    min-height: 500px;
    // position: relative;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    .spinText{
        // position: absolute;
        // left: 50%;
        // transform: translateX(-50%);
        // text-align: center;
        // width: 100%;
        padding-top: 5px;
        font-size: 14px;
        text-shadow: 0 1px 2px #fff;
        color: #1890ff;
    }
}