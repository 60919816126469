/* 暂无数据 */
.noneDataBox {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: #c9c8c8;
  user-select: none;
  cursor: default;

  .iconfont {
    font-size: 2rem;
    margin-right: 0.5rem;
  }

  > span {
    font-size: 1rem;
  }
}