.knowledgeSearchFrame{
    // background: #f0f2f5;
    height: 100%;
    overflow: hidden;
    padding: 10px 0;
    background: #fff;

    .knowledgeSearchFrameScroll{
        height: 100%;
        overflow: auto;
    }
    // .ant-spin-nested-loading {
    //     height: 100%;
    //     overflow: hidden;

    //     .ant-spin-container {
    //         height: 100%;
    //         overflow: hidden;
    //         display: flex;
    //         flex-direction: column;

            > div {
                width: 100%;
                flex-shrink: 0;
                overflow: hidden;
            }

            .flexdTop{
                position: fixed;
                z-index: 1;
                right: 0.5rem;
                left: 0.5rem;
                top: 3.5rem;
            }

            .topSearch{
                background: #fff;
                border-radius: 8px;
                padding: 0 0.7rem 0.7rem 0.7rem;
                .topSearchInput{
                    display: flex;
                }
                .searchBar{
                    width: 52rem;
                    height: 2.5rem;
                    background: #F1F5F9;
                    border-radius: 8px;
                    position: relative;
                    .verticalDivider{
                        float: left;
                        height: 1.5rem;
                        background: #BBCFDB;
                        margin-top: 10px;
                    }
                    .ant-select{
                        float: left;
                        margin: 5px 0 5px 5px;
                        height: 2rem;
                    }
                    .ant-select-selector{
                        padding: 0;
                    }
                    .ant-input-affix-wrapper{
                        width: 33rem;
                        height: 2rem;
                        float: left;
                        margin: 5px 0.5rem;
                        padding: 0;
                    }
                    .searchBtn{
                        border-radius: 8px;
                        position: absolute;
                        right: 0.5rem;
                        top: 50%;
                        transform: translateY(-50%);
                        background: #60B4F6;
                        border: 0;
                    }
                }

                .AiBtnBox {
                    .ant-btn{
                        border-radius: 8px;
                        border: 0;
                        height: 2.5rem;
                    }
                    .icon-waixingren {
                        vertical-align: initial !important;
                        line-height: initial !important;
                        font-size: 22px;
                        color: #60B4F6;
                    }
                }
                .toolBox{
                    position: relative;
                    .ant-tabs-nav{
                        margin-bottom: 0;
                    }
                    .moreTool{
                        position: absolute;
                        right: 0;
                        top: 13px;
                        font-size: 14px;
                        cursor: pointer;
                    }
                    .ant-collapse-header{
                        padding: 0;
                    }
                    .ant-tabs-top > .ant-tabs-nav::before{
                        border: 0;
                    }
                    .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar{
                        bottom: 5px;
                    }
                }

                .moreSearchBar{
                    background: #F1F5F9;
                    border-radius: 8px;
                    // margin-top: 1rem;
                    padding: 0.5rem;
                    font-size: 0.8rem;
                    .ant-picker{
                        padding: 0;
                    }
                    .ant-typography{
                        width: 90px;
                        padding-right: 1rem;
                    }

                    >div{
                        padding: 0.3rem 0;
                        display: flex;
                    }
                    .departmentList{
                        .ant-checkbox-wrapper{
                            min-width: 200px;
                            margin-left: 8px;
                        }
                    }
                    .knowledgeTypeList{
                        .ant-checkbox-wrapper{
                            min-width: 110px;
                            margin-left: 8px;
                        }
                        .ant-radio-wrapper{
                            min-width: 110px;
                            margin-left: 8px;
                        }
                    }
                    .policyList{
                        margin-left: 8px;
                        .ant-radio-group{
                            >div{
                                display: inline-block;
                                margin-right: 20px;
                                height: 22px;
                                line-height: 22px;
                                >span{
                                    float: right;
                                }
                            }
                            .iconfont {
                                font-size: 14px;
                                cursor: pointer;
                                float: right;
                                margin-top: 5px;
                                :hover{
                                    color: #1890ff;
                                }
                            }
                        }

                    }
                    .reportList{
                        position: relative;

                        .letterClassifyList{
                            .letterItem{
                                margin: 0 10px;
                                cursor: pointer;
                                font-weight: 500;
                            }
                            .letterItem:hover{
                                color: #1890ff;
                            }
                            .seletedLetter{
                                color: #1890ff;
                            }
                            .searchBtn{
                                position: absolute;
                                right: 70px;
                            }
                        }
                        .expandBtn{
                            position: absolute;
                            right: 0;
                            top: 0;
                        }
                        .ant-checkbox-wrapper{
                            min-width: 100px;
                            margin-left: 8px;
                        }
                    }


                    .ant-checkbox:hover .ant-checkbox-inner,.ant-radio:hover .ant-radio-inner{
                        border-color: #FF3320;
                    }
                    .ant-checkbox-inner,.ant-radio-inner{
                        width: 12px;
                        height: 12px;
                        top: -2px;
                        background: #B8C3CB;
                    }
                    .ant-checkbox-checked .ant-checkbox-inner,.ant-radio-checked .ant-radio-inner{
                        border-color: #FF3320;
                        border: 0;
                        background: #FF3320;
                        width: 12px;
                        height: 12px;
                        box-shadow: 0px 0px 6px 1px rgba(255,51,32,0.49);
                        top: -2px;
                    }
                    .ant-checkbox-inner::after,.ant-radio-inner::after{
                        background-color: #FF3320
                    }
                }

                .ant-collapse-header{
                    display: none !important;
                }
            }

            .selectedConditions{
                display: flex;
                margin: 0.3rem 0.7rem 0 0.7rem;
                flex-shrink: 0;
                overflow: auto;
                transition: max-height linear 0.3s;

                .ant-typography{
                    font-size: 16px;
                    margin-right: 10px;
                    margin-top: 5px;
                }
            }

            .searchList {
                flex: 1;
                background: #fff;
                border-radius: 8px 8px 0 0;
                padding: 0 0.7rem 0 0.7rem;
                margin-top: 10px;
                position: relative;
                display: flex;
                flex-direction: column;


                .timeSort {
                    position: relative;

                    .anticon-caret-up{
                        position: absolute;
                        top: 2px;
                        right: -20px;
                        margin-right: 0;
                        font-size: 0.6rem;
                    }
                    .anticon-caret-down{
                        position: absolute;
                        top: 10px;
                        right: -20px;
                        margin-right: 0;
                        font-size: 0.6rem;
                    }
                }

                .ant-tabs-top > .ant-tabs-nav::before{
                    border-bottom: 0;
                }
                .searchNumber{
                    font-size: 0.8rem;
                    padding-top: 0.5rem;
                    flex-shrink: 0;

                    .num{
                        color: #FF3320;
                        padding: 0 0.3rem
                    }
                }

                .contentListsFrame {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    overflow: hidden;

                    .sortBox {
                        .ant-tabs {
                            .ant-tabs-ink-bar {
                                bottom: 0.3rem;
                            }
                        }
                    }

                    .knowledgeListFrame {
                        flex: 1;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        margin-top: 0.5rem;
                        margin-bottom: 0;
                        overflow: hidden;

                        .listBox {
                            flex: 1;
                            overflow: auto;
                        }

                        .pagination {
                            flex-shrink: 0;
                            margin-top: 0.2rem;

                            .ant-pagination-options {
                                background: none !important;
                            }
                        }
                    }
                }
            }
    //     }
    // }

    .topTitle{
        height: 30px;
        vertical-align: bottom;
        margin-bottom: 0.5rem;
        display: flex;
        align-items: flex-end;
        .KnowledgeText{
            width: 3.4rem;
            margin-left: 0.5rem;
            margin-bottom: 2px;
            img{
                width: 100%;
            }
        }
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header{
        padding: 12px 16px 0 0;
    }
    .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box{
        padding: 0;
    }

    .flexBox{
        flex: 1;
    }

}



// 添加公共知识模态框样式
.carPublicModal{
    background: #fff;
    .topSearchInput{
        justify-content: space-between;
    }

    // .ant-spin-nested-loading .ant-spin-container {
        .searchList{
            padding: 0;
        }
        .topSearch{
            padding: 10px 0 0 0;
            .searchBar {
                width: 39.5rem !important;
                height: 2rem !important;
                .ant-select{
                    height: 1.5rem;
                    .ant-select-selector{
                        height: 30px;
                        .ant-select-selection-item{
                            line-height: 30px;
                        }
                    }
                }
                .verticalDivider{
                    height: 1.1rem;
                }
                .ant-input-affix-wrapper{
                    width: 27rem;
                    height: 1.5rem;
                }
                .icon-sousu{
                    cursor: pointer;
                }
            }
        }
    // }

    .addCarBtn{
        background: #60B4F6;
        border: 0;
        height: 40px;
        line-height: 40px;
        border-radius: 5px;
        padding: 0 20px;
    }
}