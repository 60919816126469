// 展开
.noteManagePageFrame{
  background: #ffffff;
  border-radius: 4px;
  margin-top: 0.5rem;
  padding: 0.5rem;
  .theOriginal{
    color: #9f9f9f;
    font-size: 0.6rem;
    margin-top: 0.5rem;
    line-height: 20px;
    position: relative;
  }
  .theOriginalMore{
    height: 60px;
    overflow: hidden;
  }
  .moreBtn {
    cursor: pointer;
    position: absolute;
    bottom: 0;
    right: 0;
    background: #ffffff;
    padding-left: 10px;
    color: #004693;
    font-size: 0.7rem;
  }
}
.upper-part {
  .abstract {
    line-height: 21px;
    overflow: hidden;
    zoom: 1;
    .content-deta {
      display: inline-block;
      width: 100%;
      line-height: 21px;
    }
  }
  .unfold {
    float: right;
    cursor: pointer;
    p {
      margin-top: 0px;
      margin-bottom: 0px;
      font-size: 0.7rem;
      color: #004693;
    }
  }
}
// 收起
.pack-up {
  position: relative;
  // margin: 15px 21px 0 21px;
  .abstract {
    // 最大高度设为3倍的行间距
    max-height: 63px;
    line-height: 21px;
    word-wrap: break-word;
    /*强制打散字符*/
    word-break: break-all;
    background: #ffffff;
    /*同背景色*/
    // color: #ffffff;
    overflow: hidden;
    .content-deta {
      display: inline-block;
      width: 100%;
      // text-indent: 1.5rem;
    }
    .abstract:after,
        // 这是展开前实际显示的内容
        .abstract:before {
      content: attr(title);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #9b9ba3;
    }
    // 把最后最后一行自身的上面三行遮住
    .abstract:before {
      display: block;
      overflow: hidden;
      z-index: 1;
      max-height: 42px;
      background: #ffffff;
    }
    .abstract:after {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      height: 60px;
      /*截取行数*/
      -webkit-line-clamp: 3;
      text-overflow: ellipsis;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      /*行首缩进字符数，值为[(截取行数-1)*尾部留空字符数]*/
      text-indent: -12px;
      /*尾部留空字符数*/
      padding-right: 3em;
    }
    .unfold {
      padding-left: 0.5rem;
      z-index: 11;
      outline: 0;
      position: absolute;
      right: 0;
      bottom: 0;
      background: #fff;
      cursor: pointer;
      p {
        margin: 0;
        font-size: 0.7rem;
        color: #004693;
      }
    }
  }
}
