.globalSettings {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #f4f8fa;
  overflow: auto;
  position: relative;
  .pageTitle {
    width: 100%;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-weight: 600;
    // background: pink;
    p {
      display: flex;
      margin-top: 1rem;
      .anticon,
      .anticon-setting {
        margin-right: 0.5rem;
        color: #999;
        font-size: 1.5rem;
      }
    }
  }
  .settings {
    width: 98%;
    margin-bottom: 1rem;
    height: 12rem;
    // overflow-x: scroll;
    // background: pink;
    .ant-row {
      width: 100%;
      height: 100%;
      .ant-card {
        width: 100%;
        height: 100%;
      }
      .ant-card-head {
        height: 2rem;
        .ant-card-head-wrapper {
          height: 100%;
        }
      }
      .ant-card-body {
        padding: 0.6rem 0.6rem 0 0.6rem;
      }
    }

    .table-wrapper {
      width: 100%;
      height: 8rem;
      overflow: auto;
      table {
        width: 24rem;
        text-align: center;
        table-layout: fixed !important;
        .ant-table-thead tr th.table-head {
          padding: 0;
          background: #fff;
          text-align: center;
          height: 1.5rem !important;
        }
        .ant-table-thead tr th.table-appName {
          width: 4rem !important;
        }
        .ant-table-thead tr th.table-secret {
          width: 6rem !important;
        }
        .ant-table-thead tr th.table-appid {
          width: 6rem !important;
        }
        .ant-table-tbody {
          font-size: 0.5rem;
        }
      }
    }
    // .table-wrapper::-webkit-scrollbar{
    //   display: none;
    // }
  }

  .modalTableView {
    width: 98%;
    height: 51%;
    background: #fff;
    .tableTitle{
      line-height: 2.5rem;
      padding-left: 1rem;
    }
  }
}
// ::-webkit-scrollbar{
//   display:none;
// }
