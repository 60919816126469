.viewImgBox {
  z-index: 1000;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0,0,0,0.3);
  display: flex;
  align-items: center;
  justify-content: center;

  .viewImgContentBox {
    width: 60%;
    height: 80%;
    max-width: 90vw;
    min-width: 25rem;
    max-height: 80vh;
    min-height: 25rem;
    background: #fff;
    border-radius: 0.4rem;
    position: relative;
    padding: 2rem 1rem;
    overflow: hidden;

    .clickBtn  {
      position: absolute;
      z-index: 2;
      width: 2.5rem;
      height: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(0,0,0,0.3);
      cursor: pointer;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 50%;

      .iconfont {
        font-size: 1.2rem;
        color: #fff;
      }

      &.previousBtn {
        left: 1rem;

        .iconfont {
          transform: rotate(180deg);
        }
      }

      &.nextBtn {
        right: 1rem;
      }
    }

    .closeBtn {
      position: absolute;
      z-index: 2;
      right: 0.5rem;
      top: 0.5rem;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      cursor: pointer;
      background: #eaeaea;
      display: flex;
      align-items: center;
      justify-content: center;

      .iconfont {
        font-size: 0.7rem;
        color: #727272;
      }
    }

    .viewImgCarouselBox {
      width: 100%;
      height: 100%;
      overflow: hidden;

      .ant-carousel {
        height: 100%;

        .slick-slider {
          height: 100%;

          .slick-list {
            height: 100%;

            .slick-track {
              height: 100%;

              .slick-slide  {
                height: 100%;

                > div {
                  height: 100%;

                  .viewImgItemBox {
                    height: 100%;
                    display: flex!important;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;

                    img {
                      display: block;
                      max-width: 100%;
                      object-fit: contain;
                      max-height: 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }


    }
  }
}
