.bannerManageFrame {
  display: flex;
  flex-direction: column;
  height: 100%;

  > div {
    flex-shrink: 0;
  }

  .headBox {
    display: flex;
    height: 2.6rem;
    align-items: center;
    justify-content: space-between;
    background: #f0f2f5;

    .zhanwei {
      width: 1.5rem;
    }

    .titleText {
      font-size: 1.2rem;
      color: #333;
    }

    .iconfont {
      position: relative!important;
      top: 0;
    }
  }

  .contentBox {
    flex: 1;
    height: 100%;
    background: #fff;
    border-radius: 0.4rem;
    overflow: hidden;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    user-select: none;

    .operateHeadBox {
      flex-shrink: 0;
      padding: 1rem 0 0.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .leftAddBox {
        flex-shrink: 0;
        display: flex;
        align-items: flex-end;
      }
      .addBannerBtn {
        padding: 0.2rem 0.5rem;
        border-radius: 0.2rem;
        background: #1A9DF9;
        font-size: 0.7rem;
        color: #fff;
        cursor: pointer;
      }
    }

    .tableBox {
      flex: 1;
      overflow: hidden;
      .addressBox {
        //display: flex;
        //align-items: center;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        padding-right: 1rem;
        position: relative;

        .copyBtn {
          position: absolute;
          font-size: 0.7rem;
          color: #36A3E5;
          right: 0;
          top: 0;
          cursor: pointer;
        }
      }
      .accountTypeBox {
        position: relative;
        width: 100%;
        text-align: center;

        .iconfont {
          position: absolute!important;
          right: -0.5rem;
          top: -0.5rem;
          color: #1A9DF9;
        }
      }

      .accountType {
        .ant-table-column-title {
          white-space: nowrap;
        }
      }

      .systemListBox {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: center;
        align-content: flex-start;

        .systemItem {
          display: block;
          border-radius: 0.2rem;
          background: #eaeaea;
          padding: 0.2rem 0.5rem;
          margin: 0.25rem;
          cursor: pointer;
          font-size: 0.6rem;
        }
      }

      .userStatusSwitchBox {
        position: relative;

        &:after {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          cursor: pointer;
        }
      }

      .bannerImgBox {
        height: 2rem;
        width: 7.5rem;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        overflow: hidden;
        background: #F7FAFB;
        cursor: pointer;

        img, video {
          display: block;
          width: auto;
          height: 100%;
          margin: 0 auto;
        }
      }

      .operateBox {
        display: flex;
        align-items: center;
        justify-content: center;

        .operateBtn {
          color: #7B8B96;
          cursor: pointer;

          .iconfont {
            font-size: 1rem;
          }

          +.operateBtn {
            margin-left: 1rem;
          }

          &:hover {
            color: #1A9DF9;
          }
        }
      }
    }
  }
}

.addBannerModalBox {
  .addBannerModalContentBox {
    padding: 0 0 0 0.5rem;
    max-height: 60vh;
    overflow: auto;

    .formItem {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      position: relative;

      .labelText {
        flex-shrink: 0;
        margin-right: 1rem;
        width: 3.5rem;
        text-align-last: justify;
        font-size: 0.7rem;
        color: #333;
        line-height: 1.6rem;
      }

      .formItemContent {
        flex: 1;
        overflow: hidden;
        line-height: 1rem;
        position: relative;
        margin-left: -0.5rem;
        padding-left: 0.5rem;

        .ant-input {
          border: 0.05rem solid #e0e0e0 !important;
          padding: 0.2rem 0.5rem!important;
          border-radius: 0.2rem;
        }

        .ant-select-selector {
          border-radius: 0.2rem;
          box-shadow: none;
          border-color: #e0e0e0;
        }

        .radioGroupBox {
          display: flex;
          align-items: center;
          height: 1.6rem;


          .radioItem {
            display: flex;
            align-items: center;
            cursor: pointer;

            .radioCircle {
              display: block;
              width: 0.5rem;
              height: 0.5rem;
              border: 0.05rem solid #c9c8c8;
              margin-right: 0.3rem;
              border-radius: 50%;
              position: relative;
            }

            .radioText {
              font-size: 0.7rem;
              color: #333;
              line-height: 1rem;
            }

            +.radioItem {
              margin-left: 1rem;
            }

            &.choiceStatus {
              .radioCircle {
                box-shadow: 0 0 0.3rem 0.05rem rgba(255, 77, 79, 0.76);
                background: #ff4d4f;
                border: none;

                &:before {
                  content: "";
                  position: absolute;
                  width: 0.2rem;
                  height: 0.2rem;
                  top: 0.15rem;
                  left: 0.15rem;
                  background: #fff;
                  border-radius: 50%;
                }
              }
            }
          }
        }

        .sortNumberBox {
          display: flex;
          align-content: center;

          .ant-input-number {
            border-color: #e0e0e0 !important;
            box-shadow: none;
            border-radius: 0.2rem;

            input {
              padding: 0.2rem 0.5rem;
              height: unset;
            }
          }

          .tipText {
            font-size: 0.6rem;
            color: #9f9f9f;
            line-height: 1.6rem;
            margin-left: 0.5rem;
          }
        }

        .bannerBgBox {
          //padding-left: 0.5rem;

          .bannerThumbImgBox {
            position: relative;

            .bannerThumbImg {
              background: #f8f8f8;
              border: 0.05rem solid #f8f8f8;
              border-radius: 0.4rem;
              overflow: hidden;
              cursor: pointer;
              width: 20rem;
              height: calc(20rem * 11 / 64);
              display: flex;
              justify-content: center;

              > img {
                width: auto;
                height: 100%;
              }
            }

            .uploadBtn {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 5rem;
              height: 5rem;
              cursor: pointer;
              border: 0.05rem solid #d7d7d7;
              border-radius: 0.4rem;

              .iconfont {
                font-size: 1.5rem;
                color: #babcbd;
              }
            }

            #fileInput {
              display: block;
              width: 0;
              height: 0;
              position: absolute;
              z-index: 0;
              left: 0;
              top: 0;
            }
          }

          .tipText {
            display: block;
            line-height: 1rem;
            font-size: 0.6rem;
            color: #9f9f9f;
            margin-top: 0.5rem;
          }
        }
      }

      .errorTipText {
        position: absolute;
        top: 100%;
        left: 4.5rem;
        right: 0;
        font-size: 0.6rem;
        color: red;
      }

      +.formItem {
        margin-top: 1.2rem;
      }

      &.isRequired {
        &:before {
          content: "*";
          color: red;
          position: absolute;
          left: -0.5rem;
          top: 0.75rem;
          line-height: 0;
        }
      }
    }
  }
}
