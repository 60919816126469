.systemMessageFrame{
    height: 100%;
    padding: 1rem;
    .operation{
        height: 2rem;
        line-height: 2rem;
        font-size: 0.9rem;
    }
    .listItem{
        margin-bottom: 1rem;
        .date{
            font-size: 1rem;
            margin-bottom: 0.3rem;
        }
    }
    .content{
        position: absolute;
        left: 1rem;
        right: 1rem;
        bottom: 4rem;
        top: 4rem;
        overflow: auto;
    }
    .footer{
        position: absolute;
        bottom: 1rem;
        right: 1rem;
    }
}
