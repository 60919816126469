.searchAssistantFrame{
    display: flex;
    height: 100%;
    flex-direction: column;
    overflow: hidden;
    .header{
        background: #fff;
        border-radius: 8px;
        margin-bottom: 10px;
        .topTitle{
            position: relative;
            line-height: 50px;
            padding: 0 20px;
        }
    }
    .readContent{
        flex: 1;
        display: flex;
        overflow: hidden;
        .left{
            width: 300px;
            position: relative;
            padding: 0.5rem;
            margin-right: 10px;
            background: #fff;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            .addTopicBtnBox{
                text-align: center;
                padding-top: 10px;
                .addTopicBtn{
                    background: #60B4F6;
                    border: 0;
                    margin-right: 15px;
                    border-radius: 8px;
                    width: 80%;
                    // position: absolute;
                    // left: 50%;
                    // transform: translateX(-50%);
                    // bottom: 10px;
                }
            }
        }

        .right{
            width: 75%;
            flex: 1;
            background: #fff;
            border-radius: 8px;
            position: relative;
            padding: 0.5rem;
            .aiSearchFrame{
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
                .centerText{
                    display: flex;
                    margin-left: 50%;
                    transform: translateX(-50%);
                    width: 370px;
                    .fileIcon{
                        .anticon-file-search{
                            color: #F20707;
                            font-size: 65px;
                        }
                        
                        margin-right: 10px;
                    }
                    .describe{
                        font-size: 0.7rem;
                        color: #1D2129;
                    }
                }
                .aiSearchBox{
                    width: 700px;
                    height: 118px;
                    border-radius: 0.5rem;
                    opacity: 1;
                    padding: 1rem;
                    background: #F7F8FA;

                    box-sizing: border-box;
                    border: 1px solid #979797;
                    .ant-input{
                        border: 0;
                        background: transparent;
                        border-radius: 0.5rem;
                        padding: 5px 0;
                    }
                }
                .footerBtn{
                    margin-top: 1rem;
                    display: flex;
                    .searchType{
                        margin-top: 10px;
                        flex: 1;
                        font-size: 0.6rem;
                        cursor: pointer;
                        .anticon-down{
                            margin-left: 8px;
                        }
                        .sign{
                            display: inline-block;
                            width: 8px;
                            height: 8px;
                            opacity: 1;
                            border-radius: 50%;
                            background: #409EFF;
                            box-shadow: #409eff 0px 0px 6px 2px;
                            margin-right: 0.5rem;
                        }
                    }
                    .ant-btn{
                        border-radius: 16px;
                        border: 0;
                    }
                    .ant-btn-primary{
                        background: #60B4F6;
                    }
                    .ant-btn-default{
                        background: #E4EDF2;
                        color: #3D3D3D;
                    }
                }
                .recQuestions{
                    margin-top: 0.5rem;
                    .recQuestionsItem{
                        margin-right: 0.5rem;
                        cursor: pointer;
                        border: 1px solid #E9EDF5;
                        font-size: 0.6rem;
                        display: inline-block;
                        padding: 2px 10px;
                        border-radius: 15px;
                    }
                }
            }
        }
    }
}