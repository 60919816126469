.readAssistantFrame{
    display: flex;
    height: 100%;
    flex-direction: column;
    overflow: hidden;
    .header{
        background: #fff;
        border-radius: 8px;
        margin-bottom: 10px;
        .topTitle{
            position: relative;
            line-height: 50px;
            padding: 0 20px;
        }
    }
    .readContent{
        flex: 1;
        display: flex;
        overflow: hidden;
        .left{
            width: 300px;
            position: relative;
            padding: 0.5rem;
            margin-right: 10px;
            background: #fff;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            .addTopicBtnBox{
                text-align: center;
                padding-top: 10px;
                .addTopicBtn{
                    background: #60B4F6;
                    border: 0;
                    margin-right: 15px;
                    border-radius: 8px;
                    width: 80%;
                    // position: absolute;
                    // left: 50%;
                    // transform: translateX(-50%);
                    // bottom: 10px;
                }
            }
        }

        .right{
            width: 75%;
            flex: 1;
            background: #fff;
            border-radius: 8px;
            position: relative;
        }
    }
}