.historyTopic{
  font-size: 0.6rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  .titleBox{
    display: flex;
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
    .titleText{
      display: inline-block;
      line-height: 1.5rem;
      font-size: 0.7rem;
    }
    .searchBox{
      flex: 1;
      position: relative;
      margin-left: 10px;
      .ant-input-sm{
        margin-top: 4px;
      }
      .anticon-search{
        position: absolute;
        cursor: pointer;
        top: 50%;
        transform: translateY(-50%);
        right: 0px;
      }
    }
    
  }
  .content{
    flex: 1;
    overflow-y: auto;
    .item{
        background: #F7F8FA;
        padding: 8px;
        margin: 0 5px 15px 5px;
        cursor: pointer;
        .anticon-delete,.anticon-edit{
            float: right;
            cursor: pointer;
            margin-top: 4px;
            font-size: 12px;
            margin-right: 8px;
        }
    }
    .selectedItem{
      background: #F7F8FA;
      box-shadow: 0 3px 4px #429fff;
    }
  }
}