.selfDesignModal {
  .ant-modal {
    min-width: 18rem !important;
    padding-bottom: 0;
    border-radius: 0.4rem;
    overflow: hidden;

    .ant-modal-content {
      padding: 0;

      .titleBox {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 0.75rem;

        .iconfont {
          margin-right: 0.5rem;
          font-size:  0.9rem;
          //top: 0.05rem;
        }

        > span {
          font-size: 0.8rem;
          line-height: 1rem;
          color: #333;
        }
      }

      .ant-modal-close {
        right: 1rem;
        top: 0.8rem;

        .ant-modal-close-x {
          width: 1.3rem;
          height: 1.3rem;
          background: #F0F5F8;
          border-radius: 50%;
          line-height: 1;
          display: flex;
          justify-content: center;
          align-items: center;

          .iconfont {
            font-size: 0.6rem;
          }
        }
      }

      .ant-modal-header {
        padding: 1rem;
      }

      .ant-modal-body {
        padding: 1rem;

        .selfDesignContentBox {
          user-select: none;

          .titleBox {
            margin-bottom: 0;
            padding: 1rem 0 0 0;
            justify-content: flex-start;

            > span {
              font-weight: bold;
              font-size: 0.8rem;
            }
          }

          .contentBox {
            //background: #F0F5F8;
            border-radius: 0.4rem;
            padding: 1rem 0;

            .ant-input {
              border: none;
              box-shadow: none;
              background-color: rgba(0,0,0,0);
              resize: none;
              font-size: 0.7rem;
              padding: 0;
            }
          }

          .operateBtnBox {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .operateBtn {
              padding: 0.25rem 0.8rem;
              background: #E7E7E7;
              font-size: 0.7rem;
              color: rgba(0, 0, 0, 0.9);
              border: none;
              border-radius: 0.2rem;
              cursor: pointer;

              &.confirmBtn {
                background: #0052D9;
                //box-shadow: 0 0.3rem 0.5rem 0.05rem rgba(96,180,246,0.36);
                color: #fff;
              }

              //&:only-child {
              //  flex: 0 0 100%;
              //}

              +.operateBtn {
                margin-left: 1rem;
              }
            }
          }
        }

        .ant-modal-confirm-btns {
          margin-top: 1rem;
        }
      }

      .ant-modal-footer, .ant-modal-body .ant-modal-confirm-btns {
        //margin-top: 1rem;
        text-align: center;
        display: flex;
        flex-flow: row-reverse;
        justify-content: flex-end;
        align-items: center;

        .ant-btn {
          padding: 0.25rem 0.8rem;
          background: #E7E7E7;
          font-size: 0.7rem;
          color: rgba(0, 0, 0, 0.9);
          border: none;
          border-radius: 0.2rem;

          //&:only-child {
          //  flex: 0 0 50% !important;
          //}

          +.ant-btn {
            margin-right: 1rem;
            margin-left: 0;
            background: #0052D9;
            //box-shadow: 0 0.3rem 0.5rem 0.05rem rgba(96,180,246,0.36);
            color: #fff;
          }
        }
      }

      .ant-modal-footer {
        padding: 1rem;
      }
    }
  }
}
