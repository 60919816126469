.detailsData{
    .detailsItem{
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;
        border-bottom: 1px solid #e2e2e2;
    }

    .detailsItem:first-child{
        padding-top: 0;
    }
    .detailsTitle{
        font-size: 0.8rem;
        color: #333333;
        line-height: 1.5rem;
        span{
            padding-left: 0.3rem
        }
    }
    .detailsContent{
        font-size: 0.65rem;
        color: #9f9f9f;
        line-height: 1.5rem;
        padding-left: 22px;

        span{
            margin-right: 0.5rem;
        }
    }
    .userNameList{
        line-height: 1;

        span {
            line-height: 1.5rem;
            display: inline-block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .name{
            width: 240px;
        }
        // .department{
        //     width: 100px;
        // }
        // .job{
        //     width: 100px;
        // }
    }
    
    .shareRangeBox {
        overflow: hidden;
        > span {
            display: block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .recommendBox{
        line-height: 0.8rem;
        margin: 0.3rem 0;
        .ant-switch{
            float: left;
            margin-right: 0.2rem;
        }
    }
}