.quickEntranceFrame {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 1rem;

  .moduleName {
    flex-shrink: 0;
    display: block;
    font-size: 0.9rem;
    color: #333;
    line-height: 1.2rem;
    margin-bottom: 0.5rem;
  }

  .entranceListBox {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .entranceItemBox {
      display: flex;
      width: 8.9rem;
      height: 8.9rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 0.4rem;
      cursor: pointer;

      .iconfont {
        font-size: 1.8rem;
        color: #457B9D;
      }

      .titleText {
        display: block;
        margin: 0.5rem 0;
        font-size: 0.9rem;
        color: #333;
        line-height: 1.2rem;
      }

      .descText {
        display: block;
        line-height: 0.8rem;
        font-size: 0.6rem;
        color: #999;
      }

      &:hover {
        background: #60B4F6;
        box-shadow: 0.2rem 0.3rem 0.3rem 0.05rem rgba(15,157,229,0.33);

        .iconfont, .titleText, .descText {
          color: #fff;
        }
      }
    }
  }
}
