.modelManageFrame {
  background: none !important;
  display: flex;
  flex-flow: column;
  height: 100%;
  overflow: hidden;

  .topBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    .returnBox{
      position: relative;
      height: 100%;
    }
  }
  
  .contentBox {
    display: flex;
    justify-content: space-between;
    flex: 1;
    height: 100%;
    overflow: hidden;

    .contentLeftBox, .contentRightBox {
      height: 100%;
      border-radius: 0.2rem;
    }
    .contentLeftBox {
      margin-right: 0.9rem;
      width: 20rem;
      flex-shrink: 0;
      display: flex;
      flex-flow: column;
      overflow: hidden;

      .modelOperateBox, .sortTreeContent {
        background-color: #fff;
        border-radius: 0.2rem;
        padding: 0 0.5rem;
      }
      .modelOperateBox {
        flex-shrink: 0;
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 3rem;

        .modelOperateItem {
          line-height: 1;
          margin: 0 0.5rem;
          color: #ddd;
          cursor: not-allowed;

          &.activeOperateItem {
            cursor: pointer;
            color: #60B4F6;
          }

          .iconfont {
            font-size: 1.2rem;
          }
        }
      }

      .sortTreeContent {
        flex: 1;
        height: 100%;
        margin-top: 1rem;
        padding: 0.5rem 0 0.5rem 0.5rem;
        overflow: hidden;

        .sortTreeBox {
          height: 100%;
          overflow-y: auto;
          overflow-x: hidden;
          padding-right: 0.5rem;

          .treeRoot {
            overflow: hidden;

            .treeRoot {
              overflow: visible;

              padding-left: 1rem;
              .treeSortBox {
                &:before {
                  position: absolute;
                  content: '';
                  width: 200%;
                  height: 100%;
                  left: -50%;
                  top: 0;
                  background: #60B4F6;
                  z-index: 0;
                }
                &:hover {
                  &:before {
                    background: #60B4F6;
                  }
                }

                &:after {
                  //display: none;
                  top: 0;
                  bottom: unset;
                  background: #b3c7f9;
                }
              }
            }

            .treeContent {
              .treeDataBox {
                margin: 0;
                list-style: none;

                .treeDataItem {
                  display: flex;
                  align-items: center;
                  height: 2rem;
                  position: relative;
                  cursor: pointer;
                  padding: 0 1rem 0 1.5rem;

                  &:hover {
                    &:before {
                      position: absolute;
                      content: '';
                      width: 200%;
                      height: 100%;
                      left: -50%;
                      top: 0;
                      background: #eee;
                      z-index: 0;
                    }
                  }

                  &:after {
                    position: absolute;
                    content: '';
                    width: 200%;
                    height: 0.05rem;
                    left: -50%;
                    bottom: 0;
                    background: #eee;
                    z-index: 0;
                  }

                  .treeDataItemInfo {
                    display: flex;
                    align-items: center;
                    position: relative;
                    z-index: 1;
                    overflow: hidden;
                    padding-right: 1rem;
                    user-select: none;

                    > span {
                      font-size: 0.7rem;
                      color: #333;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    }
                  }

                  .iconfont {
                    position: absolute !important;
                    right: 0.5rem;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 1rem;
                    color: #60B4F6;
                  }
                }
              }
            }

            .treeSortBox {
              background-color: #60B4F6;
              height: 2rem;
              cursor: pointer;
              padding-right: 1.6rem;
              position: relative;

              .treeSortLeft {
                .expandIcon {
                  margin-right: .25rem;
                  color: #fff;
                }

                .sortName {
                  color: #fff;
                  cursor: pointer;
                }
              }

              .iconfont.icon-dui {
                position: absolute !important;
                right: 0.5rem;
                top: 50%;
                transform: translateY(-50%);
                font-size: 1rem;
                color: #fff;
              }
            }
          }

          .noneDataBox {
            height: 100%;
            font-size: 0.8rem;

            .iconfont {
              font-size: 1.5rem;
              margin-right: 1rem;
            }
          }
        }
      }
    }

    .contentRightBox {
      width: 100%;
      flex: 1;
      overflow: hidden;

      .noSelectTip {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        height: 100%;
        cursor: default;
      }
    }
  }
}