.noteBoxFrame{
    .noteTitle{
        display: flex;
        color: #333333;
        font-size: 0.7rem;
        .icon-dangdang{
            // margin-top: 4px;
            float: left;
            margin-right: 10px;
            font-size: 22px;
        }
    }
    .content{
        background: #ffffff;
        border-radius: 4px;
        padding: 0.5rem;
        margin-top: 0.5rem;
        cursor: pointer;
        .moreBtn{
            font-size: 0.7rem;
            color: #004693;
            text-align: right;
            cursor: pointer;
        }
        .moreBtnOpen{
            padding-left: 0.5rem;
            z-index: 11;
            outline: 0;
            position: absolute;
            right: 0;
            bottom: 0;
            background: #fff;
        }
    }
    .noteTitleOperation{
        float: right;
        font-size: 0.6rem;
        .iconfont{
            cursor: pointer;
        }
    }
    .publicNoteContent{
        display: flex;
        .headSculpture{
            // float: left;
            // width: 1.2rem;
            span{
                display: inline-block;
                width: 1.2rem;
                height: 1.2rem;
                border-radius: 50%;
                text-align: center;
                line-height: 1rem;
                background: #1890ff;
                color: #ffffff;
                margin-right: 10px;
            }
            img{
                display: inline-block;
                width: 1.2rem;
                height: 1.2rem;
                border-radius: 50%;
                margin-right: 10px;
            }
        }
        .noteContentRight{
            flex: 1;
            >div{
                line-height: 1.3rem;
            }
            .noteTime{
                font-size: 0.6rem;
                color: #999999;
                .ant-typography{
                    float: right;
                    cursor: pointer;
                }
            }
            .anticon-like{
                margin-right: 5px;
            }
            .learnFromIt{
                float: right;
                margin-left: 10px;
                cursor: pointer;
                .iconfont {
                    font-size: 12px;
                    margin-right: 5px;
                    margin-top: 8px;
                    float: left;
                }
            }
        }
    }
    .noteText{
        width: 100%;
        font-size: 0.7rem;
        line-height: 1rem;
        word-break: break-all;
        cursor: pointer;
        position: relative;
    }
    .originalDetails{
        position: relative;
        font-size: 0.6rem;
        line-height: 0.9rem;
        margin-top: 0.5rem;
        color: #9f9f9f;
    }
    .originalEllipsis{
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        white-space: pre-wrap;
        word-wrap: break-word;
        word-break: break-all;
    }
    .textEllipsis{
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        white-space: pre-wrap;
        word-wrap: break-word;
        word-break: break-all;
    }
    .defalutRotate{
        // position: absolute !important;
        // right: 10px;
        // top: 50%;
        margin-left: 50%;
        transform: translateX(-50%);
        cursor: pointer;
    }

    .notePagination{
        padding-top: 0.5rem;
        text-align: right;
        margin-bottom: 1rem;
    }

    .ant-switch{
        background: #B8CDD9;
        margin-right: 5px;
        float: left;
        margin-top: 2px;
    }
    .ant-switch-handle::before{
        background: #ffffff;
    }
    .ant-switch-checked{
        background: #B8CDD9;
        .ant-switch-handle::before{
            background: #FF3320;
        }
    }
}