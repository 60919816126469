.userFrame{
    position: relative;
    .ant-tabs-nav{
        border-bottom: 10px solid #f0f2f5;
        padding: 0 1rem;
        background: white
    }
    .ant-tabs-tab-btn{
        font-size: 0.9rem;
    }
    
}
