// 树节点
.sortTreeFrame {
  overflow-x: hidden;
  overflow-y: auto;
  height: 12rem;

  .treeRoot {
    + .treeRoot {
      margin-top: 0.15rem;
    }
    .treeRoot {
      +.treeRoot {
        margin-top: 0;
      }
      padding-left: 1.53rem;
      .treeSortBox {
        background: #fff;
        position: relative;

        &:hover {
          &:before {
            position: absolute;
            content: '';
            width: 200%;
            height: 100%;
            left: -50%;
            top: 0;
            background: #eee;
            z-index: 0;
          }
        }

        &:after {
          position: absolute;
          content: '';
          width: 200%;
          height: 0.05rem;
          left: -50%;
          bottom: 0;
          background: #eee;
          z-index: 0;
        }
      }
    }
    .treeSortBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 1.5rem;
      background-color: rgb(169,206,240);
      padding: 0 1rem 0 0.5rem;

      .treeSortLeft {
        display: inline-flex;
        align-items: center;
        position: relative;
        z-index: 1;
        overflow: hidden;
        //text-overflow: ellipsis;
        //white-space: nowrap;
        flex: 1 1 auto;
        max-width: 100%;

        .expandIcon {
          margin-right: .75rem;
          transition: all 0.3s;
          line-height: 1;
          cursor: pointer;
        }


        .sortName {
          font-size: 0.8rem;
          color: #333;
          line-height: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: default;
        }

        .ant-checkbox-wrapper {
          line-height: 1;
          margin-left: 0.5rem;
        }

        &.expand {
          .expandIcon {
            transform: rotate(90deg);
            transition: all 0.3s;
          }
        }
      }
    }
  }
}