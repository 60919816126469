.knowledgeTypeFrame{
    position: relative;
    padding-right: 140px;
    min-height: 50px;
    .ant-tabs{
        .ant-tabs-nav{
            margin: 0
        }
    }
    
    .ant-tabs-ink-bar{
        background: #fff;
    }
    .ant-tabs-tab{
        font-size: 0.8rem
    }
    .ant-tabs-top > .ant-tabs-nav::before{
        border: 0
    }
    .searchIcon{
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 1.4rem;
        color: #1890ff;
        cursor: pointer;
        height: 50px;
        line-height: 50px;
        .AiSearchBtn {
            display: flex;
            align-items: center;
            float: left;
            margin-right: 0.5rem;
            .iconfont {
                margin-right: 0.3rem;
                font-size: 0.9rem;
                color: #1890ff;
            }

            span {
                font-size: 0.7rem;
                color: #4E5969;
            }
        }
    }

}
.searchModal{
    .ant-modal-content{
        border-radius: 8px;
    }
    .ant-modal-header{
        border-radius: 8px;
        border: 0;
        padding: 20px 24px;
    }
    .searchBar{
        width: 100%;
        height: 3.2rem;
        background: #F1F5F9;
        border-radius: 8px;
        position: relative;
        .verticalDivider{
            float: left;
            height: 2rem;
            background: #BBCFDB;
            margin-top: 0.6rem;
        }
        .ant-select{
            float: left;
            margin: 0.6rem 0;
            height: 2rem;
        }
        .ant-select-selector{
            padding: 0;
        }
        .ant-input{
            width: 200px;
            height: 2rem;
            float: left;
            margin: 0.6rem 0;
        }
    }
    .footer{
        margin-top: 1.3rem;
        .ant-space{
            width: 100%;
        }
        .ant-space-item{
            width: 50%;
        }
        .ant-btn-primary{
            height: 2.1rem;
            background: #60B4F6;
            border-radius: 8px;
            border-color: #60B4F6;
            width: 100%;
        }
        .ant-btn-default{
            height: 2.1rem;
            background: #B8C3CB;
            border-radius: 8px;
            color: #fff;
            border-color: #B8C3CB;
            width: 100%;
        }
        
    }
    .ant-modal-body{
        padding: 0 24px 24px;
    }
}