.fileListFrame{
    font-size: 0.7rem;
    padding: 0.5rem;
    .fileHeader{
        display: flex;
        .title{
            flex: 1;
            span{
                display: inline-block;
            }
            .titleIcon{
                font-weight: 600;
            }
            .titleDes{
                font-size: 0.6rem;
            }
        }
        .ant-btn-primary{
            background: #60B4F6;
            border: 0;
            // margin-right: 15px;
        }
        
    }
    .fileSearch{
        margin-top: 1rem;
        display: flex;
        margin-bottom: 1rem;
        line-height: 32px;
        .searchInput{
            flex: 1;
            height: 32px;
            line-height: 32px;
            .inputTitle{
                // font-weight: 600;
            }
            .ant-input-search{
                width: 300px;
            }
        }
        .ant-btn > .anticon + span{
            margin-left: 5px;
        }
        .detaleFile{
            .anticon-delete{
                color: #f66060;
                font-size: 18px;
            }
        }
        .addKnowledgeFile{
            .anticon-file-add{
                color: #60B4F6;
                font-size: 18px;
            }
        }
    }
}