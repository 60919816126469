.knowledgeExplorationFrame {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .backgroundBox {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    background-image: url("../../../../../assets/img/homePage/defaultScheme/knowledgeExploration.png");
    background-position: left center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-color: rgb(241,244,253);

    .entryBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 9.4rem;
      height: 2.25rem;
      border-radius: 0.4rem;
      background: #60B4F6;
      font-size: 0.75rem;
      color: #fff;
      cursor: pointer;
      position: absolute;
      left: 2.4rem;
      bottom: 3.1rem;
      z-index: 2;
    }

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: #000;
      opacity: 0.05;
      transition: opacity 0.1s linear;
    }

    &:hover {
      &:before {
        opacity: 0;
      }
    }
  }
}
