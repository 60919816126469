.published {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  > div {
    width: 100%;
    border-radius: 0.4rem;
    background: #fff;
    overflow: hidden;
  }

  .tabs {
    flex-shrink: 0;
    display: flex;
    justify-content: space-around;
    margin-bottom: 0.5rem;
    box-sizing: border-box;
    height: 2.8rem;
    background: #fff;
    .knowledgeTypeTabFrame {
      .ant-empty {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;

        .ant-empty-image {
          margin: 0;
        }
      }
    }
  }

  .content {
    box-sizing: border-box;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;

    .sort {
      margin: 0 -1rem 1rem;
      padding: 0 1rem;
      height: 2.5rem;
      display: flex;
      font-size: 0.8rem;
      align-items: center;
      flex-shrink: 0;
      box-shadow: 0 0.05rem 0 0 #ececec;

      p {
        display: flex;
        align-items: center;
        margin: 0;

        .sortIcon {
          font-size: 0.7rem;
          margin-left: 0.2rem;
          display: flex;
          flex-direction: column;
          span:nth-child(1) {
            margin-top: 0.15rem;
            margin-bottom: -0.15rem;
          }
          span:nth-child(2) {
            margin-top: -0.15rem;
          }
        }

        + p {
          margin-left: 2rem;
        }
      }
    }

    .knowledgeList{
      overflow-x: hidden;
      overflow-y: auto;
      flex: 1;
      margin-right: -0.3rem;
      padding-right: 0.3rem;
      
      .ant-spin-nested-loading {
        // height: 100%;
        //overflow: hidden;
        .ant-empty {
          margin: 0 auto;
          padding: 2rem;
        }
      }
    }

    .pager{
      height: 2.5rem;
    }
    // .pager {
    //   flex-shrink: 0;
    //   display: flex;
    //   align-items: center;
    //   justify-content: flex-end;
    //   height: 2.5rem;
    //   margin: 1rem -1rem 0;
    //   padding: 0 1rem;
    //   box-shadow: 0 -0.05rem 0 0 #ececec;

    //   .paginationFrame {
    //     margin: 0;
    //     padding: 0;

    //     .ant-pagination {
    //       display: flex;
    //       align-items: center;
    //       justify-content: flex-end;

    //       > li {
    //         background: #eee !important;
    //         border-radius: 0.2rem;
    //         border: none;
    //         min-width: unset;
    //         width: 1.2rem;
    //         height: 1.2rem;
    //         line-height: 1.2rem;

    //         > a, .anticon {
    //           font-size: 0.7rem;
    //           color: #333;
    //           vertical-align: unset!important;
    //         }

    //         > button {
    //           border: none!important;
    //           background: none!important;
    //           display: flex;
    //           align-items: center;
    //           justify-content: center;
    //         }

    //         &.ant-pagination-disabled {
    //           .anticon {
    //             color: #b7b6b6;
    //           }
    //         }

    //         &.ant-pagination-item-active {
    //           background: #1A9DF9 !important;
    //           box-shadow: 0 0.05rem 0.2rem 0.05rem rgba(26, 157, 249, 0.73);
    //           font-weight: normal;

    //           > a {
    //             color: #fff;
    //           }
    //         }

    //         &.ant-pagination-total-text, &.ant-pagination-options, &.ant-pagination-simple-pager {
    //           background: none!important;
    //           width: unset;
    //         }

    //         &.ant-pagination-options {
    //           .ant-select {
    //             height: 1.2rem;
    //             line-height: 1rem;

    //             .ant-select-selector {
    //               height: 100%!important;
    //               border-color: #d9d9d9!important;
    //               box-shadow: none!important;
    //               border-radius: 0.2rem;

    //               .ant-select-selection-search {
    //                 height: 100%;

    //                 input {
    //                   height: 100%;
    //                   line-height: 0;
    //                 }
    //               }

    //               .ant-select-selection-item {
    //                 height: 100%;
    //                 line-height: 1.1rem;
    //               }
    //             }
    //           }

    //           .ant-pagination-options-quick-jumper {
    //             height: 100%;
    //             line-height: 1.1rem;

    //             > input {
    //               height: 100%;
    //               line-height: 1.1rem;
    //               border-color: #d9d9d9!important;
    //               box-shadow: none!important;
    //               border-radius: 0.2rem;
    //               text-align: center;
    //               font-size: 0.6rem;
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
  }

}
