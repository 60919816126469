@import "~antd/dist/antd.css";

$deep: '::v-deep';

* {
  padding: 0;
  margin: 0;
  font-style: normal;
  box-sizing: border-box;
}

html, body {
  font-size: 20px;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: unset!important;
}

#root {
  display: block;
  width: 100%;
  // min-width: 1000px;
  // max-width: 1440px;
  height: 100vh;
  min-height: 600px;
  overflow: hidden;
  position: relative;
  // left: 50%;
  // transform: translateX(-50%);

  // 因为首页使用了zoom缩放，影响了dropDowm的定位问题
  .dropDownClass{
    left: auto !important;
    right: 30px !important;
    top: 50px !important;
  }
  .themePopover{
    left: auto !important;
    right: 165px !important;
    top: 50px !important;
  }
}

.loadingModal{
  .ant-modal-confirm-btns{
    display: none;
  }
}

#visibilityBox{
  width: 314px;
  word-break: break-all;
  visibility: hidden;
  line-height: 1rem;
  font-size: 0.7rem;
  position: fixed;
  right: -314px;
}

#visibilityBoxOriginal{
  width: 314px;
  word-break: break-all;
  visibility: hidden;
  line-height: 0.9rem;
  font-size: 0.6rem;
  position: fixed;
  right: -314px;
}

.ant-empty {
  > div, > p {
    text-align: center !important;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-layout{
  min-height: 100%;
}
