.contentListFrame{
    display: flex;
    flex-direction: column;
    .sortBox{
        height: 1.7rem;
        border-bottom: 1px solid #f0f0f0;
        line-height: 1.5rem;
        .sort>div{
            font-size: 0.7rem;
            float: left;
            margin-right: 1rem;
            cursor: pointer;
        }
        .timeSort{
            position: relative;
            .anticon-caret-up{
                position: absolute;
                top: 5px;
                right: -20px;
                margin-right: 0;
                font-size: 0.6rem;
            }
            .anticon-caret-down{
                position: absolute;
                top: 13px;
                right: -20px;
                margin-right: 0;
                font-size: 0.6rem;
            }
        }
        .activeKeyClass{
            color: #1890ff;
            text-shadow: 0 0 0.25px currentcolor;
        }
    }


    .conditionItem {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        position: relative;
        background: #F0F5F8;
        border-radius: 0.2rem;
        cursor: pointer;
        float: right;
        margin-left: 1rem;
        .iconBtn {
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 1.3rem;
            width: 1.3rem;
            height: 1.3rem;
            flex-shrink: 0;
            .iconfont {
                font-size: 0.7rem;
                color: #797979;

                &.icon-search {
                    font-size: 0.8rem;
                }
            }
        }

        .ant-picker {
            padding: 0.2rem 0.4rem;
            border-radius: 0.3rem;
        }

        .ant-input-affix-wrapper {
            border-radius: 0.3rem;
            padding: 0.2rem 0.4rem;
        }

        .choiceDateBox {
            width: 10rem;
            box-shadow: none;
            border: none;
            height: 0.7rem;
            padding: 0;
            font-size: 0.7rem;
            margin-right: 0.8rem;
            background: #F0F5F8;

            .ant-picker-active-bar {
                margin-left: 0;
                bottom: -0.15rem;
            }

            .ant-picker-clear {
                right: -0.7rem;
            }

            input {
                text-align: center;
            }
        }

        .searchInputBox {
            box-shadow: none;
            border: none;
            height: 0.7rem;
            padding: 0 0 0 0.5rem;
            font-size: 0.7rem;
            margin-right: 0.2rem;
            background: #F0F5F8;

            input{
                background: #F0F5F8;
            }
        }

        &.selectedAreaOrDepartmentBox {
            align-items: flex-start;
            overflow: hidden;
            flex-flow: row-reverse;
            flex: 0 1 auto;

            .areaOrDepartmentContentBox {
                position: relative;
                height: 100%;
                padding: 0.5rem 4rem 0.5rem 0.5rem;

                .listItem {
                    display: inline-flex;
                    align-items: center;
                    background: #ebebeb;
                    cursor: default;
                    max-width: 100%;
                    position: relative;
                    margin: 0.25rem 0.25rem;
                    line-height: 0;
                    padding: 0.1rem 0.3rem;
                    border-radius: 0.2rem;
                    overflow: hidden;

                    .itemText {
                        display: inline-block;
                        font-size: 0.6rem;
                        color: #333;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        line-height: 1rem;
                    }

                    .clickBtn {
                        display: block;
                        line-height: 0;
                        cursor: pointer;
                        margin-left: 0.3rem;

                        .iconfont {
                            font-size: 0.8rem;
                            color: #333;
                            zoom: 0.7;
                        }
                    }
                }

                .selectedOperateBox {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-shrink: 0;
                    position: absolute;
                    right: 0.2rem;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 3rem;

                    .listItem {
                        background: #1A9DF9;
                        border-radius: 0.2rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        margin: 0;


                        .itemText  {
                            background: none;
                            color: #fff;
                        }

                        .clickBtn {
                            position: relative;
                            top: unset;
                            right: unset;
                            line-height: 0;
                            display: block;
                            margin-left: 0;

                            .iconfont {
                                color: #fff;
                                zoom: 1;

                                &.icon-shanchu1 {
                                    font-size: 0.65rem;
                                    margin-right: 0.2rem;
                                }
                            }
                        }

                        &.deleteBtn {
                            flex: 1 1 30%;
                            background: #ff4d4f;
                        }
                    }
                }

                .areaOrDepartmentListBox {
                    flex: 1;
                    max-height: 7rem;
                    overflow: auto;
                    display: flex;
                    flex-direction: column;
                    flex-flow: row wrap;
                    align-items: center;
                    align-content: flex-start;
                    margin: 0 -0.25rem 0;
                }
            }
        }
    }
    .conditionItem:first-of-type {
        margin-left: 1rem;
    }

    .addCartAll{
        font-size: 0.7rem;
        position: absolute;
        right: 0;
        width: 90px;
        height: 30px;
        line-height: 30px;
        cursor: pointer;
        .AiBtn {
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 1.5rem;
            height: 100%;
            color: #767676;;
            position: relative;
            border-radius: 0.5rem 0 0 0.5rem;
            float: left;
            .icon-waixingren {
                font-size: 0.7rem;
            }
    
            .icon-jiahao {
                font-weight: 600;
                font-size: 1rem;
                zoom: 0.3;
                position: absolute!important;
                bottom: 1.1rem;
                left: 3rem;
            }
        }
    }


    .overseasCaseBox {
        .titleText {
            display: block;
            font-size: 0.8rem;
            line-height: 1rem;
            word-break: break-all;
        }
        .extractBox{
            font-size: 0.7rem;
            color: #898989;
            margin-top: 0.5rem;
        }

        .keyWards{
            font-size: 0.7rem;
            display: flex;
            align-items: flex-start;
            margin-top: 5px;
            color: #898989;
            line-height: 1rem;

            .keyWardsTitle{
                flex-shrink: 0;
                width: 4.2rem;
                display: block;
            }

            .keyWardsList{
                flex: 1;
                line-height: 0;
                overflow: hidden;
                .site-tag-plus{
                    cursor: pointer;
                }
                .ant-tag {
                    line-height: 1rem;
                    margin-bottom: 0.25rem;
                    color: #7b7b7b;
                    border: none;
                    background: #efefef;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    max-width: 100%;
                }
            }
        }
    }
    
}
