.contentListsFrame{
    .sortBox{
        position: absolute;
        right: 20px;
        top: -4px;
        .timeSort{
            position: relative;
            .anticon-caret-up{
                position: absolute;
                top: 2px;
                right: -20px;
                margin-right: 0;
                font-size: 0.6rem;
            }
            .anticon-caret-down{
                position: absolute;
                top: 10px;
                right: -20px;
                margin-right: 0;
                font-size: 0.6rem;
            }
            .ant-tabs-nav{
                margin-bottom: 0;
            }
        }
    }
    
}
