.addFilesFrame{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    .centerText{
        display: flex;
        margin-left: 50%;
        transform: translateX(-50%);
        width: 370px;
        margin-bottom: 50px;
        .fileIcon{
            .anticon-container{
                color: #5E5ADB;
                font-size: 65px;
            }
            
            margin-right: 10px;
        }
        .describe{
            font-size: 0.7rem;
            color: #1D2129;
        }
    }


    .tabsBox{
        text-align: center;
        .themeItem{
            display: inline-block;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.6);
            padding: 10px;
            margin: 0 10px;
            cursor: pointer;
            .themeItemIcon{
                display: inline-block;
                font-size: 16px;
                color: #0052D9;
                margin-left: 10px;
            }
        }
        .themeItemSelete{
            border-bottom: 2px solid #0052D9;
        }
    }

    .tips{
        font-size: 12px;
        color: #767676;
        margin-top: 10px;
        p{
            margin: 0;
        }
        p:not(:first-child){
            text-indent: 2em;
        }
    }
    
    .footerBtn{
        width: 800px;
        height: 141px;
        border-radius: 10px;
        opacity: 1;
        background: #F7F8FA;
        position: relative;
        
        .ant-input-affix-wrapper{
            .ant-input-prefix{
                color: rgba(0, 0, 0, 0.6);
                margin-right: 10px;
            }
            border: 1px solid #DCDCDC;
            width: 500px;
            height: 40px;
            line-height: 40px;
            border-radius: 6px;
            padding: 10px;
            position: absolute;
            left: 50%;
            top: 50%;
            right: 50%;
            bottom: 50%;
            transform: translate(-50%,-50%);
        }


        .addFileBox{
            position: absolute;
            left: 50%;
            top: 50%;
            right: 50%;
            bottom: 50%;
            transform: translate(-50%,-50%);
            text-align: center;
            width: 100px;
            height: 80px;
            cursor: pointer;
            .addFileIcon{
                width: 40px;
                height: 40px;
                line-height: 40px;
                border-radius: 10px;
                background: #FFFFFF;
                color: #409EFF;
                text-align: center;
                margin-left: 50%;
                transform: translateX(-50%);
                margin-bottom: 10px;
            }
            p{
                font-size: 0.7rem;
                color: #3D3D3D;
            }
        }

        .uploadFileBox{
            position: absolute;
            left: 50%;
            top: 50%;
            right: 50%;
            bottom: 50%;
            transform: translate(-50%,-50%);
            text-align: center;
            width: 200px;
            height: 80px;
            cursor: pointer;
            .addFileIcon{
                width: 40px;
                height: 40px;
                line-height: 40px;
                border-radius: 10px;
                background: #FFFFFF;
                color: #5E5ADB;
                text-align: center;
                margin-left: 50%;
                transform: translateX(-50%);
                margin-bottom: 10px;
            }
            p{
                font-size: 0.7rem;
                color: #3D3D3D;
            }
            #fileInput3 {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                opacity: 0;
            }
        }

        // .ant-btn{
        //     border-radius: 16px;
        //     border: 0;
        //     margin-right: 20px;
        // }
        // .ant-btn-primary{
        //     background: #60B4F6;
        // }
        // .ant-btn-default{
        //     background: #E4EDF2;
        //     color: #3D3D3D;
        // }
    }
}