.layoutTopFrame {
  height: 3rem!important;
  padding: 0!important;
  background: transparent!important;
  min-width: 1005px;

  .singleLogo{
    width: 2.5rem;
    float: left;
    overflow: visible;
    display: flex;
    align-items: center;
    margin-left: 1.8rem;
    margin-top: 0.7rem;
  }
  .singleLogoText{
    position: absolute;
    left: 4.6rem;
    top: 0;
    line-height: 60px;
    color: #1890ff;
    font-size: 1rem;
  }
  .singleLogoWhiteText{
    position: absolute;
    left: 4.6rem;
    top: 0;
    line-height: 60px;
    color: #ffffff;
    font-size: 1rem;
  }
  .layoutTopLogo {
    width: 10rem;
    margin-left: 1.8rem;
    margin-top: 0.7rem;
    float: left;
    overflow: visible;
    display: flex;
    align-items: center;
    img{
      width: 100%;
    }
    > span {
      white-space: nowrap;
      font-size: 0.8rem;
      font-weight: bold;
      color: #1A9DF9;
      cursor: default;
      user-select: none;
      line-height: 1rem;
      margin-left: 0.5rem;
      position: relative;
      padding-left: 0.3rem;

      &:before {
        content: "";
        width: 0.2rem;
        height: 0.2rem;
        border-radius: 50%;
        background: #1A9DF9;
        position: absolute;
        left: -0.2rem;
        top: 0.4rem;
      }
    }
  }
  .fileTitle{
    float: left;
    margin-left: 10px;
    font-size: 14px;
    .anticon-download{
      cursor: pointer;
      margin-left: 10px;
    }
  }
  .layoutTopRight {
    float: right;
    color: #fff;
    .layoutTopNav {
      margin-top: 0.5rem;
      float: left;
      margin-right: 2.4rem;
      .ant-tabs-tab-disabled:hover{
        color: rgb(140 140 140);
      }
      .ant-tabs-tab.ant-tabs-tab-disabled{
        color: rgb(140 140 140);
      }
      .ant-tabs-tab {
        padding: 0.4rem 0.8rem;
        margin: 0;
        font-size: 0.8rem;
      }
      .ant-tabs-top > .ant-tabs-nav::before {
        border: 0;
      }

      .navTabs{
        .navTabsItem{
          float: left;
          color: #FFFFFF;
          padding: 0.4rem 0.8rem;
          margin: 0;
          font-size: 0.8rem;
          line-height: 24px;
        }
        .navTabsItemSelected{
          border-bottom: 2px solid #FFFFFF;
        }
        .navTabsItemDisabled{
          color: rgba(140, 140, 140, 0.6);
          cursor: no-drop;
        }
        .navTabsItemDefalut{
          cursor: pointer;
        }
      }
    }
    .layoutTopIcon {
      margin-top: 1rem;
      float: left;
      .icon-houtaizonglan,
      .icon-line-041,
      .icon-zhuti2,
      .anticon-question-circle,
      .icon-tishi{
        font-size: 1.2rem;
        margin-right: 1.2rem;
        float: left;
        cursor: pointer;
      }
      .navAIIcon{
        font-size: 0.8rem;
        height: 1.2rem;
        line-height: 1.2rem;
        float: left;
        margin-right: 1.2rem;
        // cursor: pointer;
        cursor: not-allowed;
        color: rgb(118 118 118);
        .icon-jiqirenpeixun {
          font-size: 1.2rem;
          margin-right: 0.3rem;
          float: left;
        }
      }
      .themeBox{
        float: left;
      }
      .shoppingCart{
        float: left;
        font-size: 20px;
        height: 24px;
        line-height: 24px;
        margin-right: 1.2rem;
        cursor: pointer;
        .anticon-shopping-cart{
          font-size: 1.2rem;
          float: left;
        }
      }
      .manualBox{
        float: left;
        cursor: pointer;
        height: 24px;
        line-height: 24px;
        color: #000;
        font-size: 0.8rem;
        margin-right: 20px;
      }
      .headIcon {
        width: 1.2rem;
        height: 1.2rem;
        background: #3dadee;
        border-radius: 50%;
        margin-right: 2.1rem;
        overflow: hidden;
        float: right;
        cursor: pointer;
        text-align: center;
        color: #fff;
        line-height: 1.2rem;
        font-size: 0.7rem;
      }
      .message {
        position: relative;
        float: left;
        .messageTips {
          width: 1rem;
          height: 1rem;
          line-height: 1rem;
          text-align: center;
          font-size: 0.6rem;
          border-radius: 50%;
          background: #ff3116;
          position: absolute;
          left: 0.7rem;
          top: 0.1rem;
        }
      }
    }
  }
  .questionCircleOutlined{
    position: absolute;
    top: 14px;
    left: 217px;
    font-size: 0.8rem;
    cursor: pointer;
  }

}

.layoutHome {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1;
  // max-width: 1440px;

  // 切换到知识中心后影响消息中心弹窗的样式
  // .ant-tabs-ink-bar {
  //   background: #fff;
  // }
  // .ant-tabs {
  //   color: #fff;
  // }
  // .ant-tabs-tab:hover,
  // .ant-tabs-tab-btn:active,
  // .ant-tabs-tab-btn:focus {
  //   color: #fff;
  // }

  // .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  //   color: #fff;
  // }
}
.layoutOther {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1;
  background: white!important;
  box-shadow: 0 0.05rem 0.45rem 0 rgba(0,0,0,0.3);

  .icon-houtaizonglan,
  .icon-line-041,
  .icon-zhuti2,
  .anticon-question-circle,
  .icon-tishi,
  .anticon-shopping-cart,.shoppingCart{
    color: #1890ff;
  }

  .layoutTopRight {
    .layoutTopNav {
      .ant-tabs-tab-disabled:hover{
        color: rgba(140, 140, 140, 0.6);
      }
      .ant-tabs-tab.ant-tabs-tab-disabled{
        color: rgba(140, 140, 140, 0.6);
      }
      .navTabs{
        .navTabsItem{
          float: left;
          color: #000000;
          padding: 0.4rem 0.8rem;
          margin: 0;
          font-size: 0.8rem;
          line-height: 24px;
        }
        .navTabsItemSelected{
          border-bottom: 2px solid #1890ff;
        }
        .navTabsItemDisabled{
          color: rgba(140, 140, 140, 0.6);
          cursor: no-drop;
        }
        .navTabsItemDefalut{
          cursor: pointer;
        }
      }
    }
  }
}

.rootLayout {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;

  .layoutContent {
    //background: white;
    //position: fixed;
    //top: 4rem;
    //left: 1rem;
    //right: 1rem;
    //bottom: 1rem;
    //overflow: auto;
    flex: 1;
    overflow: hidden;
    margin-top: 3rem;
    .ant-layout {
      height: 100%;
      // overflow: hidden;
    }
  }

  .AIHelperBox{
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    z-index: 10;
    width: 500px;
    height: 600px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 30px 5px rgba(0,0,0,0.2);
    padding: 0.5rem;
    .anticon-close{
      position: absolute;
      top: 0.6rem;
      right: 0.5rem;
      color: rgba(0, 0, 0, 0.45);
      font-size: 0.9rem;
    }
  }
  
  .AIMultiDocDetailsBox{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 60px;
    background: #dfe1e4;
    z-index: 10;
    padding: 0.5rem;
  }

    
  .toolOperateBox {
    position: fixed;
    bottom: 2rem;
    right: 1rem;
    z-index: 10;
    line-height: 1rem;
    visibility: hidden;

    .intelligentAssistantIcon {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      right: -3.6rem;
      transform: rotate(-30deg);
      padding-right: 1rem;

      .iconBtn {
        .iconfont {
          font-size: 3.4rem;
          color: #53b1ff;
        }
      }
      .text {
        font-size: 0.6rem;
        color: #53b1ff;
        line-height: 0.6rem;
        zoom: 0.8;
        letter-spacing: 2px;
      }

      &:hover {
        right: -1rem;
        transform: unset;
      }
    }

    .intelligentAssistantChatBox {
      position: fixed;
      z-index: 11;
      bottom: 2rem;
      right: 1rem;
      width: 60vw;
      height: 50vh;
      max-width: 26rem;
      min-width: 16rem;
      border-radius: 0.4rem;
      background: #fff;
      box-shadow: 0 0 0.8rem 0 rgba(0,0,0,0.3);
      overflow: hidden;
      transition: width linear 0.1s, height linear 0.1s, max-width 0.1s;

      .moveBar {
        position: absolute;
        z-index: 1;
        width: 10%;
        height: 0.2rem;
        border-radius: 0.2rem;
        background: #d0d0d0;
        cursor: move;
        top: 0.3rem;
        left: 50%;
        transform: translateX(-50%);
      }

      &.bigStatus {
        position: fixed;
        bottom: 1rem;
        right: 1rem;
        width: calc(100vw - 2rem);
        height: calc(100vh - 2rem);
        max-width: calc(100vw - 2rem);
        transition: width linear 0.2s, height linear 0.2s, max-width 0.2s, right linear 0.2s, bottom linear 0.2s;

        .middleChatBox {
          .chatHeadBox {
            .chatHeadRightBox {
              .chatModalIconBtn {
                .icon-suoxiao1 {
                  font-size: 1rem;
                }

                +.chatModalIconBtn {
                  margin-left: 0.8rem;
                }
              }
            }
          }
        }
      }
    }
  }

  .uploadFileModalBox {
    position: fixed;
    width: 100%;
    height: 100% !important;
    background: rgba(0,0,0,0.3) !important;
    left: 0;
    top: 0;
    z-index: 1001;
    display: flex;
    align-items: center;
    justify-content: center;

    .uploadBox {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      background-color: #fff;
      //padding: 1rem;
      //width: 30rem;
      border-radius: 0.4rem;
      //box-shadow: 0 0.1rem 0.45rem 0.05rem rgb(192, 215, 233);
      box-shadow: 0 0.2rem 0.5rem 0 rgba(0, 0, 0, 0.3);
      position: relative;

      .closeBtn {
        position: absolute;
        right: 1rem;
        top: 1rem;
        line-height: 0;
        cursor: pointer;

        .iconfont {
          font-size: 1rem;
          color: #c9c8c8;
        }
      }

      .loadingBox {
        padding: 1rem 0;

        .ant-spin {
          .ant-spin-text {
            font-size: 0.6rem!important;
            line-height: 1rem;
            margin-top: 0.2rem;
          }
        }

        .tipBox {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1rem;
          color: #45B42B;

          .iconfont {
            font-size: 1.5rem;
            margin-right: 0.5rem;
          }

          &.stopStatus {
            color: red;

            .tipText {
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 1rem;
            }

            .linkBtn {
              font-size: 0.8rem;
              color: #1A9DF9;
              cursor: pointer;
            }
          }
        }
      }

      .uploadFileComponentBox {
        box-shadow: none;
        padding-top: 3rem;
      }

      .uploadFileBox {
        margin: 1rem -0.3rem 1rem 0;
        padding-right: 0.3rem;
        width: 100%;
        height: 40vh;
        overflow: auto;
        position: relative;

        .uploadFileListBox {
          .sortName {
            display: block;
            font-size: 0.7rem;
            font-weight: bold;
            line-height: 1.2rem;
            color: #818181;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
          }

          .uploadFileListContentBox {
            margin-top: 10px;
            .uploadFileItem {
              display: block;
              overflow: hidden;
              border-radius: 0.4rem;
              border: 0.05rem solid #e0e0e0;
              padding: 0.2rem 4rem 0.3rem 0.2rem;
              position: relative;

              .fileInfo {
                display: flex;
                align-items: center;
                justify-content: space-between;
                overflow: hidden;

                .editBtn {
                  position: absolute;
                  right: 7px;
                  top: 7px;
                  border-radius: 5px;
                  .iconfont {
                    font-size: 0.8rem;
                    margin-right: 3px;
                  }
                }

                .fileName {
                  flex: 1;
                  font-size: 0.6rem;
                  color: #333;
                  line-height: 1rem;
                  max-width: 400px;
                }

                .operateBox {
                  flex-shrink: 0;
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                  margin-left: 0.5rem;

                  .iconfont {
                    flex-shrink: 0;
                    font-size: 0.8rem;

                    &.icon-pc-processing {
                      color: #1A9DF9;
                    }
                    &.icon-fail {
                      color: #ff4d4f;
                    }
                    &.icon-success {
                      color: #10dc10;
                    }
                  }

                  .processNumText {
                    display: block;
                    width: 2.1rem;
                    flex-shrink: 0;
                    text-align: right;
                    font-size: 0.6rem;
                    zoom: 0.8;
                    color: #60b4f6;
                  }
                }
              }

              .processBox {
                margin-top: 0.2rem;
                border-radius: 0.5rem;
                border: 0.05rem solid #dcdcdc;
                overflow: hidden;

                .processLine {
                  display: block;
                  height: 0.3rem;
                  transition: all 0.1s;
                }
              }

              +.uploadFileItem {
                margin-top: 0.5rem;
              }

              &.processing {
                .processBox {
                  .processLine {
                    background: #1A9DF9;
                  }
                }
              }
              &.fail {
                .fileInfo {
                  .fileName {
                    color: #ff4d4f;
                  }
                }

                .processBox {
                  .processLine {
                    background: #ff4d4f;
                  }
                }
              }
              &.success {
                .processBox {
                  .processLine {
                    background: #10dc10;
                  }
                }
              }
            }
            .ant-checkbox-group{
              width: 100%;
              .ant-checkbox-wrapper{
                width: 100%;
                display: flex;
                margin-bottom: 10px;
                .ant-checkbox{
                  float: left;
                }
                span + span {
                    flex: 1;
                }
              }
              .ant-checkbox-wrapper + .ant-checkbox-wrapper {
                margin-left: 0;
              }
            }

          }

          +.uploadFileListBox {
            margin-top: 0.5rem;
          }
        }

        .viewBtn {
          position: absolute;
          right: 0;
          bottom: 1rem;
          line-height: 0;

          .iconfont {
            font-size: 1.2rem;
          }
        }
      }

      .footerBtnBox {
        display: flex;
        justify-content: right;
        width: 100%;

        > span {
          height: 2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.7rem;
          border-radius: 0.4rem;
          cursor: pointer;
          border: 1px solid #b8b8b8;
          width: 140px;
        }

        .viewBtn {
          margin-left: 1rem;
          box-shadow: none;
        }
      }
    }

    .circleProcessBox {
      display: none;
    }

    &.smallStatus {
      width: 6rem;
      height: 4rem!important;
      right: -2rem;
      bottom: 0;
      left: unset;
      top: unset;
      overflow: visible!important;
      transition: right 0.3s linear;
      background: none!important;
      padding-right: 2rem;

      .uploadBox {
        display: none;
      }

      .circleProcessBox {
        display: block;
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
        position: relative;
        cursor: pointer;
        overflow: hidden;
        box-shadow: 0 0 0.6rem 0.05rem rgba(107, 107, 107, 0.5);
        zoom: 0.6;

        .circleSvg {
          display: block;
          width: 100%;
          height: 100%;
          transform: rotate(-90deg);

          > circle {
            transition: stroke-dasharray 0.4s linear, stroke 0.3s;
          }
        }

        &:before {
          content: attr(data-num);
          position: absolute;
          left: 0.5rem;
          top: 0.5rem;
          width: 3rem;
          height: 3rem;
          border-radius: 50%;
          background: none;
          box-shadow: inset 0 0 0.4rem 0.05rem rgba(107, 107, 107, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.8rem;
          color: #77ce56;
          line-height: 0;
        }
      }
    }
  }

  .analysisBox{
    position: absolute;
    right: 20px;
    bottom: 20px;
    height: 30px;
    line-height: 30px;
    box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px;
    font-size: 0.7rem;
    padding: 0 20px;
    background: #fff;
    border-radius: 10px;
    cursor: pointer;
    .anticon {
      font-size: 0.6rem;
      color: #409EFF;
      margin-right: 0.1rem;
    }
    .icon-dui{
      font-size: 0.7rem;
      color: #2BA471;
      float: right;
      margin-top: 9px;
      margin-left: 5px;
    }
  }

  .rootFrameBottom {
    padding: 0;
    height: 1.6rem;

    .bottomContent {
      display: flex;
      width: 100%;
      height: 100%;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      color: #aeaeae;
      font-size: 0.6rem;
      border-top: 0.05rem solid #e7e7e7;
      background-color: rgba(245, 248, 248);
    }
  }
}

.minDivider {
  margin: 0 0 7px 0!important;
}


.messagePopover{
  width: 22rem;
  left: auto !important;
  right: 10px !important;
  top: 30px !important;
  // overflow: hidden;
  .ant-popover-inner-content {
    // padding: 0 0.5rem;
    //   background: #eee;
    .madelFoot {
      text-align: right;
      color: #169bd5;
      cursor: pointer;
    }
  }
  .interactionMessageFrame,.checkMessageFrame,.systemMessageFrames,.checkToDoMessageFrame,.ownerApplyMessageFrame {
    // width: 95%;
    max-height: 8rem;
    overflow-y: scroll;
    .knowledgeName {
      margin-bottom: -0.3rem;
      margin-right: 1rem;
      display: inline-block;
      width: 10rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .isFailed{
      color: #ff3116!important;
    }
    .isPass{
      color: #52c41a!important;
    }
    .ant-spin-container {
      font-size: 0.65rem;
      .ant-list-item{
        display: block;
      }
      li {
        color: #999;
        span {
          font-size: 0.7rem;
          color: #169bd5;
        }
        .cursorSpan {
          cursor: pointer;
        }
      }
    }
  }
  .interactionMessageFrame::-webkit-scrollbar {
    display: none;
  }
  .checkMessageFrame::-webkit-scrollbar {
    display: none;
  }
  // .checkToDoMessageFrame::-webkit-scrollbar {
  //   display: none;
  // }
  // .ownerApplyMessageFrame::-webkit-scrollbar {
  //   display: none;
  // }
  .systemMessageFrames::-webkit-scrollbar {
    display: none;
  }
}

.changePasswordModal{
  top: 50% !important;
  transform: translateY(-50%) !important;

  .tipsText{
    position: absolute;
    left: 99px;
    font-size: 0.7rem;
    color: #afaeae;
    bottom: 91px;
  }
}
