.notPublished {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  > div {
    flex: 1 1 50%;
    box-sizing: border-box;
    padding: 0 1rem 0.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 0.4rem;
    overflow: hidden;

    + div {
      margin-top: 0.5rem;
    }
  }

  .audit,.draft {

    .auditHeadBox {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #ccc;
      margin-bottom: 0.5rem;
      padding: 0.5rem 0;

      .headTitleText {
        font-size: 0.8rem;
        color: #333;
      }

      .pagination {
        margin-left: 1rem;

        .paginationFrame {
          margin: 0;
          padding: 0;

          .ant-pagination {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            > li {
              background: #eee !important;
              border-radius: 0.2rem;
              border: none;
              min-width: unset;
              width: 1.2rem;
              height: 1.2rem;
              line-height: 1.2rem;

              > a, .anticon {
                font-size: 0.7rem;
                color: #333;
                vertical-align: unset!important;
              }

              > button {
                border: none!important;
                background: none!important;
                display: flex;
                align-items: center;
                justify-content: center;
              }

              &.ant-pagination-disabled {
                .anticon {
                  color: #b7b6b6;
                }
              }

              &.ant-pagination-item-active {
                background: #1A9DF9 !important;
                box-shadow: 0 0.05rem 0.2rem 0.05rem rgba(26, 157, 249, 0.73);
                font-weight: normal;

                > a {
                  color: #fff;
                }
              }

              &.ant-pagination-total-text, &.ant-pagination-options, &.ant-pagination-simple-pager {
                background: none!important;
                width: unset;
              }

              &.ant-pagination-options {
                .ant-select {
                  height: 1.2rem;
                  line-height: 1rem;

                  .ant-select-selector {
                    height: 100%!important;
                    border-color: #d9d9d9!important;
                    box-shadow: none!important;
                    border-radius: 0.2rem;

                    .ant-select-selection-search {
                      height: 100%;

                      input {
                        height: 100%;
                        line-height: 0;
                      }
                    }

                    .ant-select-selection-item {
                      height: 100%;
                      line-height: 1.1rem;
                    }
                  }
                }

                .ant-pagination-options-quick-jumper {
                  height: 100%;
                  line-height: 1.1rem;

                  > input {
                    height: 100%;
                    line-height: 1.1rem;
                    border-color: #d9d9d9!important;
                    box-shadow: none!important;
                    border-radius: 0.2rem;
                    text-align: center;
                    font-size: 0.6rem;
                  }
                }
              }
            }
          }
        }
      }
    }


    .auditCon,.draftCon {
      flex: 1;
      margin-right: -0.3rem;
      padding-right: 0.3rem;
      overflow-y: auto;
    }
  }
}
