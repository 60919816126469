.knowledgeDetailsFrame{
    height: 100%;
    overflow: auto;
    background: #f0f2f5;
    position: relative;

    .backButton{
        top: 1.3rem !important;
    }
    .layoutSider{
        overflow-y: auto;
        overflow-x: hidden;
        background: #fff;
        margin: 0.5rem 0;
        
    }
    .layoutSiderMulti{
        overflow-y: auto;
        overflow-x: hidden;
        background: #fff;
        border-right: 10px solid #f0f2f5;
        .multiFileItem{
            margin: 15px 10px;
            display: flex;
            .multiFileTitle{
                border-radius: 5px;
                display: block;
                margin-left: 10px;
            }
            .checkedMultiFile{
                background: #F7F8FA;
                box-shadow: 0px 4px 2px 0px #409eff;
                padding: 5px 10px;
            }
        }
    }

    // 左侧样式
    .layoutLeftSiderdefault{
        overflow: hidden;
        position: relative;
        width: 310px;
        padding-right: 30px;
        border-right: 10px solid #f0f2f5;
        background: #fff;
    }
    .layoutLeftSiderClose{
        animation-name: animationClose;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        overflow: hidden;
        position: relative;
        padding-right: 30px;
        border-right: 10px solid #f0f2f5;
    }
    .layoutLeftSiderOpen{
        animation-name: animationOpen;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        overflow: hidden;
        position: relative;
        padding-right: 30px;
        border-right: 10px solid #f0f2f5;
    }
    .basicInformation{
        padding: 0 0.5rem;
        .ant-col{
            font-size: 0.7rem;
            margin: 0.2rem 0;
        }
    }
    .switchButton{
        width: 30px;
        background: #ffffff;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        .DoubleRightOutlined{
            position: absolute !important;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
        }
        
    }


    .site-layout {
        background: #fff;
        .knowledgeHeader {
            background: #fff;
            padding: 0px;
            height: 80px;
            padding-left: 1rem;
            position: relative;
            .ant-space{
                font-size: 0.7rem;
                height: 20px;
                line-height: 20px;
                float: left;
                color: rgb(135 135 135 / 85%);
            }
            .ant-typography{
                margin-top: 0.5rem;
                margin-bottom: 0.5rem;
            }
        }

        .centerContentBox{
            position: relative;
            #edui1_toolbarbox{
                display: none;
            }
            
            .oprationCloseAndSearch{
                position: absolute;
                top: 10px;
                right: 11px;
                >div{
                    background: #fff;
                    width: 2rem;
                    height: 2rem;
                    line-height: 2rem;
                    text-align: center;
                    cursor: pointer;
                    border-radius: 50%;
                    color: #1A9DF9;
                    background-color: #fff;
                    box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
                    margin-bottom: 1rem;
                }
            }
            
            .oprationFooter{
                position: absolute;
                right: 20px;
                bottom: 20px;
                z-index: 10;
                background: #fff;
                border-radius: 10px;
                padding: 0.5rem;
                font-size: 0.6rem;
                box-shadow: #cbcbcb 0px 4px 4px, rgb(0 0 0 / 10%) 0px 6px 14px, rgb(0 0 0 / 6%) 0px 0px;
                .oprationSwitch{
                    width: 22px;
                    text-align: center;
                    border-right: 1px solid #ccc;
                    margin-right: 10px;
                    font-size: 12px;
                    height: 16px;
                    line-height: 16px;
                    cursor: pointer;
                    float: left;
                }
                .oprationFooterDefalut{
                    width: 173px;
                }
                .oprationFooterClose{
                    animation-name: animationOprationFooterClose;
                    animation-duration: 1s;
                    animation-fill-mode: forwards;
                    overflow: hidden;
                }
                .oprationFooterOpen{
                    animation-name: animationOprationFooterOpen;
                    animation-duration: 1s;
                    animation-fill-mode: forwards;
                    overflow: hidden;
                }
                .ant-space-item{
                    cursor: pointer;
                }
            }
        }
    }

    .zipChildFileList {
        height: 100%;
        background: #fff;
        overflow: hidden;
        padding: 1rem;

        .scrollBox {
            height: 100%;
            overflow: auto;
            margin-right: -0.3rem;
            padding-right: 0.3rem;

            .fileItem {
                padding: 0.5rem;
                background: #eee;
                overflow: hidden;
                border-radius: 0.4rem;
                line-height: 1rem;
                cursor: pointer;

                .infoBox {
                    display: flex;
                    align-items: center;

                    .anticon {
                        flex-shrink: 0;
                        margin-right: 0.5rem;
                    }

                    .fileName {
                        flex: 1;
                        font-size: 0.8rem;
                        color: #333;
                    }
                }

                .timeText {
                    display: block;
                    padding-left: 1.5rem;
                    font-size: 0.7rem;
                    margin-top: 0.5rem;
                    color: #868686;
                }

                +.fileItem {
                    margin-top: 1rem;
                }
            }

            .noneDataBox {
                flex-direction: column;
                height: 100%;

                .iconfont {
                    margin: 0;
                }

                > span {
                    line-height: 1rem;
                    font-size: 0.7rem;
                    margin-top: 0.2rem;
                }
            }
        }
    }

    // 右侧
    .layoutRightSiderdefault{
        overflow: hidden;
        position: relative;
        width: 400px;
        padding-left: 30px;
        border-left: 10px solid #f0f2f5;
    }
    .layoutRightSiderdefaultAi{
        overflow: hidden;
        position: relative;
        width: 500px;
        padding-left: 30px;
        border-left: 10px solid #f0f2f5;
    }
    .layoutRightSiderClose{
        animation-name: animationRightClose;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        overflow: hidden;
        position: relative;
        padding-left: 30px;
        border-left: 10px solid #f0f2f5;
    }
    .layoutRightSiderOpen{
        animation-name: animationRightOpen;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        overflow: hidden;
        position: relative;
        padding-left: 30px;
        border-left: 10px solid #f0f2f5;
    }

    .layoutRightSiderCloseAi{
        animation-name: animationRightCloseAi;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        overflow: hidden;
        position: relative;
        padding-left: 30px;
        border-left: 10px solid #f0f2f5;
    }
    .layoutRightSiderOpenAi{
        animation-name: animationRightOpenAi;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        overflow: hidden;
        position: relative;
        padding-left: 30px;
        border-left: 10px solid #f0f2f5;
    }


    
    
    .rightbaseInfor{
        padding: 0.5rem 0.5rem 0.5rem 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        .rightMenu{
            margin-bottom: 1rem;
        }
        .rightMenuItem{
            font-size: 0.7rem;
            color: rgb(93 93 93);
            display: initial;
            cursor: pointer;
            padding: 5px 15px;
            border-radius: 15px;
        }
        .rightMenuItemSelected{
            background: #ecf5ff;
            color: #409eff;
        }

        .readNoteTitle{
            margin-bottom: 0.5rem;
            .ant-tabs-top > .ant-tabs-nav{
                margin: 0;
            }
            .ant-typography{
                font-size: 0.8rem;
                line-height: 38px;
                display: inline-block;
                height: 38px;
            }
        }
    }

    
    .switchButtonRight{
        width: 30px;
        background: #ffffff;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        .DoubleRightOutlined{
            position: absolute !important;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
        }
        // .rotateOpen{
        //     animation-name: animationRotateRightOpen;
        //     animation-duration: 1s;
        //     animation-fill-mode: forwards;
        // }
        // .rotateClose{
        //     animation-name: animationRotateRightClose;
        //     animation-duration: 1s;
        //     animation-fill-mode: forwards;
        // }
    }

    .knowledgeNote{
        background: #fff;
        .ant-tabs-tab{
            font-size: 0.8rem;
        }
    }
    .countdown{
        position: absolute;
        right: 1rem;
        top: 0.5rem;
        color: #1890ff;
        opacity:0;
        span{
            padding-right: 10px
        }
    }

    .custom-mount{
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
        .edui-default{
            height: 100% !important;
        }
        .edui-editor-toolbarbox{
            display: none
        }
    }
    .noPrintMask{
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 999;
        display: none;
    }
    .highlight{background: red}
    .ocrIcon{
        position: absolute;
        right: 10px;
        top: 90px;
        z-index: 10;
    }
    .currentPageBox{
        .currentPageContent{
            height: 2.1rem;
            line-height: 2.1rem;
            background: #FFFFFF;
            border-radius: 4px;
            margin-top: 1rem;
            padding-left: 1rem;
        }
    }

    .labelOpertion,.currentPageOpertion,.noteOperation,.excerptOperation{
        .iconfont{
            line-height: 1;
            font-size: 0.8rem;
            text-align: center;
            padding-right: 0.5rem;
            float: left;
            margin-top: 2px;
        }
    }
    .noteContent,.labelContent,.publicNoteContent{
        background: #F0F5F8;
        border-radius: 4px;
        // margin-top: 1rem;
        padding: 0.5rem;
        margin-bottom: 0.5rem;
    }
}

.downloadContent{
    p{
        margin-bottom: 0;
        cursor: pointer;
        line-height: 1.7rem;
    }
    p:hover{
        color: #1890ff
    }
}

.specifiedRangeModal{
    .ant-form-item{
        margin-bottom: 0.5rem;
    }
    .prompt{
        color: #5a5a5a
    }
}


// 旋转样式
.rotateOpen{
    animation-name: animationRotateOpen;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}
.rotateClose{
    animation-name: animationRotateClose;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

// 收藏等操作栏
@keyframes animationOprationFooterOpen{
    from {
        width: 0;
    }
    to {
        width: 173px;
    }
}

@keyframes animationOprationFooterClose{
    from {
        width: 173px;
    }
    to {
        width: 0;
    }
}

// 左侧
@keyframes animationClose {
    from {
        width: 300px;
    }
    to {
        width: 0;
    }
}

@keyframes animationOpen {
    from {
        width: 0;
    }
    to {
        width: 320px;
    }
}

@keyframes animationRotateOpen {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(180deg);
    }
}

@keyframes animationRotateClose {
    from {
        transform: rotate(180deg);
    }
    to {
        transform: rotate(0deg);
    }
}

// 右侧
@keyframes animationRightClose {
    from {
        width: 400px;
    }
    to {
        width: 0;
    }
}
@keyframes animationRightCloseAi {
    from {
        width: 500px;
    }
    to {
        width: 0;
    }
}

@keyframes animationRightOpen {
    from {
        width: 0;
    }
    to {
        width: 400px;
    }
}
@keyframes animationRightOpenAi {
    from {
        width: 0;
    }
    to {
        width: 500px;
    }
}


@keyframes animationRotateRightOpen {
    from {
        transform: rotate(180deg);
    }
    to {
        transform: rotate(0);
    }
}

@keyframes animationRotateRightClose {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(180deg);
    }
}

.resultBox{
    .prompt{
        font-size: 0.9rem;
        span{
            display: inline-block;
            margin-bottom: 20px;
        }
    }
}
