.noteManage {
  height: 100%;
  overflow: hidden;
  margin-left: 1rem;

  .ant-tabs,
  .ant-tabs-top {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .ant-tabs-nav {
      flex-shrink: 0;
      margin-bottom: 0.5rem;
      padding: 0 1rem;
      height: 3rem;
      background: #fff;
      border-radius: 0.4rem;
    }
    .ant-tabs-content-holder {
      flex: 1;
      //padding: 0 1rem;
      background: #fff;
      border-radius: 0.4rem;
      overflow: hidden;

      .ant-tabs-content {
        height: 100%;
        overflow: hidden;

        .ant-tabs-tabpane {
          height: 100%;
          overflow: hidden;
        }
      }
    }
  }
}
