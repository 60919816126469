.personalStatisticsFrame{
    background: white;
    .title{
        padding: 0.5rem 1rem;
        font-size: 1.2rem
    }
    .operation{
        padding: 0 1rem 1rem 1rem;
        display: flex;
        .operationLeft{
            flex: 1
        }
        .operationItem{
            height: 1.6rem;
            line-height: 1.6rem;
            width: 320px;
            display: inline-block;
            margin-bottom: 0.5rem;
            >span{
                display: inline-block;
                max-width: 70px;
            }
            .ant-picker,.ant-input{
                width: 240px;
            }
            .ant-input{
                flex: 1;
            }
        }
        .operationRight{
            float: right;
        }
    }
    .table{
        padding: 0 1rem 0 1rem;
    }
}
