.discussionBox{
    // margin: 20px 400px 0 330px;
    margin-top: 1rem;
    background: #fff;
    padding: 1rem;
    .askQuestion{
        display: flex;
        .headSculpture{
            width: 2.1rem;
            height: 2.1rem;
            border-radius: 50%;
            float: left;
            margin-right: 1rem;
            background: #3dadee;
            border-radius: 50%;
            text-align: center;
            color: #fff;
            line-height: 2rem;
        }
        .questionButton{
            flex: 1;
            background: #F0F5F8;
            border-radius: 8px;
            border: 0;
            height: 2.1rem;
            line-height: 2.1rem;
            padding-left: 0.5rem;
            font-size: 0.7rem;
            color: rgba(0, 0, 0, 0.45);
            cursor: pointer;
        }
        margin-bottom: 2rem;
    }
    .discussionOperation{
        font-size: 0.8rem;
        color: #333333;
        margin-bottom: 1rem;
        >span{
            color: #FF3320;
            font-size: 1.2rem;
            padding-right: 10px;
        }
        .ant-segmented{
            float: right;
        }
    }
    .ant-list-item-action{
        padding-left: 48px;
    }
    .CommentItem{
        margin-bottom: 1rem;
        display: flex;
        font-size: 0.8rem;
        color: #414141;
        .headIcon {
            width: 1rem;
            height: 1rem;
            background: #3dadee;
            border-radius: 50%;
            overflow: hidden;
            text-align: center;
            color: #fff;
            line-height: 1rem;
            font-size: 0.7rem;
            margin-right: 8px;
            margin-top: 3px;
        }
        .icon-taolun,.icon-xiala,.icon-icon_tiwen{
            float: left;
            margin-right: 5px;
            margin-top: 5px;
        }
        .icon-right{
            padding: 0 0.5rem;
        }
        .headSculpture{
            margin-right: 8px;
            width: 1rem;
            height: 1rem;
            margin-top: 3px;
        }
        .commentContent{
            flex: 1;
            overflow: auto;
            >div{
                word-break:normal;
                white-space: pre-wrap;
                word-wrap: break-word;
                line-height: 1.3rem;
            }
            .commentBotton{
                margin-top: 0.2rem;
                >span{
                    font-size: 0.6rem;;
                    color: #999999;
                    margin-right: 1rem;
                }
                .commentRight{
                    float: right;
                    font-size: 0.7rem;
                    color: #414141;
                }
            }
            .title{
                font-size: 0.8rem;
            }
        }
    }
    .CommentItemReply{
        margin-left: 2rem;
    }
    .CommentItemReplys{
        margin-left: 4rem;
    }
    .moreAnswer{
        text-align: center;
        height: 2.1rem;
        line-height: 2.1rem;
        background: #F0F5F8;
        border-radius: 8px;
        padding: 0 1.5rem;
        display: inline-block;
        font-size: 0.8rem;
        margin-left: 50%;
        transform: translateX(-50%);
    }
    .probe{
        margin-left: 4rem;
        height: 2.1rem;
        background: #F0F5F8;
        border-radius: 8px;
        line-height: 2.1rem;
        padding: 0 1.5rem;
        display: inline-block;
        font-size: 0.8rem;
    }
    
    .example{
        text-align: center
    }
    .clearfix:after{
        content: '';
        display: table;
        clear: both;
    }
    .bottomText{
        text-align: center;
        font-size: 0.6rem;
        color: #414141;
        background:#f6f6f6;
        line-height: 1.3rem;
        margin-top: 0.5rem;
    }
    .pointer{
        cursor: pointer;
    }
    .commentMessage{
        font-size: 0.7rem;
    }
    .titleChild{
        font-size: 0.9rem;
    }
}
.CommentItemReplys,.CommentItemReply{
    .titleChild{
        font-size: 0.7rem;
    }
    .commentMessage{
        font-size: 0.8rem;
    }
}