.homePageFrame{
  display: block;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: flex-start;
  overflow-x: hidden;
  overflow-y: scroll;
  
  .backTop{
    position: fixed;
    right: 50px;
    bottom: 140px;
    z-index: 10;
    width: 50px;
    height: 50px;
    line-height: 50px;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px 1px rgb(61 117 137 / 44%);
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    .anticon {
      font-size: 30px;
      color: #5A5A68;
      margin-top: 10px;
    }
  }
}
