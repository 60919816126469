.interactionMessageFrame {
  width: 100%;
  height: 100%;
  .interactionKnowledgeName {
    margin-bottom: -0.3rem;
    display: inline-block;
    // max-width: 8rem;
    white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
  }
}
