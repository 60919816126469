.generalKnowledgeFrame{
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .topTitle{
        flex-shrink: 0;
        height: 3rem;
        line-height: 4rem;
        font-size: 1.6rem;
        background: #fff;
        margin-bottom: 0.5rem;

        // tabs样式
        .tabsBox{
            margin-top: 0.3rem;
            position: absolute;
            left: 18.5rem;
            right: 1rem;
            .ant-tabs{
                margin-right: 100px;
            }
            .ant-tabs-nav{
                margin: 0
            }
            .ant-tabs-ink-bar{
                background: #fff;
            }
            .ant-tabs-tab{
                font-size: 0.8rem
            }
            .ant-tabs-top > .ant-tabs-nav::before{
                border: 0
            }
            .searchIcon{
                position: absolute;
                right: 8px;
                top: 50%;
                transform: translateY(-50%);
                font-size: 1.4rem;
                color: #1890ff;
                cursor: pointer;
                height: 3rem;
                line-height: 3rem;
            }
            .addButton{
                position: absolute;
                right: 70px;
                top: -22px;
            }
        }
        .leftTitle{
            float: left;
            font-size: 1rem;
            color: #1890ff;
            width: 16.5rem;
            padding-left: 1rem;
            margin-right: 1.5rem;
            height: 3rem;
            line-height: 3rem;
        }
    }

    .content{
        flex:1;
        display: flex;
        flex-direction: row;
        overflow: hidden;
    }
    .leftBox{
        flex: 1;
        max-width: 17rem;
        min-width: 12rem;
        height: 100%;
        background: #fff;
        position: relative;
        padding: 1rem;
        margin-right: 0.5rem;
        overflow: hidden;
    }
    .righBox{
        flex: 1;
        overflow: hidden;
        background: #fff;
        padding: 0.3rem 1rem 0;
    }
}

.knowledgeModalWrap{
    // position: relative;
    .operation{
        height: 1.6rem;
            line-height: 1.6rem;
        .operationItem{
            float: left;
            .title{
                display: inline-block;
                max-width: 70px;
                font-size: 0.7rem;
            }
            .ant-select{
                width: 420px;
            }
            .ant-input-affix-wrapper{
                width: 190px;
                margin: 0 1rem;
            }
        }
    }
    .selectAll{
        position: absolute;
        bottom: 83px;
    }
}
