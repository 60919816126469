.defaultSchemePageFrame{
  display: flex;
  width: 100%;
  height: 100%;
  min-width: 70rem;
  background: #F2F9FC;
  padding: 1rem;

  .leftBox {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    // overflow: auto;
    background: none;
    border-radius: 0.4rem;

    .leftTopBox {
      display: flex;
      width: 100%;
      height: 13.5rem;
      flex-shrink: 0;
      justify-content: space-between;
      align-items: center;

      > div {
        height: 100%;
        background: #fff;
        border-radius: 0.4rem;
        overflow: hidden;
      }

      .searchBox {
        flex: 1 1 64.0628%;
        // min-width: 26rem;
        // max-width: 40rem;
      }

      .hotSpotBox {
        flex: 1 1 34.375%;
        margin-left: 1rem;
      }
    }

    .leftMiddleBox {
      display: flex;
      width: 100%;
      height: 12rem;
      flex-shrink: 0;
      justify-content: space-between;
      align-items: center;
      margin: 1rem 0;

      > div {
        display: block;
        height: 100%;
        flex: 50% 1;
        background: #fff;
        border-radius: 0.4rem;
        overflow: hidden;

        + div {
          margin-left: 1rem;
        }
      }

      .knowledgeExplorationBox {

      }

      .aiBaseBox {

      }
    }

    .leftBottomBox {
      width: 100%;
      flex: 1;
      background: #fff;
      border-radius: 0.4rem;
      // overflow: hidden;
      // min-height: 65vh;
    }
  }

  .rightBox {
    display: flex;
    flex-direction: column;
    width: 29rem;
    height: 100%;
    flex-shrink: 0;
    margin-left: 1rem;
    // overflow: auto;
    border-radius: 0.4rem;

    > div {
      width: 100%;
      flex-shrink: 0;
      background: #fff;
      border-radius: 0.4rem;
      // overflow: hidden;

      + div {
        margin-top: 1rem;
      }
    }

    .rightTopBox {
      height: 13.5rem;
    }

    .rightMiddleBox {
      height: 9rem;
    }

    .rightBottomBox {
      flex: 1;
      // min-height: 65vh;
    }
  }
}
