.addModelFrame {
    display: flex;
    flex-flow: column;
    height: 100%;
    overflow: hidden;

    .topContent, .addModelContent {
        border-radius: 0.2rem;
    }

    .topContent {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-shrink: 0;
        padding: 1rem;
        background-color: #fff;

        .topContentLeft {
            .inputItemBox {
                display: flex;
                align-items: center;

                +.inputItemBox {
                    margin-top: 0.5rem;
                }

                .inputItemLabel {
                    font-size: 0.7rem;
                    color: #333;
                    flex-shrink: 0;

                    &:before {
                        font-size: 1rem;
                    }
                }

                .choiceLabelListBox {
                    display: flex;
                    flex-flow: row wrap;
                    position: relative;
                    overflow: hidden;
                    margin: -0.5rem 0 0 -0.5rem;

                    .labelValueItem {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 1.5rem;
                        padding: 0 0.6rem;
                        background: #F4F8FA;;
                        border-radius: 0.2rem;
                        font-size: 0.7rem;
                        color: #666;
                        margin: 0.5rem 0.5rem 0;
                        cursor: default;
                        user-select: none;
                        white-space: nowrap;
                        position: relative;

                        //&:only-child, &:first-child {
                        //    margin-left: 0;
                        //}

                        &.labelValueAddBtn {
                            cursor: pointer;
                            background-color: #60B4F6;
                            color: #fff;
                            line-height: 1;

                            .iconfont {
                                margin-right: 0.3rem;
                                margin-left: 0;
                                color: #fff;

                                &:hover {
                                    color: #fff;
                                }
                            }
                        }

                        > span {
                            line-height: 0.6rem;

                        }

                        .iconfont {
                            font-size: 0.6rem;
                            margin-left: 0.3rem;
                            cursor: pointer;
                            color: #8EA6C7;

                            &:hover {
                                color: #60B4F6;
                            }
                        }
                    }
                }
            }
        }

        .topContentRight {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex-shrink: 0;
            margin-left: 1rem;

            > span {
                display: flex;
                height: 1.5rem;
                align-items: center;
                cursor: pointer;

                &.viewBtn {
                    .iconfont {
                        font-size: 1.3rem;
                    }
                }

                + span {
                    margin-left: 1rem;
                }

                .iconfont {
                    font-size: 1rem;
                    color: #909091;
                }
            }
        }
    }

    .addModelContent {
        flex: 1;
        height: 100%;
        margin-top: 1rem;
        overflow: auto;
        position: relative;

        .addModelItem {
            display: block;
            padding: 1rem;
            background-color: #fff;
            border-radius: 0.2rem;

            + .addModelItem {
                margin-top: 2rem;
            }

            .stageNameBox {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;

                .stageNameLeft {
                    display: flex;
                    align-items: center;

                    .stageNameLabel {
                        flex-shrink: 0;
                        font-size: 0.7rem;
                        font-weight: bold;
                        color: #333;
                    }

                    .ant-input {
                        border: none;
                        border-bottom: 0.05rem solid #b6b3b3;
                        background: none;

                        &:focus {
                            box-shadow: none;
                        }
                    }
                }

                .stageOperateBox {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    flex-shrink: 0;
                    margin-left: 2rem;

                    > span {
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        height: 1rem;

                        .iconfont {
                            font-size: 0.9rem;
                            color: #333;
                        }
                    }

                    .addBtn {
                        margin-right: 1rem;
                    }

                    .deleteBtn {
                        .iconfont {
                            font-size: 1rem;
                        }
                    }
                }
            }

            .secondLevelBox {
                margin-top: 1.8rem;
                overflow: auto;
                margin-right: -0.5rem;
                padding-right: 0.5rem;
                display: flex;
                flex-flow: row wrap;
                position: relative;

                .secondLevelNode {
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: center;
                    width: 100%;

                    +.secondLevelNode {
                        margin-top: 2rem;
                    }

                    .secondLevelItem {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        flex-shrink: 0;
                        height: 2.4rem;
                        padding: 0 0.75rem;
                        cursor: default;
                        background-color: #60B4F6;
                        border-radius: 0.2rem;

                        .secondLevelNameInput {
                            font-size: 0.7rem;
                            color: #fff;
                            margin-right: 0.8rem;
                            height: 1rem;
                            background: none;
                            padding: 0.1rem 0.2rem;
                            border: none;
                            border-bottom: 0.05rem solid #fff;
                            text-align: center;

                            &::placeholder {
                                color: #dedcdc;
                            }

                            &:focus {
                                box-shadow: none;
                            }
                        }

                        .secondLevelItemOperate {
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;

                            > span {
                                cursor: pointer;
                                display: flex;
                                align-items: center;
                                height: 1rem;

                                .iconfont {
                                    font-size: 0.8rem;
                                    color: #fff;
                                }
                            }

                            .deleteBtn {
                                margin-left: 0.5rem;

                                .iconfont {
                                    font-size: 0.86rem;
                                }
                            }
                        }
                    }

                    .thirdLevelNode {
                        width: 100%;
                        padding-left: 3rem;
                        overflow: hidden;

                        .fileDataBox {
                            padding: 1rem;
                            border-radius: 0.2rem;
                            background-color: #F4F8FA;

                            .mapDataBox {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                overflow: hidden;
                                position: relative;
                                padding-right: 1.8rem;

                                .icon-lock, .mapDataDelete, .mapDataSign {
                                    flex-shrink: 0;
                                    line-height: 1;
                                }

                                .mapNameText {
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    font-size: 0.7rem;
                                    color: #333;
                                    line-height: 1;
                                }

                                .icon-lock {
                                    font-size: 0.6rem;
                                    //margin-left: 0.45rem;
                                    color: #4A8529;
                                    //top: 0.05rem;
                                }

                                .mapDataSign {
                                    font-size: 0.7rem;
                                    color: #999;
                                    line-height: 1;
                                }

                                .mapDataDelete {
                                    display: flex;
                                    align-items: center;
                                    height: 0.8rem;
                                    cursor: pointer;
                                    position: absolute;
                                    right: 0;

                                    .iconfont {
                                        font-size: 0.8rem;
                                        color: #37576E;
                                    }
                                }
                            }

                            .fileDataListBox {
                                margin-top: 0.6rem;

                                > span {
                                    display: block;
                                    font-size: 0.7rem;
                                    color: #999;
                                }

                                .fileItem {
                                    display: flex;
                                    align-items: center;

                                    > span {
                                        display: block;
                                        white-space: nowrap;
                                    }

                                    .fileUserName {
                                        margin: 0 1rem;
                                    }
                                }
                            }
                        }

                        .addSortBox {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            background: #F4F8FA;
                            border-radius: 0.2rem;
                            padding: 1rem;
                            width: 100%;

                            .addSortLeft {
                                display: flex;
                                align-items: center;

                                .sortNameInput {
                                    font-size: 0.7rem;
                                    color: #333;
                                    height: 1rem;
                                    background: none;
                                    padding: 0.1rem 0.2rem;
                                    border: none;
                                    border-bottom: 0.05rem solid #a5a4a4;
                                    text-align: center;
                                    width: 5.5rem;

                                    &::placeholder {
                                        color: #dedcdc;
                                        font-size: 0.7rem;
                                    }

                                    &:focus {
                                        box-shadow: none;
                                    }
                                }

                                .switchBox {
                                    display: flex;
                                    align-items: center;
                                    margin-left: 1.5rem;

                                    .switchText {
                                        font-size: 0.6rem;
                                        color: #333;
                                        cursor: default;
                                        margin-right: 0.5rem;
                                        white-space: nowrap;
                                    }

                                    .ant-switch {
                                        &.ant-switch-checked {
                                            background: #FF3116;
                                        }
                                    }
                                }
                            }

                            .addSortRight {
                                display: flex;
                                justify-content: flex-end;
                                align-items: center;
                                margin-left: 1rem;
                                width: 100% ;

                                > span {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    height: 1.8rem;
                                    cursor: pointer;

                                    .iconfont {
                                        font-size: 0.8rem;
                                        color: #333;
                                    }
                                }

                                .cancelBtn {
                                    margin-right: 1rem;
                                }

                                .confirmBtn {
                                    &.disabled {
                                        cursor: not-allowed;
                                        .iconfont {
                                            color: #b3afaf;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .mapItemBox {
                .mapItemName {
                    background-color: #fff;
                    width: 5rem;
                    height: 1.6rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }

    .addStageBox {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 2.8rem;
        background-color: #60B4F6;
        color: #fff;
        cursor: pointer;
        margin-top: 1rem;
        border-radius: 0.2rem;

        //&:hover {
        //  background-color: #60B4F6;
        //  color: #fff;
        //}

        .iconfont, > span {
            font-size: 0.8rem;
            user-select: none;
        }

        .iconfont {
            margin-right: 0.3rem;
        }
    }

    .isRequiredTip {
        &:before {
            left: -0.7rem;
            font-size: 0.9rem;
        }
    }
}

.labelChoiceModal {
    .choiceLabelTitleBox {
        display: flex;
        align-items: center;
        cursor: default;
        font-size: 0.9rem;
        color: #333;
        user-select: none;

        .iconfont {
            font-size: 1rem;
            color: #3D5C73;
            margin-right: 0.3rem;
        }
    }

    .labelChoiceContent {
        max-height: calc(100vh - 500px);
        // max-width: 32.5rem;
        // min-width: 16rem;
        overflow-y: auto;
        margin: 0 auto;

        .groupListItem {

            .choiceBtn {
                margin-right: 0.5rem;
                flex-shrink: 0;
            }

            +.groupListItem {
                margin-top: 0.5rem;
            }
            .groupItemTitle {
                display: flex;
                align-items: center;
                cursor: pointer;

                .titleText {
                    //margin-left: 0.5rem;
                    font-size: 0.8rem;
                    color: #333;
                    line-height: 1;
                }
            }

            .labelListBox {
                margin-top: 0.5rem;
                padding: 0.5rem;
                background: #F4F8FA;
                border-radius: 0.2rem;

                .labelListItem {
                    display: flex;
                    align-items: center;
                    overflow: hidden;
                    line-height: 1;

                    +.labelListItem {
                        margin-top: 0.75rem;
                    }

                    .labelNameBox {
                        display: flex;
                        align-items: center;
                        margin-right: 0.5rem;
                        cursor: pointer;

                        .labelNameText {
                            flex-shrink: 0;
                            font-size: 0.7rem;
                            color: #6a6262;
                            width: 4rem;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            text-align: center;
                            background: #e7e7e7ed;
                            padding: 4px 6px;
                            border-radius: 4px;
                        }
                    }

                    .labelValueListBox {
                        flex: 1 1 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        //display: block;
                        //align-items: center;
                        cursor: default;

                        .labelValueText {
                            display: inline-block;
                            font-size: 0.6rem;
                            color: #989696;
                            white-space: nowrap;

                            +.labelValueText {
                                margin-left: 0.8rem;
                            }
                        }
                    }
                }
            }
        }

        .noneDataBox {
            height: 15rem;
            flex-direction: column;

            .iconfont {
                font-size: 2.5rem;
                margin: 0 0 0.5rem;
            }
        }
    }

    // .ant-modal-body {
    //     padding: 1rem;
    // }

    // .ant-modal-footer {
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;

    //     button {
    //         width: 10rem;
    //         height: 2.5rem;
    //         font-size: 0.8rem;
    //         color: #333;
    //         border-radius: 0.2rem;
    //         background: #D9E6ED;
    //         border: none;

    //         +button {
    //             margin-left: 1.8rem !important;
    //             background: #60B4F6;
    //             box-shadow: 0 0.1rem 0.45rem 0.05rem rgba(96,180,246,0.65);
    //             color: #fff;
    //         }
    //     }
    // }
}