.newKnowledgeFrame {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: #f0f2f5;
  display: flex;
  flex-direction: column;

  .title {
    box-sizing: border-box;
    padding: 0 1rem;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 3rem;
    background: #fff;
    border-radius: 0.4rem;
    // .btns {
    //   display: flex;
    //   justify-content: space-around;
    //   align-items: center;
    //   width: 15%;
    //   height: 100%;
    //   button {
    //     width: 40%;
    //     height: 55%;
    //     font-size: 0.8rem;
    //     font-weight: 600;
    //     background-color: #169bd5;
    //     color: #fff;
    //     cursor: pointer;
    //     border-radius: 0.4rem;
    //     border: none;
    //   }
    // }
  }
  .newKnowledgeContent {
    width: 100%;
    height: 100%;
    // display: flex;
    // justify-content: space-between;
    display: flex;
    flex: 1;
    overflow: hidden;

    > div {
      border-radius: 0.4rem;
    }

    .knowledgeInfo {
      flex-shrink: 0;
      margin-right: 0.5rem;
      box-sizing: border-box;
      padding: 1rem 1rem 1rem 1rem;
      width: 17rem;
      height: 100%;
      overflow: hidden;
      background: #fff;
      display: flex;
      flex-direction: column;

      .scrollBox {
        height: 100%;
        margin-right: -0.6rem;
        padding-right: 0.6rem;
        overflow-y: auto;
        overflow-x: hidden;

        .InfoTabs {
          margin: 0.5rem 0;
          // height: 2.5rem;
          // display: flex;
          // justify-content: space-around;
          // align-items: center;
          font-weight: 600;
          // text-align: center;
          // border-bottom: 1px solid #ccc;
          // .tabLight {
          //   color: #169bd5;
          // }
        }
        .tabCon {
          width: 100%;

          form {
            label {
              font-weight: normal;
            }
            .ant-form-item {
              margin-bottom: 0.3rem;
            }
            .ant-row,
            .ant-form-item-row {
              margin-bottom: 0;
            }
            .ant-form-item-label > label {
              font-size: 0.65rem;
            }
            .vertical {
              width: 100%;
              .ant-row,
              .ant-form-item-row {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
              }
            }
            .selectInfo {
              width: 100%;
              display: flex;
              justify-content: space-around;
              font-size: 0.6rem;
              color: #999;
            }
          }
          .creatInfo{
            margin-top: 1rem;
            width: 100%;
            height: 4rem;
            font-size: 0.65rem;
            p{
              width: 100%;
              // padding-left: 0.6rem;
              .label{
                margin-right: 3.5rem;
              }
            }
          }
        }
      }

    }
    .custom-mount {
      background: #fff;
      flex: 1;
      // width: 76%;
      height: 100%;
      // background: pink;
      iframe{
        width: 100%;
        height: 100%;
      }
    }

    .zipChildFileList {
      flex: 1;
      height: 100%;
      background: #fff;
      overflow: hidden;
      padding: 1rem;

      .scrollBox {
        height: 100%;
        overflow: auto;
        margin-right: -0.3rem;
        padding-right: 0.3rem;

        .fileItem {
          padding: 0.5rem;
          background: #eee;
          overflow: hidden;
          border-radius: 0.4rem;
          line-height: 1rem;
          cursor: pointer;

          .infoBox {
            display: flex;
            align-items: center;

            .anticon {
              flex-shrink: 0;
              margin-right: 0.5rem;
            }

            .fileName {
              flex: 1;
              font-size: 0.8rem;
              color: #333;
            }
          }

          .timeText {
            display: block;
            padding-left: 1.5rem;
            font-size: 0.7rem;
            margin-top: 0.5rem;
            color: #868686;
          }

          +.fileItem {
            margin-top: 1rem;
          }
        }

        .noneDataBox {
          flex-direction: column;
          height: 100%;

          .iconfont {
            margin: 0;
          }

          > span {
            line-height: 1rem;
            font-size: 0.7rem;
            margin-top: 0.2rem;
          }
        }
      }
    }
  }
}
