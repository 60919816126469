.aiBaseFrame {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .backgroundBox {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    background-image: url("../../../../../assets/img/homePage/defaultScheme/aiBase.png");
    background-position: left center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-color: rgb(226,231,243);

    .entryBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 9.4rem;
      height: 2.25rem;
      border-radius: 0.4rem;
      font-size: 0.75rem;
      color: #fff;
      cursor: pointer;
      background: #60B4F6;
      position: absolute;
      z-index: 2;
      left: 1.9rem;
      bottom: 3.1rem;
    }

    .entryBtnDisabled {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 9.4rem;
      height: 2.25rem;
      border-radius: 0.4rem;
      font-size: 0.75rem;
      color: #fff;
      position: absolute;
      z-index: 2;
      left: 1.9rem;
      bottom: 3.1rem;
      color: #00000040;
      border-color: #d9d9d9;
      background: #f5f5f5;
      cursor: no-drop;
    }

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: #000;
      opacity: 0.05;
      transition: opacity 0.1s linear;
    }

    &:hover {
      &:before {
        opacity: 0;
      }
    }
  }
}
