.knowledgeCentreFrame {
    height: 100%;
    display: flex;
    flex-direction: column;

    .topBox{
        position: relative;
    }
    .topBg{
        height: 14rem;
        background-image: url('../../assets/img/homePageBg.jpg');
        background-position: bottom left;
        background-size:100%;
        background-repeat:no-repeat;
    }
    .content{
        margin: 1rem;
        flex: 1;
        display: flex;
        flex-direction: row;
        overflow: hidden;
    }
    .title{
        font-size: 0.8rem;
    }
    .leftBox{
        width: 23.5rem;
        background: #fff;
        position: relative;
        border: 1rem solid #fff;
        border-radius: 8px;
        overflow: auto;
        .leftContent{
            padding-top: 1rem;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            align-items: center;
            align-content: flex-start;
        }
        .leftContent > i {
            width: 10rem;
        }
        .departmentCard{
            width: 10rem;
            height: 4.8rem;
            position: relative;
            border-radius: 8px;
            background: #F0F5F8;
            box-shadow: none;
            position: relative;
            margin-bottom: 1rem;
        }
        .departmentCard:hover{
            background: #60B4F6;
            color: #fff;
            cursor: pointer;
            box-shadow: 0px 3px 10px 1px rgba(96,180,246,0.55);
            .departmentCardTitle{
                color: #fff
            }
        }
        .departmentCardTitle{
            text-align: center;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 80%;
            font-size: 0.8rem;
        }
        .departmentCardIcon{
            position: absolute;
            top: 0.2rem;
            left: 0.5rem;
            right: 0;
            height: 27px;
            line-height: 27px;
            .number{
                font-size: 13px;
                float: right;
                color: #6d6d6d;
            }
        }
        .anticon-idcard{
            float: left;
            margin-top: 4px;
        }
    }
    .righBox{
        flex: 1;
        margin-left: 1rem;
        border: 1rem solid #fff;
        border-radius: 8px;
        background: #fff;
        overflow: auto;
        .righContent{
            padding-top: 1rem;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            align-items: center;
            align-content: flex-start;
        }
        .righContent > i {
            width: 18rem;
        }
        .knowledgeCard{
            width: 17rem;
            height: 11.5rem;
            border-radius: 8px;
            background: #F0F5F8;
            box-shadow: none;
            padding: 1.2rem 1rem;
            margin-bottom: 1rem;
        }
        .knowledgeCard:hover{
            background: #60B4F6;
            color: #fff;
            cursor: pointer;
            box-shadow: 0px 3px 10px 1px rgba(96,180,246,0.55);
            .knowledgeCardTitle{
                color: #fff
            }
            .knowledgeCardDescription{
                color: #fff;
            }
            .ant-image-img{
                position: relative;
                left: -80px;
                filter: drop-shadow(#fff 80px 0);
            }
        }
        .knowledgeCardImage{
            text-align: center
        }
        .knowledgeCardTitle{
            font-size: 0.9rem;
            margin-bottom: 0.5rem;
            text-align: center;
        }
        .knowledgeCardDescription{
            font-size: 0.7rem;
            color: #666666;
            text-align: center;
            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp:2;
            overflow: hidden;
            white-space: pre-line;
        }
        .ant-image{
            margin-bottom: 1rem;
            margin-top: 1rem;
            overflow: hidden;
        }
    }
}

