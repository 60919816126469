.searchFrame {
    height: 100%;
    width: 100%;
    background-image: url('../../../../../assets/img/searchBg.png');
    background-position: left center;
    background-repeat: no-repeat;
    background-size: auto 110%;
    background-color: rgb(125 197 253);
    position: relative;
    .searchBar{
        display: flex;
        align-items: center;
        height: 2.3rem;
        background: #fff;
        border-radius: 8px;
        position: absolute;
        bottom: 90px;
        left: 42px;
        right: 60px;
        padding-right: 3px;
        padding-left: 0.5rem;

        .anticon-search{
            height: 1.3rem;
            margin: 0.95rem 1rem;
        }
        .ant-select{
            flex-shrink: 0;
            margin: 0;
            height: 2rem;
        }
        .ant-select-selector{
            padding: 0;
        }

        .ant-input{
            flex: 1;
            height: 2rem;
            margin: 0 0.5rem;
        }
        .ant-btn{
            flex-shrink: 0;
            border-radius: 8px;
            background: #60B4F6;
            border: 0;
        }
        
    }
    .homePageSearch{
        position: absolute !important;
        right: 20px;
        bottom: 98px;
        .icon-waixingren{
            font-size: 1.5rem;
            color: #fff;
            cursor: pointer;
        }
    }
    
}
