.uploadFileComponentBox {
  width: 100%;
  max-width: 40rem;
  min-width: 30rem;
  height: 60vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  user-select: none;
  background: #fff;
  padding: 3rem 4rem 1.5rem;
  border-radius: 0.4rem;
  box-shadow: 0 0.2rem 0.5rem 0 rgba(0, 0, 0, 0.3);
  position: relative;

  .headBox{
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    font-size: 0.8rem;
    .closeBtn{
      position: absolute;
      right: 0;
      top: 0;
      height: 16px;
      line-height: 16px;
    }
  }

  .uploadBtn {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .choiceFileBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.25rem 0.8rem;
      border: 0.05rem solid #DCDCDC;
      border-radius: 0.2rem;
      cursor: pointer;
      position: relative;
      font-size: 0.7rem;
      color: rgba(0,0,0,0.9);

      #fileInput {
        opacity: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }

      &.disabledChoiceFile {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    .tipText {
      font-size: 0.6rem;
      color: rgba(0, 0, 0, 0.4);
      margin-left: 1rem;
    }
  }

  .tableContentBox {
    flex: 1;
    width: 100%;
    padding: 0;
    margin-top: 1rem;
    position: relative;
    overflow: hidden;

    .dragFileBox {
      position: absolute;
      left: 0;
      width: 100%;
      top: 1.9rem;
      height: calc(100% - 1.9rem);
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0.05rem dashed #DCDCDC;

      .tipText {
        font-size: 0.7rem;
        color: rgba(0,0,0,0.4);
      }

      #fileInput2 {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        opacity: 0;
      }
    }

    .statusBox {
      display: flex;
      align-items: center;
      font-size: 0.7rem;
      color: rgba(0,0,0,0.9);
      line-height: 1rem;

      .iconfont {
        font-size: 0.8rem;
        margin-right: 0.1rem;
        color: rgba(0,0,0,0.4);

        &.icon-dui {
          font-size: 0.7rem;
          color: #2BA471;
        }

        &.icon-locationiconfail {
          font-size: 0.75rem;
          color: #D54941;
        }
      }

      .anticon {
        font-size: 0.6rem;
        color: #409EFF;
        margin-right: 0.1rem;
      }
    }

    .uploadOperateBox {
      color: rgba(0,0,0,0.4);

      .operateBtn {
        font-size: 0.7rem;
        cursor: pointer;

        &.deleteBtn {
          color: #ff3a36;
        }

        &.shareBtn {
          color: #409EFF;
        }
      }
    }
  }

  .footBox {
    flex-shrink: 0;
    height: 2rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 1.5rem;

    .operateBtn {
      padding: 0.25rem 0.8rem;
      font-size: 0.7rem;
      cursor: pointer;
      border-radius: 0.2rem;

      &.disabled {
        cursor: not-allowed;
        opacity: 0.3;
      }

      &.cancelBtn {
        background: #E7E7E7;
        color: rgba(0,0,0,0.9);
        .anticon-close-circle{
          margin-right: 5px;
        }
      }

      &.confirmBtn {
        background: #0052D9;
        color: #fff;
        .anticon-cloud-upload{
          margin-right: 5px;
        }
      }

      +.operateBtn {
        margin-left: 1rem;
      }
    }
  }
}
