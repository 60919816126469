.publicknowledgeModal{
    .ant-modal-content{
        border-radius: 8px;
        
    }
    .headerTitle{
        font-size: 18px;
        line-height: 34px;
        .anticon-info-circle{
            margin-right: 10px;
        }

        .addCarBtn{
            background: #60B4F6;
            border: 0;
            border-radius: 8px;
            position: absolute;
            right: 72px;
        }

        .fullScreenIcon{
            position: absolute;
            right: 60px;
            top: 8px;
            color: rgba(0, 0, 0, 0.45);
        }
    }
    .ant-modal-close-icon{
        font-size: 20px;
    }
}



.fullScreen{
    .ant-modal{
        width: 100% !important;
        height: 100%;
        max-width: calc(100vw);
        .ant-modal-content{
            width: 100%;
            height: 100%;
            border-radius: 0;
        }
        .ant-modal-body{
            height: 100%;
            display: flex;
            flex-direction: column;
            .content{
                flex: 1 !important;
            }
        }
    }
}