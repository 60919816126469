.aiCartModal{
    .ant-modal-content{
        border-radius: 8px;
        .ant-modal-close-x{
            width: 40px;
            height: 40px;
            line-height: 40px;
        }
    }
    .aiCartHeader{
        display: flex;
        .title{
            flex: 1;
            span{
                display: inline-block;
            }
            .titleIcon{
                font-weight: 600;
                .anticon-shopping-cart{
                    color: #60B4F6;
                    font-size: 20px;
                    margin-left: 2px;
                    margin-top: 1px;
                    float: right;
                }
            }
        }
        .ant-btn{
            border: 0;
            margin-right: 15px;
        }
        .buttonPrimary{
            background: #60B4F6;
        }
        
    }
    .aiCartSearch{
        margin-top: 1rem;
        display: flex;
        margin-bottom: 1rem;
        line-height: 32px;
        .searchInput{
            flex: 1;
            height: 32px;
            line-height: 32px;
            .ant-input{
                background: #F2F3F5;
                border: 0;
            }
            .ant-input-search-button{
                border: 0;
                background: #F2F3F5;
                height: 30px;
            }
            .inputTitle{
                color: #1D2129;
            }
            .ant-input-search{
                width: 300px;
            }
        }
        .startConversation{
            .anticon-file-add{
                color: #60B4F6;
                font-size: 18px;
            }
        }
    }

    
}