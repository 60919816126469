.myHomeWrap {
  // width: 52rem;/
  width: 100%;
  height: 100%;
  overflow: auto;
  // display: flex;
  // justify-content: space-between;
  // flex-direction: column;
  .dataOverview {
    margin-bottom: 0.5rem;
    box-sizing: border-box;
    padding: 0 1rem;
    width: 100%;
    height: 11rem;
    background: #fff;
    border-radius: 0.5rem;
    .dataShow {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 7.5rem;
      background: #f0f5f8;
      font-size: 0.7rem;
      border-radius: 0.5rem;
      padding: 0.5rem;
    }
  }
  .readView {
    margin-bottom: 0.5rem;
  }
  .readView,
  .upload {
    padding: 1rem 1.5rem 1.5rem 1.5rem;
    width: 100%;
    height: 20rem;
    background: #fff;
    border-radius: 0.5rem;
    .topTitle {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
      width: 100%;
      line-height: 0.5rem;
      font-size: 0.8rem;
      p{
        margin-bottom: 0
      }
      a {
        font-size: 0.8rem;
        color: #0099cc;
      }
    }
    .showView {
      width: 100%;
      height: 16rem;
      display: flex;
      justify-content: space-around;
    }
  }
}
