.myReadFrame {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .tabs {
    display: flex;
    justify-content: space-around;
    margin-bottom: 0.5rem;
    box-sizing: border-box;
    width: 100%;
    height: 2.8rem;
    background: #fff;
    border-radius: 0.5rem;
    .knowledgeTypeFrame{
      width: 95%;
    }
  }
  .content {
    flex: 1;
    box-sizing: border-box;
    padding: 0 1rem;
    width: 100%;
    background: #fff;
    border-radius: 0.5rem;
    .sort {
      width: 100%;
      height: 2.5rem;
      display: flex;
      font-size: 0.8rem;
      .readTimeSort,.progressSort {
        margin-right: 2rem;
        display: flex;
        align-items: center;
        .icon {
          font-size: 0.7rem;
          margin-left: 0.2rem;
          display: flex;
          flex-direction: column;
          span:nth-child(1) {
            margin-top: 0.15rem;
            margin-bottom: -0.15rem;
          }
          span:nth-child(2) {
            margin-top: -0.15rem;
          }
        }
      }
    }
    .readView {
      display: flex;
      justify-content: space-around;
      overflow-y: scroll;
      .null{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
      }
    }
    .pager {
      display: flex;
      justify-content: flex-end;
      height: 2.5rem;
    }
  }
}
