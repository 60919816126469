.modalTableFrame {
  width: 100%;
  height: 100%;
  // .editable-row .ant-form-item-explain {
  //   position: absolute;
  //   top: 100%;
  //   font-size: 0.75rem;
  // }
  table{
    font-size: 0.65rem;
    text-align: center;
    .ant-table-thead th{
      font-weight: 600;
      text-align: center;
    }
  }
}
