.cloudSpaceFrame{
    position: relative;
    height: 100%;
    overflow: hidden;

    .cloudSpaceTabs {
        height: 100%;
        overflow: hidden;

        .ant-tabs {
            display: flex;
            flex-direction: column;
            height: 100%;
            overflow: hidden;

            .ant-tabs-nav {
                flex-shrink: 0;
                padding: 0 1rem;
                background: #fff;
                margin: 0 0 0.5rem 0 !important;

                .ant-tabs-tab-btn{
                    font-size: 0.9rem;
                }
            }

            .ant-tabs-content-holder {
                flex: 1;
                overflow: hidden;

                .ant-tabs-content {
                    height: 100%;
                    overflow: hidden;

                    .ant-tabs-tabpane {
                        height: 100%;
                        overflow: hidden;
                    }
                }
            }
        }
    }
}
