$halfPadding: 1.5rem;

.mindMapFrame {
  position: relative;

  + .mindMapFrame {
    margin-top: 1.9rem;
  }

  .mindMapLine {
    position: absolute;
    width: $halfPadding;
    border: 2px solid #1182e8;
  }

  .topLine {
    left: -($halfPadding * 2);
    border-left: none;
    box-sizing: content-box;
  }

  .bottomLine {
    left: -$halfPadding;
    border-right: none;
    box-sizing: border-box;
  }

  &.topMindMap {
    > .topLine {
      border-top: none;
      top: calc(50% - 1px);
    }

    > .bottomLine {
      border-bottom: none;
      top: calc(50% - 1px);
    }
  }

  &.bottomMindMap {
    > .topLine {
      border-bottom: none;
      bottom: calc(50% - 1px);
    }

    > .bottomLine {
      border-top: none;
      bottom: calc(50% - 1px);
    }
  }
}
