.overseasCaseConfigFrame{
    background: #fff;
    height: 100%;
    position: relative;
    line-height: 1rem;

    .title{
        padding: 1rem;
        font-size: 1.2rem;
    }
    .manageList{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        align-content: flex-start;
        padding: 0.5rem;
    }
    .manageCard{
        width: 13rem;
        height: 13rem;
        background: rgba(244, 248, 250, 1);
        border-radius: 10px;
        border: 0;
        cursor: pointer;
        margin: 0 0.5rem 1rem;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        padding: 1rem;

        .manageCardImage{
            text-align: center;
            margin: 2rem 0;

            .iconfont {
                font-size: 3.5rem;
            }
        }

        .manageCardTitle{
            font-size: 0.9rem;
            text-align: center;
            margin-bottom: 0.5rem;
        }

        .manageCardDescription {
            text-align: center;
            font-size: 0.6rem;
            color: #898989;
            height: 2rem;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2; /* 设置为想要的行数 */
            overflow: hidden;
            text-overflow: ellipsis;

            > span {
                display: inline-block;
                text-align: left;
            }
        }
    }
}
