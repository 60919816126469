.layoutTopLogoSingle {
    width: 54px;
    margin-left: 1.8rem;
    margin-top: 0.7rem;
    float: left;
    overflow: visible;
    display: flex;
    align-items: center;
    img{
      width: 100%;
    }
  }