.manageFrame {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: #fff;
    cursor: default;
    user-select: none;
    overflow: hidden;
    line-height: 1rem;

    .manageHeadBox {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        overflow: hidden;
        padding: 0 1rem;
        border-bottom: 0.05rem solid #efefef;

        .menuSortListBox {
            flex: 1;
            display: flex;
            align-items: center;
            height: 3rem;
            overflow: hidden;

            .menuSortItem {
                font-size: 0.8rem;
                color: #7e7e7e;
                cursor: pointer;
                position: relative;
                height: 3rem;
                line-height: 3rem;

                &.activeStatus {
                    color: #1A9DF9;

                    &:after {
                        content: "";
                        width: 100%;
                        height: 0.1rem;
                        background: #1A9DF9;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                    }
                }

                +.menuSortItem {
                    margin-left: 2rem;
                }
            }
        }

        .departmentName {
            flex-shrink: 0;
            margin-left: 1rem;
            font-size: 1rem;
        }
    }

    .menuListBox {
        flex: 1;
        padding: 1rem;
        overflow: hidden;

        .scrollBox {
            height: 100%;
            overflow: auto;
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;
            align-items: flex-start;
            align-content: flex-start;
            margin: 0 -0.5rem;

            .manageCard{
                width: 13rem;
                height: 13rem;
                background: rgba(244, 248, 250, 1);
                border-radius: 10px;
                border: 0;
                cursor: pointer;
                margin: 0 0.5rem 1rem;
                display: flex;
                flex-direction: column;
                align-content: center;
                justify-content: center;
                padding: 1rem;

                .manageCardImage{
                    text-align: center;
                    margin: 2rem 0;

                    .iconfont {
                        font-size: 3.5rem;
                    }
                }

                .manageCardTitle{
                    font-size: 0.9rem;
                    text-align: center;
                    margin-bottom: 0.5rem;
                }

                .manageCardDescription {
                    text-align: center;
                    font-size: 0.6rem;
                    color: #898989;
                    height: 2rem;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2; /* 设置为想要的行数 */
                    overflow: hidden;
                    text-overflow: ellipsis;

                    > span {
                        display: inline-block;
                        text-align: left;
                    }
                }
            }
        }
    }


    .icon-shenheliebiao{
        color: #9D5B06;
    }
    .icon-gearmore-full{
        color: #004693;
    }
    .icon-bijibendiannao{
        color:#540790;
    }
    .icon-tongji{
        color:#90076A;
    }
    .icon-yonghuguanli{
        color:#E6A706;
    }
    .icon-shuyi_zhengf
    u{
        color:#025098;
    }
    .icon-ditu{
        color:#2280F1;
    }
    .icon-rizhi{
        color: #319D1A;
    }
    .icon-xitong-caidanguanli{
        color: #d4237a;
    }
    .icon-baifenbi{
        color: #E6A706;
    }
    .icon-shezhi{
        color: #797979;
    }
    .icon-xiangmu{
        color: #2d5184;
    }
    .icon-zhishi2{
        color: #137979;
    }
    .icon-recikuguanli{
        color: #E2852D;
    }
    .icon-tongbu{
        color: #367a00;
    }
    .icon-ic_PC_IM_Burn{
        color: #FB5146;
    }
    .icon-flow-banner{
        color: #46fbc5;
    }
    .icon-biaoqian1{
        color: #b2ad12;
    }
}
