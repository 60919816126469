.knowledgeSystemDetailFrame {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  cursor: default;
  background-color: #f0f2f5;
  user-select: none;

  .headerBox {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    padding: 0.7rem;
    border-radius: 0.4rem;
    margin-bottom: 1rem;
    position: relative;
    .countdown{
      position: absolute;
      right: 1rem;
      top: 0.5rem;
      color: #1890ff;
      opacity:0;
      span{
          padding-right: 10px
      }
    }
    .backBtn {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      line-height: 1;
      color: #1A9DF9;
      cursor: pointer;

      .iconfont {
        font-size: 1.2rem;
      }
    }

    .systemNameBox {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 1rem;

      .systemName {
        display: inline-block;
        text-align: left;
        font-size: 0.9rem;
        color: #333;
      }
    }

    .kongChar {
      flex: 0;
      width: 1.2rem;
    }
  }

  .contentBox {
    flex: 1;
    background-color: #fff;
    padding: 1rem;
    border-radius: 0.4rem;

    .systemDesignBox {
      position: relative;
      height: 100%;
      border-radius: 0.4rem;
      background-color: #f0f2f5;
      overflow: hidden;

      .ant-modal-root {
        .selfDesignModal, .ant-modal-mask {
          position: absolute;

          .ant-modal-header {
            .titleBox {
              margin: 0;
            }
          }

          .ant-modal-body {
            padding: 0;

            .modalContentBox {
              padding: 1rem;
            }
          }
        }

        .colorFillBox {
          padding: 0;
          background: none;
          width: 100%;

          > div {
            width: 100%!important;
          }
        }
      }

      .treeRootBox {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 100%;
        min-width: 100%;
        position: absolute;
        left: 0;
        top: 0;

        .treeNodeBox {
          display: flex;
          align-items: center;

          .treeNode {
            display: flex;
            align-items: center;
            border-radius: 0.2rem;
            //overflow: hidden;
            background: #fff;
            padding: 0.2rem 0.5rem;
            min-height: 1.6rem;
            user-select: none;
            position: relative;

            .treeNodeName {
              display: block;
              width: 5.5rem;
              text-align: center;
              font-size: 0.6rem;
              color: #333;
              // line-height: 1.85rem;
            }

            &.selectedNode {
              box-shadow: 0 0 0 0.2rem #1A9DF9;
              z-index: 10;
            }
          }

          .treeChildNodeBox {
            display: flex;
            flex-direction: column;
            margin-left: 3rem;
          }
        }
      }

      .reductionPositionBtn {
        position: absolute;
        line-height: 1;
        top: 1rem;
        right: 1rem;
        cursor: pointer;

        .iconfont {
          font-size: 1.3rem;
          color: #b5b5b5;
        }
      }
    }
  }
}