.knowledgeSystemFrame {
  display: flex;
  flex-flow: column;
  height: 100%;
  background-color: #fff;
  user-select: none;
  cursor: default;
  padding: 1rem;

  .contentBox {
    flex: 1;
    overflow: auto;
    margin-right: -0.5rem;
    padding-right: 0.5rem;
    position: relative;

    .sortItem {
      background-color: #f8f8f8;
      border-radius: 0.4rem;

      +.sortItem {
        margin-top: 1rem;
      }

      .infoAndOperateBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 2.5rem;
        padding: 0 1rem;
        font-size: 0.8rem;
        color: #333;
        cursor: pointer;
        overflow: hidden;

        .sortName {
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .expandSortBtn {
          flex-shrink: 0;
          line-height: 1;
          margin-left: 1rem;
          transition: transform 0.1s linear;

          .iconfont {
            font-size: 0.9rem;
            color: #888686;
          }

          &.expandStatus {
            transform: rotate(90deg);
          }
        }
      }

      .systemListBox {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        padding: 0 0.5rem;
        overflow: hidden;
        transition: max-height 0.1s linear;

        .systemItem {
          display: flex;
          flex-direction: column;
          width: 11rem;
          height: 17.5rem;
          padding: 0.5rem;
          margin: 1rem;
          position: relative;
          background-color: #fff;
          box-shadow: 0 0.1rem 0.5rem 0.05rem rgba(109,141,159,0.47);
          border-radius: 0.4rem;
          overflow: hidden;
          cursor: pointer;

          .systemImg {
            flex: 1;
            overflow: hidden;
            border-radius: 0.2rem;
            background-color: #f0f0f0;
            display: flex;
            align-items: center;

            > img {
              display: block;
              width: 100%;
              //margin-top: 50%;
              &:not([src]) {
                opacity: 0;
              }
            }
          }

          .systemName {
            flex-shrink: 0;
            height: 1.6rem;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            margin-top: 0.4rem;

            > span {
              text-align: justify;
              font-size: 0.6rem;
              color: #333;
              line-height: 0.8rem;
              display: -webkit-box; /* 使用flex布局 */
              -webkit-box-orient: vertical; /* 纵向排列 */
              -webkit-line-clamp: 2; /* 最多展示两行文字 */
              overflow: hidden; /* 隐藏超出部分 */
            }
          }

          &:hover {
            box-shadow:  0 0.1rem 0.5rem 0.05rem rgba(14, 108, 227, 0.47);
          }
        }
      }
    }

    .noneDataBox {
      height: 100%;
      flex-direction: column;
      color: #d5d5d5;

      .iconfont {
        margin: 0 0 0.5rem 0;
      }

      > span {
        font-size: 0.8rem;
      }
    }
  }

  .ant-spin {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 8rem;

    .ant-spin-text {
      height: 1rem;
      text-align: center;
      font-size: 0.7rem;
    }
  }
}