.topSearchBar{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 80%;
    max-width: 52rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .KnowledgeTitle{
        width: 22.5rem;
        margin-bottom: 1.2rem;
        img{
            width: 100%;
        }
    }
    .KnowledgeTitleIndex{
        width: 22.5rem;
        margin-left: 50%;
        transform: translateX(-50%);
        margin-bottom: 3.3rem;
        img{
            width: 100%;
        }
    }
    
    .searchBox{
        display: flex;
        display: flex;
        width: 100%;
        
        .searchBar{
            display: flex;
            align-items: center;
            width: 100%;
            height: 2.6rem;
            background: #fff;
            border-radius: 8px;
            position: relative;
            padding: 0 0.5rem;
    
            .anticon-search{
                height: 1.3rem;
                margin: 0.95rem 1rem;
            }
            .ant-select{
                flex-shrink: 0;
                margin: 0;
                height: 2rem;
            }
            .ant-select-selector{
                padding: 0;
            }
    
            .ant-input{
                flex: 1;
                height: 2rem;
                margin: 0 0.5rem;
            }
            .ant-btn{
                flex-shrink: 0;
                border-radius: 8px;
                background: #60B4F6;
                border: 0;
            }
        }
        
    
        .AiBtnBox {
            margin-left: 1rem;
            .ant-btn{
                border-radius: 8px;
                background: #60B4F6;
                border: 0;
                height: 2.6rem;
            }
            .icon-waixingren {
                vertical-align: initial !important;
                line-height: initial !important;
                font-size: 22px;
            }
        }
    }
}
