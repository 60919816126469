.initDataFrame{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 1rem;
    background: white;
    border-radius: 0.4rem;
    overflow: hidden;
    user-select: none;

    .initDataContentBox {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        overflow: hidden;
        margin-bottom: 0.5rem;
        margin-top: -0.2rem;

        .initItemBox {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .labelText {
                font-size: 0.7rem;
                color: #8f8f8f;
            }

            .infoText {
                font-size: 0.7rem;
                color: #333;
            }

            .ant-input-number-group-wrapper {
                flex: 1;
            }

            .operateBtn {
                cursor: pointer;
                color: #409EFF;
            }

            +.initItemBox {
                margin-left: 2rem;
            }
        }
    }

    .tableContentBox {
        flex: 1;
        overflow: hidden;

        .operateBox {
            .operateBtn {
                cursor: pointer;
                color: #409EFF;

                &.resetBtn {
                    color: #ff3a36;

                    .iconfont {
                        font-size: 0.7rem;
                    }
                }

                +.operateBtn {
                    margin-left: 0.5rem;
                }
            }
        }
    }
}

.editInitSpaceModalBox{
    .ant-modal-body{
        .ant-btn-default{
            width: 100%;
            text-align: left
        }
    }
}
