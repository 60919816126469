.knowledgeTypeTabFrame {
  width: 95%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .ant-empty,
  .ant-empty-normal {
    margin: 0;
    font-size: 0.6rem;
  }
  .ant-tabs,
  .ant-tabs-top {
    width: 83%;
  }
  .ant-tabs-nav {
    margin: 0 !important;
  }
  .ant-tabs-ink-bar {
    background: #fff;
  }
  .ant-tabs-tab {
    font-size: 0.8rem;
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    border: 0;
  }
  .searchIcon {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.4rem;
    color: #1890ff;
    cursor: pointer;
  }
  // .searchInput{
  //   width: 10rem;
  // }
  .AiSearchBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 0.7;
    margin-right: 1rem;
    width: 100px;

    .iconfont {
        margin-right: 0.3rem;
        font-size: 0.9rem;
        color: #1890ff;
    }

    span {
        font-size: 0.7rem;
        color: #4E5969;
    }
}
}
