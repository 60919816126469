.knowledgeLineFrame{
  width: 100%;
  height: 100%;
  .item {
    background: #F0F5F8;
    border-radius: 0.4rem;
    padding: 0.5rem;

    .firstLine{
      display: flex;
      font-size: 0.8rem;
      color: #333333;
      .fileIcon{
        float: left;
        margin-right: 0.5rem;
      }
      .title{
        flex: 1;
        display: flex;
        .AiBtnBox {
          flex-shrink: 0;
          margin-left: 0.5rem;
          margin-top: 2px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 4.2rem;
          height: 1rem;
          border-radius: 0.5rem;
          border: 0.05rem solid #1890ff;
          cursor: pointer;
          opacity: 0.7;
          overflow: hidden;

          .AiBtn {
              flex-shrink: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 1.5rem;
              height: 100%;
              background: #409EFF;
              color: #fff;
              position: relative;

              .icon-waixingren {
                  font-size: 0.7rem;
              }

              .icon-jiahao {
                  font-size: 1rem;
                  zoom: 0.3;
                  position: absolute!important;
                  bottom: 0.4rem;
                  left: 3.2rem;
              }
          }
          .shoppingCartBtnAvailable{
              background: #1890ff;
              border: 0.05rem solid #1890ff;
          }
          .shoppingCartBtnNotAvailable{
              background: #409EFF;
              border: 0.05rem solid #409EFF;
              cursor: no-drop;
          }
          .AiReadBtn {
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 0.5rem;
              color: #3D3D3D;
          }
        }
      }
      .itemRightBox{
        float: right;
        color: #aaa;
        font-size: .7rem;
        line-height: 1.2rem;
        margin-left: 5px;
      }
    }
    .pointer{
      cursor: pointer;
    }
    .secondLine{
      display: flex;
      padding-left: 2rem;
      margin-top: 5px;
      .itemLeftBox{
        display: flex;
        align-items: center;
        flex: 1;
        color: #666666;
        font-size: 0.6rem;
        >div{
          margin-right: 10px
        }
      }
      .itemRightBox{
        float: right;
        color: #aaa;
        font-size: 0.6rem;
        .ant-progress {
          width: 7.5rem;
          float: right;
        }
        .operate3{
          display: flex;
          justify-content: space-between;
          margin-right: 1rem;
          width: 9rem;
          .span{
            display: flex;
            align-items: center;
          }
          .iconfont{
            margin-right: 0.2rem;
          }
          .state2{
            color: rgb(198, 4, 4);
            cursor: pointer;
          }

          .ant-popover-placement-leftTop {
            .ant-popover-content {
              top: -0.75rem;
              right: -0.25rem;
            }
          }
          .ant-popover-placement-topRight {
            .ant-popover-content {
              top: 0.4rem;
            }
          }
          .ant-popover-placement-left {
            .ant-popover-content {
              right: -0.4rem;
            }
          }
        }
        .operate{
          height: 100%;
          display: flex;
          justify-content: space-evenly;
          width: 9rem;
          div{
            display: flex;
            align-items: center;
            .iconfont{
              margin-right: 0.2rem;
              margin-top: 0.2rem;
            }
          }
        }
        .wordinfo {
          width: 40rem;
          display: flex;
          align-items: center;
          >div{
            margin-right: .7rem;
          }
          .value {
            color: #0099cc;
          }

        }
        .star{
          display: flex;
          align-items: center;
          span{
            margin-right: 0.3rem;
          }
        }
        .state1{
          width: 4rem;
          color: #666;
          cursor: pointer;
        }
      }
    }

    +.item {
      margin-top: 0.5rem;
    }

    .childrenlistBox{
      background: #fff;
      border-radius: 8px;
      padding: 0.8rem 1.5rem;
      margin-top: 0.7rem;
      .childrenlistItemHeader{
          font-size: 0.6rem;
          color: #666666;
          display: flex;
          margin-bottom: 0.5rem;
          cursor: pointer;
          .anticon {
              margin-right: 0.5rem;
          }
          .iconfont {
              margin-right: 0.5rem;
              top: 0.05rem;
          }
          .listTitle{
              flex: 1;
              font-size: 0.8rem;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
          }
      }
      .childrenlistItemHeader:last-child{
          margin-bottom: 0
      }
    }
  }
}
.item {
  dl {
    box-sizing: border-box;
    margin-bottom: 0.2rem;
    padding-right: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    height: 3.5rem;
    font-size: 0.8rem;
    color: #aaaaaa;
    // .anticon-file-excel{
    //   font-size: 1rem;
    //   color: #107C41;
    //   }
    .anticon{
      margin-right: 0.2rem;
    }
    .pointer{
      cursor: pointer;
    }
    dt {
      display: flex;
      justify-content: space-between;
      .title {
        // width: 20rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #666;
      }
      // .time{
      //   width: 13rem;
      // }
    }
    dd {
      display: flex;
      justify-content: space-between;
      font-size: 0.65rem;

      .wordinfo {
        width: 40rem;
        display: flex;
        align-items: center;
        >div{
          margin-right: .7rem;
        }
        .value {
          color: #0099cc;
        }

      }
      .star{
        display: flex;
        align-items: center;
        span{
          margin-right: 0.3rem;
        }
      }

      .state1{
        width: 4rem;
        color: #666;
        cursor: pointer;
      }
      .operate3{
        display: flex;
        justify-content: space-between;
        margin-right: 1rem;
        width: 9rem;
        .span{
          display: flex;
          align-items: center;
        }
        .iconfont{
          margin-right: 0.2rem;
        }
        .state2{
          color: rgb(198, 4, 4);
          cursor: pointer;
        }
      }
      .operate{
        height: 100%;
        display: flex;
        justify-content: space-evenly;
        width: 9rem;
        div{
          display: flex;
          align-items: center;
          .iconfont{
            margin-right: 0.2rem;
            margin-top: 0.2rem;
          }
        }
      }
    }
  }
  dl:hover {
    background: #eee;
  }
}

.publishPopConfirmContentBox {
  .tipText {
    display: block;
    line-height: 1rem;
    font-size: 0.7rem;
  }

  .operateBox {
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .labelText {
      flex-shrink: 0;
      font-size: 0.6rem;
      color: #666;
    }

    .ant-switch {
      box-shadow: none;

      .ant-click-animating-node {
        animation: none;
      }
    }
  }
}
