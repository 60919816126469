.labelBox{
    
    .labelOpertion{

        .labelSwitch{
            float: right;
            color: #999999;
            font-size: 0.6rem;
        }
        .ant-switch{
            background: #ffffff;
            margin-right: 5px;
            float: left;
            margin-top: 2px;
        }
        .ant-switch-handle::before{
            background: #B8CDD9;
        }
        .ant-switch-checked{
            // background: #F0F5F8;
            .ant-switch-handle::before{
                background: #FF3320;
            }
        }
        .ant-switch-checked .ant-switch-loading-icon{
            color: #ffffff
        }
    }
    .labelContent{
        
        .ant-tag{
            border: 0;
            padding: 4px 10px;
            background: #F0F5F8;
            color: #333333;
        }
    }
}