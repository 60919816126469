.errorPageFrame {
    display: flex;
    width: 100%;
    height: 100%;
    background: #fff;
    overflow: hidden;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    .imgBox {
        display: block;
        width: auto;
        height: 28vh;

        img {
            display: block;
            height: 100%;
            width: auto;
        }
    }

    .tipText {
        text-align: center;
        font-size: 1.2rem;
        cursor: default;
        color: #357DB0;
        margin-top: 1.4rem;
    }

    .backBtn {
        display: flex;
        width: 14rem;
        height: 1.8rem;
        justify-content: center;
        align-items: center;
        background-color: #60B4F6;
        cursor: pointer;
        box-shadow: 0 0.1rem 0.45rem 0 rgba(96,180,246,0.65);
        border-radius: 0.4rem;
        font-size: 0.8rem;
        color: #fff;
        margin-top: 1.6rem;
    }


}
