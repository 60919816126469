.departmentTreeFrame {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    margin-right: -1rem;
    padding-right: 1rem;


    .classificationList{
        margin-bottom: 0.5rem;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: 1rem;

        .titleText {
            flex-shrink: 0;
            display: block;
            font-size: 0.9rem;
            color: #333;
            line-height: 0;
        }

        .operateBtnBox {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .operateBtn {
                display: block;
                line-height: 0;
                cursor: pointer;

                .iconfont {
                    color: #b8b8b8;
                    font-size: 0.9rem;

                    &.icon-paixu {
                        font-size: 0.75rem;
                    }
                }

                &:hover {
                    .iconfont {
                        color: #1A9DF9;
                    }
                }

                &.activeStatus {
                    .iconfont {
                        color: #1A9DF9;
                    }
                }

                +.operateBtn {
                    margin-left: 0.5rem;
                }
            }
        }
    }

    .treeContentBox {
        flex: 1;
        margin-right: -0.5rem;
        padding-right: 0.5rem;
        overflow: auto;
        position: relative;

        > div {
            position: relative;
            z-index: 1;

            .activeNodeStatus {
                position: relative;

                &:before {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    border: 0.1rem solid #1A9DF9;
                    border-radius: 0.2rem;

                }
            }
        }

        .menuRootNode {
            position: absolute;
            z-index: 0;
        }

        .ant-tree-node-content-wrapper {
            display: flex;

            .ant-tree-iconEle {
                flex-shrink: 0;
                width: 0.7rem;
                margin-right: 0.4rem;

                .anticon {
                    font-size: unset;

                    &.anticon-file-text {
                        color: #8f9da8;
                    }
                }
            }
        }
    }
    .iconfont{
        cursor: pointer;
    }
}

.menuToolTipBox {
    .ant-tooltip-content {
        background-color: #fff;
        border-radius: 0.4rem;
        padding: 0.5rem 0;
        //width: 7rem;
        box-shadow: 0 0.3rem 0.8rem 0.05rem rgba(0,0,0,0.25);
        user-select: none;
        cursor: default;

        .ant-tooltip-arrow {
            .ant-tooltip-arrow-content {
                --antd-arrow-background-color: #fff;
            }
        }

        .ant-tooltip-inner {
            background: #fff;
            box-shadow: none;
            padding: 0;

            .menuBox {
                max-height: 15rem;
                overflow: auto;

                .menuItem {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    color: #333;
                    padding: 0.2rem 0.5rem;

                    .iconfont {
                        font-size: 0.8rem;
                        flex-shrink: 0;
                        margin-right: 0.3rem;

                        &.icon-add {
                            font-size: 0.85rem;
                        }
                    }

                    .menuItemName {
                        font-size: 0.6rem;
                        line-height: 1;
                    }

                    +.menuItem {
                        margin-top: 0.2rem;
                    }

                    &:hover {
                        background-color: #1A9DF9;
                        color: #fff;
                    }
                }
            }
        }
    }
}
