.documentOperations{
    .operateBox{
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        padding: 1rem;
        background: #fff;
        border-radius: 8px;
        box-shadow: 0 0 10px #000000;
        .operateBoxIcon{
            width: 20px;
            margin-right: 5px;
            float: left;
            margin-top: 3px;
        }
    }

    .operateRightBox{
        position: absolute;
        right: 11px;
        top: 60px;
        z-index: 10;
        .operateButton{
            width: 2rem;
            height: 2rem;
            line-height: 1.8rem;
            text-align: center;
            cursor: pointer;
            border-radius: 50%;
            color: #1A9DF9;
            background-color: #fff;
            box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
            margin-bottom: 1rem;
        }
    }

    .operateBoxPPT{
        position: absolute;
        right: 11px;
        // top: 130px;
        .operateButton{
            width: 2rem;
            height: 2rem;
            line-height: 1.8rem;
            text-align: center;
            cursor: pointer;
            border-radius: 50%;
            color: #1A9DF9;
            background-color: #fff;
            box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
            margin-bottom: 1rem;
        }
    }
}

.translateModalClass{
    .translateSwitch{
        margin-bottom: 1rem;
        .anticon{
            margin: 0 1rem;
            font-size: 0.6rem;
            color: #767676;
            //cursor: pointer;
        }
        .text{
            padding: 2px 10px;
            border: 1px solid #414141;
            color: #414141;
        }
    }

    .translateContentBox {
        .copyBtn {
            cursor: pointer;
            color: #1A9DF9;
            margin-left: 0.2rem;
        }
    }
}


.addNoteModalWrapBox {
    .ant-modal {
        pointer-events: unset;
        transform-origin: unset!important;
    }
    .addNoteModal {
        background: #fff;
        padding: 1rem;
        border-radius: 0.4rem;
        position: fixed;
        width: 25rem;
        // max-height: 20rem;
        z-index: 10000;
        box-shadow: 0 0.15rem 0.45rem 0 rgba(0,0,0,0.45);
        user-select: none;
        cursor: default;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        > div {
            flex-shrink: 0;
        }

        .modalHeader {
            cursor: move;
            padding: 1rem;
            margin: -1rem -1rem 1rem;
            box-shadow: 0 0.05rem 0 0 #ececec;
        }

        .modalBody {
            flex: 1;
            overflow: auto;
        }

        .modalFooter {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-top: 1rem;
            box-shadow: 0 -0.05rem 0 0 #ececec;

            .operateBtn {
                cursor: pointer;
                padding: 0.3rem 0.8rem;
                background: #c3c3c3;
                font-size: 0.7rem;
                color: #333;
                border-radius: 0.2rem;
                line-height: 1.2;

                +.operateBtn {
                    margin-left: 1rem;
                }

                &.confirmBtn {
                    background: #1A9DF9;
                    color: #fff;
                }
            }
        }
    }
}

