.uploadDrawerFrame {
  width: 100%;
//   .uploadDrawerContent {
//     width: 100%;
//     .ant-drawer-body {
//       padding: 0;
//       display: flex;
//       background: #f5f5f5;
//       .baseInfo {
//         width: 17rem;
//         margin-right: 0.5rem;
//         background: #fff;
//         padding: 1.5rem 0 0 1.5rem;
//       }
//       .custom-mount {
//         flex: 1;
//         background: #fff;
//         padding: 1.5rem 0 0 1.5rem;
//       }
//     }
//   }
}
.policyDigest{
  .ant-input-affix-wrapper.ant-input-affix-wrapper-textarea-with-clear-btn .ant-input-clear-icon{
    right: 3px;
  }
}

.uploadDrawerBox{
  .baseInfo{
    .showItem{
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      font-variant: tabular-nums;
      line-height: 1.5715;
      list-style: none;
      font-feature-settings: "tnum";
      margin-bottom: 24px;
      vertical-align: top;
      display: flex;
      .title{
        display: inline-block;
        width: 33.33333333%;
        text-align: right;
      }
      .content{
        flex: 1;
      }
    }
  }
}

