.pointsInquiryFrame {
  display: flex;
  // justify-content: space-around;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #fff;
  .numall {
    margin-bottom: 1rem;
    box-sizing: border-box;
    padding-left: 1.5rem;
    width: 100%;
    height: 11rem;
    background: #fff;
    .h3 {
      line-height: 2.5rem;
    }
    .dataShow {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 98%;
      height: 7rem;
      font-size: 0.7rem;
      background: #f0f5f8;
      border-radius: 0.5rem;
      // .numBox {
      //   box-sizing: border-box;
      //   padding-top: 2rem;
      //   width: 18%;
      //   height: 100%;
      //   background: #f4f8fa;
      //   border-radius: 0.7rem;
      //   display: flex;
      //   align-items: center;
      //   flex-direction: column;
      //   color: #868686;
      //   p {
      //     line-height: 1.2rem;
      //     .messageNumber {
      //       font-size: 1.5rem;
      //       color: #000;
      //       margin-right: 0.3rem;
      //     }
      //   }
      // }
    }
  }
  .pointsHistory {
    flex: 1;
    width: 95%;
    .scoreChangeValue {
      .add {
        color: rgb(82, 239, 68);
      }
      .reduce {
        color: red;
      }
    }
    .pointsHistoryTitle {
      height: 3rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .pickDate {
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
  }
}
