.viewModelFrame {
  padding: 1rem;
  background: #F4F8FA;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-flow: column;

  .baseInfoBox {
    background-color: #fff;
    padding: 1rem;
    border-radius: 0.2rem;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 0.2rem 0.65rem 0.05rem rgba(148,169,191,0.32);

    .leftBox {
      flex: 1 1 100%;
      overflow: hidden;

      .infoItem {
        display: flex;
        justify-content: center;
        color: #333;
        cursor: default;
        overflow: hidden;
        line-height: 1;

        .infoItemLabel {
          font-size: 0.7rem;
          flex-shrink: 0;
        }

        .infoItemText {
          font-weight: bold;
          font-size: 0.7rem;
          flex: 1;
          white-space: pre-wrap;
          word-break: break-all;
          text-align: justify;
        }

        +.infoItem {
          margin-top: 0.5rem;
        }
      }

      .labelValueListBox {
        display: flex;
        flex-flow: row wrap;
        align-content: flex-start;
        margin: 0 -0.25rem;

        &.modelLabelView {
          flex: 1 1 100%;
          margin: -0.5rem -0.25rem 0;
        }

        .labelValueItem {
          display: block;
          height: 1.4rem;
          line-height: 1.4rem;
          padding: 0 0.5rem;
          background: #F4F8FA;
          border-radius: 0.2rem;
          font-size: 0.7rem;
          color: #333;
          cursor: default;
          margin: 0.5rem 0.25rem 0;
        }
      }
    }

    .operateBox {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      margin-left: 1rem;
      color: #3DADEE;

      > span {
        //&:hover {
        //  color: #3DADEE;
        //}

        + span {
          margin-left: 1.5rem;
        }
        .iconfont {
          font-size: 1.3rem;

          &.icon-combination {
            font-size: 1.5rem;
          }
        }
        cursor: pointer;
      }
    }
  }

  .addModelContentBox {
    display: flex;
    flex-flow: column;
    flex: 1 1 100%;
    margin-top: 1rem;
    overflow: hidden;
    position: relative;
    background-color: #fff;
    box-shadow: 0 0.2rem 0.65rem 0.05rem rgba(148,169,191,0.32);
    border-radius: 0.2rem;

    .navListBox {
      display: flex;
      flex-flow: row nowrap;
      flex-shrink: 0;
      align-items: center;
      height: 2.8rem;
      width: 100%;
      border-bottom: 1px solid #D9E6EC;
      user-select: none;

      .navItem {
        display: flex;
        flex: 0 1 7rem;
        justify-content: center;
        align-items: center;
        padding: 0 0.65rem;
        //max-width: 7rem;
        height: 2.8rem;
        font-size: 0.8rem;
        color: #333;
        line-height: 1;
        cursor: pointer;
        position: relative;
        overflow-x: hidden;
        overflow-y: visible;

        &:after {
          display: none;
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 0.1rem;
          background: #60B4F6;
          box-shadow: 0 0.1rem 0.2rem 0.05rem rgba(96,180,246,0.65);
          border-radius: 0.05rem;
        }

        &.activeNav, &:hover {
          color: #0099FF;

          &:after {
            display: block;
          }
        }

        +.navItem {
          margin-left: 2rem;
        }

        .text {
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .count {
          flex-shrink: 0;
        }
      }
    }

    .addModelContent {
      //margin: 0 -1rem;
      //padding: 0 1rem 1rem;
      position: relative;
      flex: 1 1 100%;
      overflow-y: auto;

      .addModelItem {
        padding: 1rem;
        //border-radius: 0.2rem;

        + .addModelItem {
          //margin-top: 1rem;
        }

        .stageNameBox {
          display: flex;
          width: 100%;
          align-items: center;
          color: #333;
          //font-weight: bold;
          position: relative;
          padding-right: 4rem;

          .stageNameLabel {
            flex-shrink: 0;
            margin-right: 0.5rem;
            display: flex;

            .iconfont {
              font-size: 0.8rem;
            }
          }

          .stageNameText {
            font-size: 0.8rem;
            color: #333;
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: default;
            line-height: 1;
          }

          .zhankaiIcon {
            position: absolute;
            right: 1rem;
            top: 0;
            display: flex;
            align-items: center;
            transition: all 0.3s;
            cursor: pointer;
            color: #d5d1cf;

            &.rotateIcon {
              transform: rotate(90deg);
              transition: all 0.3s;
            }

            .iconfont {
              font-size: 1rem;
            }
          }
        }

        .secondLevelBox {
          margin-top: 1.8rem;
          //overflow: auto;
          margin-right: -0.5rem;
          padding-right: 0.5rem;
          display: flex;
          flex-flow: row wrap;

          .secondLevelNode {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            width: 100%;

            &.activeNode {
              padding: 0.5rem;
            }

            +.secondLevelNode {
              margin-top: 2rem;
            }

            .secondLevelItem {
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0.6rem 0.75rem;
              cursor: default;
              background-color: #60B4F6;
              border-radius: 0.2rem;
              width: 7.2rem;
              //height: 2.4rem;
              overflow: hidden;
              flex-shrink: 0;

              > span {
                display: block;
                width: 100%;
                //overflow: hidden;
                //white-space: nowrap;
                //text-overflow: ellipsis;
                font-size: 0.7rem;
                color: #fff;
                text-align: center;
                word-break: break-all;
                line-height: 0.9rem;
              }
            }

            .thirdLevelNode {
              width: 100%;
              padding-left: 3rem;
              //overflow: hidden;

              .fileDataBox {
                padding: 1rem;
                border-radius: 0.2rem;
                background-color: #F4F8FA;

                .checkbox {
                  position: absolute;
                  left: 0.25rem;
                  width: 0.5rem;
                  height: 0.5rem;
                  border-radius: 0.1rem;
                  cursor: pointer;
                  background-color: #C1D4DE;

                  &:after {
                    content: '';
                    position: absolute;
                    width: 40%;
                    height: 40%;
                    background-color: #FF3116;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    display: none;
                  }

                  &.checked {
                    &:after {
                      display: block;
                    }
                  }

                  &.allChecked {
                    &:after {
                      display: block;
                      width: 60%;
                      height: 60%;
                    }
                  }
                }

                .mapDataBox {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  //overflow: hidden;
                  position: relative;
                  padding-right: 3rem;

                  .checkbox {
                    left: -0.75rem;
                  }

                  .icon-lock, .operateBtnBpx, .mapDataSign {
                    flex-shrink: 0;
                  }

                  .mapNameText {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-size: 0.7rem;
                    color: #333;
                    line-height: 1;
                    cursor: default;
                  }

                  .iconfont {
                    font-size: 0.6rem;

                    &.icon-suo {
                      color: rgb(227, 54, 10);
                    }

                    &.icon-jiesuo2 {
                      color: rgb(123,168,139);
                    }
                  }

                  .mapDataSign {
                    font-size: 0.7rem;
                    color: #999;
                    line-height: 1;
                  }

                  .operateBtnBpx {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    //width: 2.5rem;
                    height: 1rem;
                    cursor: pointer;
                    position: absolute;
                    right: 0;

                    > span {
                      display: block;
                      height: 100%;
                      line-height: 100%;
                      cursor: pointer;

                      &.disabled {
                        cursor: not-allowed;

                        .iconfont {
                          color: #babcbd;
                        }
                      }
                    }

                    .iconfont {
                      top: -0.15rem;
                      font-size: 0.8rem;
                      color: #37576E;
                    }
                  }
                }

                .fileDataListBox {
                  margin-top: 0.6rem;

                  .noneFileText {
                    display: block;
                    font-size: 0.7rem;
                    color: #999;
                    cursor: default;
                    white-space: pre-wrap;
                    word-break: break-all;
                    text-align: justify;
                  }

                  .fileItem {
                    display: flex;
                    align-items: center;
                    margin-left: -1rem;
                    margin-right: -1rem;
                    padding: 0.1rem 1rem;
                    //position: relative;

                    &.activeNode {
                      padding: 0.1rem 1rem;
                    }

                    &:hover {
                      background-color: #9bb4c1;

                      .checkbox {
                        background-color: #fff;
                      }

                      .iconfont {
                        color: #fff !important;
                      }

                      .leftBox {
                        .fileName {
                          color: #fff;
                        }

                        .fileOperateBtnBox {
                          visibility: visible;
                        }
                      }

                      .rightBox {
                        > span {
                          color: #fff;
                        }
                      }
                    }

                    .leftBox {
                      flex: 1 1 100%;
                      display: flex;
                      align-items: center;
                      overflow: hidden;
                      margin-right: 0.5rem;

                      .fileName {
                        font-size: 0.7rem;
                        color: #333;
                        cursor: default;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                      }

                      .fileOperateBtnBox {
                        visibility: hidden;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 1.6rem;
                        margin-left: 1rem;

                        > span {
                          font-size: 0.7rem;
                          line-height: 1;
                          cursor: pointer;

                          + span {
                            position: relative;
                            top: -0.05rem;
                            margin-left: 0.2rem;
                            .iconfont {
                              font-size: 0.6rem;
                            }
                          }

                          .iconfont {
                            font-size: 0.7rem;
                            color: #fff;
                          }
                        }
                      }
                    }

                    .rightBox {
                      flex-shrink: 0;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;

                      > span {
                        font-size: 0.7rem;
                        color: #333;
                        cursor: default;
                      }

                      .userName {
                        width: 3.8rem;
                        margin-right: 0.5rem;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                      }

                      .dateText {
                        flex-shrink: 0;
                        width: 3.8rem;
                        white-space: nowrap;
                        text-align: right;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

  }

  .viewModelNavBox {
    position: fixed;
    right: 1rem;
    bottom: 3rem;
    width: 1rem;
    overflow: hidden;
    padding: 0.5rem;
    background-color: #fff;
    box-shadow: 0 0.2rem 0.45rem 0 #bebaba;
    border-radius: 0.2rem;
    transition: width 0.3s;
    z-index: 3;

    &:before {
      content: '<';
      position: absolute;
      left: 0.15rem;
      top: 50%;
      transform: translateY(-50%);
      color: #ccc;
    }

    &:hover {
      width: 5rem;
      padding: 0.5rem 0.3rem;
      transition: width 0.3s;

      > div {
        overflow-y: auto;
      }

      &:before {
        display: none;
      }
    }
    > div {
      max-height: 10rem;
      overflow: hidden;

      > span {
        display: block;
        font-size: 0.6rem;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        .iconfont {
          font-size: 0.6rem;
          margin-right: 0.2rem;
          color: #3DADEE;
        }

        &:hover {
          color: #3DADEE;
        }

        + span {
          margin-top: 0.5rem;
        }
      }
    }
  }

  .activeNode {
    position: relative;
    box-shadow: 0 0.65rem 1.9rem 0 rgba(144,149,178,0.41);

    &:before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      border: 0.1rem solid #60B4F6;
      left: 0;
      top: 0;
      border-radius: 0.2rem;
      //z-index: 2;
    }
  }
}
